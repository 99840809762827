import React from "react";
import { Layout, Button, Typography } from "antd";
import { ArrowLeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const AccessDeniedScreen = () => {
    const navigateTo = useNavigate();

    const goBack = () => {
        navigateTo(-1);
    }

    return <Layout className="flex h-screen justify-center items-center">
        <ExclamationCircleOutlined style={{ fontSize: 80, marginBottom: 20, color: 'rgb(156 163 175)' }} />
        <Typography.Title level={4} className="text-gray-500 mb-6">Bạn không có quyền truy cập chức năng này</Typography.Title>
        <Button type="primary" onClick={goBack}><ArrowLeftOutlined/> Trở lại</Button>
    </Layout>
};

export default AccessDeniedScreen;