import React, { useEffect, useState } from "react";
import { Layout, Collapse, Typography, notification, Space, Button } from "antd";
import { Link } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { moneyFormat } from "@Services/utils";
import { renderValueWithColor } from '@Services/renderSpecial';
import api from '@Services/api';
import routes from "@Routes";
import Loading from '@Components/Loading';
import StocksOwnedTable from '@Screens/Main/Borrowers/Detail/Asset/StocksOwnedTable';

type SummaryBorrower = {
    index: number;
    account: NSApp.Account;
    source?: NSApp.Source;
    totalBuyValue: number;
    totalMarketValue: number;
    nav1: number;
    nav2: number;
    totalExpectedProfit: number;
    avgMarginRatio: number;
}

const SourceCollapseContent = ({ sourceId }) => {
    const [borrowers, setBorrowers] = useState<SummaryBorrower[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchSummaryBorrowers = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`source/summary-borrower/${sourceId}`);

        setIsFetching(false);

        if (err) {
            return;
        }

        const borrowers = resp.data;

        setBorrowers(borrowers);
    };

    useEffectOnce(() => {
        fetchSummaryBorrowers();
    });

    if (isFetching || !borrowers.length) {
        return <Layout/>
    }

    return (
        <Collapse defaultActiveKey={[borrowers[0].account.profileId]} className="collapse-no-padding-collapse">
            {borrowers.map((borrower) => {
                return <Collapse.Panel
                    key={borrower?.account?.profileId}
                    header={<span className="font-bold">{borrower?.account?.profile?.fullname} - ID: {borrower?.account?.profile?.shortId}</span>}
                    className="collapse-no-padding-panel"
                >
                    <StocksOwnedTable account={borrower?.account} sourceId={String(sourceId)} />
                </Collapse.Panel>
            })}
        </Collapse>
    )
};

const SourcesStockList = () => {
    const [listSources, setListSources] = useState<NSApp.Source[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchSources = async () => {
        setIsFetching(true);

        const [err, resp]: any = await api.get('/source/list', {
            filter: '', page: 1, pageSize: 100
        });

        setIsFetching(false)

        if (err) {
            notification.error({
                message: err?.response?.data?.message
            });
            return;
        }

        const data = resp.data;
        let sources = data.sources;

        if (sources.length > 0) {
            sources.sort((a, b) => {
                const marketValueA = a.totalBuyValue + a.totalExpectedProfit;
                const marketValueB = b.totalBuyValue + b.totalExpectedProfit;
                return marketValueB - marketValueA;
            });
        }

        setListSources(sources);
    }

    useEffect(() => {
        fetchSources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <Layout className="bg-gray p-4">
                <Space>
                    <Link to={routes.SOURCES_ASSET}><Button className='custom-btn-tab'>Tài sản</Button></Link>
                    <Button className='custom-btn-tab active'>Cổ phiếu</Button>
                </Space>
            </Layout>
            <Layout className="px-4 pt-4">
                <Typography.Title level={4} className="uppercase font-bold mb-4">Cổ phiếu tổng kho</Typography.Title>
                {isFetching
                    ? <Loading />
                    : !!listSources.length ? (
                        <Collapse className="header-align-center mb-4" defaultActiveKey={[listSources[0].id]}>
                            {listSources.map((source) => {
                                const isInactiveSource = source.totalBuyValue === 0 && source.totalExpectedProfit === 0;
                                let collapseProps = {};

                                if (isInactiveSource) {
                                    collapseProps = {
                                        ...collapseProps,
                                        collapsible: 'disabled'
                                    }
                                }
                                const marketValue = source.totalBuyValue + source.totalExpectedProfit;
                                const changeValue = marketValue - source.totalBuyValue;
                                const changeValuePercent = (changeValue / source.totalBuyValue) * 100;

                                return <Collapse.Panel
                                    {...collapseProps}
                                    key={source.id}
                                    header={<span className="text-base font-bold">Kho {source.sourceCode}</span>}
                                    extra={
                                        isInactiveSource
                                            ? <></>
                                            : <div className="flex">
                                                <div className="flex flex-col w-64 text-right">
                                                    <span className="text-gray-500 text-xs">Giá trị mua</span>
                                                    <span className="text-base font-bold">{source.totalBuyValue ? moneyFormat(source.totalBuyValue) : '0'}</span>
                                                </div>
                                                <div className="flex flex-col w-64 text-right">
                                                    <span className="text-gray-500 text-xs">Giá trị thị trường</span>
                                                    <span className="text-base font-bold">{marketValue ? moneyFormat(marketValue) : '0'}</span>
                                                </div>
                                                <div className="flex flex-col w-64 text-right">
                                                    <span className="text-gray-500 text-xs">Lãi/lỗ</span>
                                                    <span className="text-base font-bold">{renderValueWithColor(changeValue)}</span>
                                                </div>
                                                <div className="flex flex-col w-48 text-right">
                                                    <span className="text-gray-500 text-xs">% Lãi/lỗ</span>
                                                    <span className="text-base font-bold">{renderValueWithColor(changeValuePercent, 'percent')}</span>
                                                </div>
                                            </div>
                                    }
                                >
                                    <SourceCollapseContent sourceId={source.id} />
                                </Collapse.Panel>
                            })}
                        </Collapse>
                    ) : <Layout/>
                }
            </Layout>
        </Layout>
    )
};

export default SourcesStockList;