import React, { useEffect, useState } from "react";
import { Collapse, Table, Button, Modal, notification } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import { DATE_TIME_FORMAT, PAGE_SIZE_SMALL } from "@Configs";
import { moneyFormat, useReducedState } from "@Services/utils";
import FullscreenToggle from "@Components/FullscreenToggle";
import api from "@Services/api";

const DashboardTransaction = () => {
    const columns: ColumnsType<any> = [
        {
            title: 'Tên',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (value, record) => {
                return record?.account?.profile?.fullname
            },
        },
        {
            title: 'ID',
            dataIndex: 'shortId',
            key: 'shortId',
            align: 'center',
            render: (value, record) => {
                return record?.account?.profile?.shortId
            }
        },
        {
            title: 'Ngày',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            render: (value) => {
                return moment(value).format(DATE_TIME_FORMAT)
            }
        },
        {
            title: 'Loại giao dịch',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (value) => {
                return value === 'withdrawal' ? "Rút" : "Nạp"
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (value) => value ? moneyFormat(value) : '-',
        },
        {
            title: 'Nội dung',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Tình trạng',
            dataIndex: '',
            key: 'status',
            align: 'center',
            width: 220,
            render: (value, record) => (
                <div>
                    <Button type="primary" onClick={() => onConfirmFinish(record.id)}>Hoàn thành</Button>
                    <Button className="ml-2" danger onClick={() => onConfirmCancel(record.id)}>Huỷ</Button>
                </div>
            )
        },
    ];

    const [transactions, setTransactions] = useState<any>([]);
    const [pagination, setPagination] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_SMALL,
        total: 0
    })

    const onConfirmFinish = (id) => {
        Modal.confirm({
            centered: true,
            content: <span>Bạn chắc chắn muốn hoàn thành giao dịch này?</span>,
            okText: 'Có',
            cancelText: 'Không',
            onOk: () => onFinish(id)
        });
    };

    const onConfirmCancel = (id) => {
        Modal.confirm({
            centered: true,
            content: <span>Bạn chắc chắn muốn huỷ giao dịch này?</span>,
            okText: 'Có',
            cancelText: 'Không',
            onOk: () => onCancel(id)
        });
    };

    const onFinish = async (id) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`transaction/approve-waiting/${id}`, {})

        if (err) {
            notification.error({
                message: 'Lỗi',
                description: err?.response?.data?.message
            });
            return;
        }

        notification.success({
            message: 'Hoàn thành giao dịch!',
        });

        fetchWaitingTransactions();
    };

    const onCancel = async (id) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`transaction/cancel-waiting/${id}`, {})

        if (err) {
            notification.error({
                message: 'Lỗi',
                description: err?.response?.data?.message
            });
            return;
        }

        notification.success({
            message: 'Huỷ giao dịch thành công!',
        });

        fetchWaitingTransactions();
    };

    const fetchWaitingTransactions = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get('transaction/list-waiting', {
            page: pagination.current,
            pageSize: pagination.pageSize,
            status: 'waiting'
        });

        const { transactions, total } = resp.data;

        setTransactions(transactions);
        setPagination({
            total
        })
    }

    useEffect(() => {
        fetchWaitingTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page]);

    return (
        <div id="sourceCollapse">
            <Collapse defaultActiveKey="source" collapsible="header" className="h-full">
                <Collapse.Panel
                    key="source"
                    header={<span className="font-bold">Nạp/rút tiền ({pagination.total})</span>}
                    extra={<FullscreenToggle elementId={'sourceCollapse'}/>}
                >
                    <Table
                        bordered
                        rowKey={r => `${r.id}`}
                        dataSource={transactions}
                        columns={columns}
                        pagination={{
                            ...pagination,
                            size: "small",
                            showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                setPagination({ current: page, pageSize });
                            }
                        }}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
    )
};

export default DashboardTransaction;