import React, { useEffect, useState } from "react";
import { Layout, Card, Form, DatePicker, Select, Input, Button, Typography, notification } from "antd";
import moment from "moment";

import api from "@Services/api";
import { moneyFormat, useReducedState } from "@Services/utils";
import { DATE_FORMAT, DATE_TIME_FORMAT, PAGE_SIZE_SMALL } from "@Configs";
import FullscreenToggle from "@Components/FullscreenToggle";
import EditableTable from "@Components/EditableTable";

const NavTab = ({ account, updateAccount }) => {
    const columns = [
        {
            title: 'Ngày',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            editable: true,
            inputType: 'date-time',
            render: (value) => moment(value).format(DATE_TIME_FORMAT),
        },
        {
            title: 'Reference',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Loại giao dịch',
            dataIndex: 'type',
            key: 'type',
            render: (value) => {
                const transactionType = {
                    'deposit': 'Nạp tiền',
                    'withdrawal': 'Rút tiền',
                    'profit': 'Chốt lãi/lỗ',
                    'cover-loss': 'Bù lỗ',
                    'withdrawal-profit': 'Rút lãi',
                };
                return transactionType[value];
            },
            editable: true,
            inputType: 'select',
            inputValue: [
                { value: 'deposit', label: 'Nạp tiền' },
                { value: 'withdrawal', label: 'Rút tiền' },
                { value: 'profit', label: 'Chốt lãi/lỗ' },
                { value: 'withdrawal-profit', label: 'Rút lãi' },
                { value: 'cover-loss', label: 'Bù lỗ' },
            ],
        },
        {
            title: 'Nội dung',
            dataIndex: 'note',
            key: 'note',
            editable: true,
            inputType: 'input',
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (value) => moneyFormat(value),
            editable: true,
            inputType: 'number',
            inputWidth: 200,
        },
    ];

    const [transactions, setTransactions] = useState<NSApp.Transaction[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);
    const [searchString, setSearchString] = useState<string>('');
    const [transactionType, setTransactionType] = useState<string>('');
    const [form] = Form.useForm();

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_SMALL,
        total: 0
    });

    const fetchTransactions = async (fromDate, toDate, searchString, transactionType) => {
        setIsFetching(true);
        const filter = {
            page: pagination.current, pageSize: PAGE_SIZE_SMALL, accountId: account.id
        };

        if (fromDate) {
            filter['fromDate'] = fromDate.clone().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (toDate) {
            filter['toDate'] = toDate.clone().endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (searchString) {
            filter['ref'] = searchString
        }

        if (transactionType) {
            filter['transactionType'] = transactionType;
        }

        const [err, resp]: any = await api.get(`transaction/list`, filter);

        if (err) {
            notification.error(err?.response?.data?.message);
            return;
        }

        let { transactions, total } = resp?.data;

        transactions = transactions.map(transaction => {
            transaction.date = moment(transaction.date);
            return transaction
        })
        setTransactions(transactions);
        setPagination({ total });
        setIsFetching(false);
    }

    useEffect(() => {
        fetchTransactions(fromDate, toDate, searchString, transactionType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, transactionType, fromDate, toDate, searchString, pagination.current]);

    const onSearch = (value: string) => {
        setSearchString(value);
        setPagination({
            current: 1
        })
    }

    const onSave = async (id, values) => {
        const [err, resp]: any = await api.post(`transaction/update/${id}`, {
            date: values.date,
            amount: values.amount,
            note: values.note,
            type: values.type,
        });

        if (err) {
            notification.error({
                message: "Có lỗi trong quá trình thay đổi giao dịch"
            })

            if (err?.response?.data?.message) {
                notification.error({
                    message: err?.response?.data?.message
                });
            }

            if (err?.response?.data?.errors) {
                notification.error({
                    message: err?.response?.data?.errors[0].message
                });
            }

            return;
        }

        const {account, transaction} = resp.data;

        const _transactions = transactions.map((t) => {
            if (t.id === transaction.id) {
                return transaction
            }
            return t;
        })
        setTransactions(_transactions);
        updateAccount(account);
    }

    const onDelete = async (id) => {
        const [err, resp]: any = await api.post(`transaction/delete/${id}`, {});

        if (err) {
            notification.error({
                message: "Có lỗi trong quá trình xoá giao dịch"
            })

            if (err?.response?.data?.message) {
                notification.error({
                    message: err?.response?.data?.message
                });
            }

            if (err?.response?.data?.errors) {
                notification.error({
                    message: err?.response?.data?.errors[0].message
                });
            }

            return false;
        }

        notification.success({
            message: "Xoá giao dịch thành công"
        })

        const { account } = resp.data;
        updateAccount(account);
        return true;
    }

    const onFinishFilter = (values) => {
        const { date, transactionType } = values;
        if (date) {
            setFromDate(date[0]);
            setToDate(date[1]);
        } else {
            setFromDate(null);
            setToDate(null);
        }
        setTransactionType(transactionType);
        setPagination({ current: 1 });
    };

    const resetFilter = () => {
        setFromDate(null);
        setToDate(null);
        setTransactionType('');
        setPagination({ current: 1 });
        form.setFieldsValue({
            date: [],
            transactionType: ''
        });
    }

    return <Layout style={{ margin: -16 }}>
        <div id="borrowerNavTab" className="p-4 bg-light">
            <div className="flex justify-between mb-2">
                <Typography.Text><Typography.Link onClick={resetFilter}>Tất cả giao dịch</Typography.Link> ({pagination.total})</Typography.Text>
                <FullscreenToggle elementId="borrowerNavTab" />
            </div>
            <Layout className="flex-row mt-2 mb-5 justify-between items-center">
                <Form
                    form={form}
                    layout="inline"
                    onFinish={onFinishFilter}
                    initialValues={{ transactionType: '' }}
                >
                    <Form.Item name="date">
                        <DatePicker.RangePicker
                            format={DATE_FORMAT}
                            placeholder={['Từ ngày', 'Đến ngày']}
                        />
                    </Form.Item>
                    <Form.Item name="transactionType">
                        <Select style={{ width: 200 }}>
                            <Select.Option value="">Tất cả giao dịch</Select.Option>
                            <Select.Option value="deposit">Nạp tiền</Select.Option>
                            <Select.Option value="withdrawal">Rút tiền</Select.Option>
                            <Select.Option value="profit">Chốt lãi/lỗ</Select.Option>
                            <Select.Option value="withdrawal-profit">Rút lãi</Select.Option>
                            <Select.Option value="cover-loss">Bù lỗ</Select.Option>
                        </Select>
                    </Form.Item>
                    <Button htmlType="submit">Lọc</Button>
                </Form>
                <div>
                    <Input.Search
                        placeholder="Nhập Reference"
                        allowClear
                        enterButton="Tìm kiếm"
                        style={{ minWidth: 300 }}
                        onSearch={onSearch}
                    />
                </div>
            </Layout>

            <Layout className="h-full">
                <Card className="card-table-header">
                    <div className=" flex justify-between items-center">
                        <span>Tổng tiền đã nạp: <strong>{moneyFormat(account.totalDeposit)} VND</strong></span>
                        <span>Tổng tiền đã rút: <strong>{moneyFormat(account.totalWithdrawal)} VND</strong></span>
                        <span>Lãi/lỗ đã chốt: <strong>{moneyFormat(account.profit)} VND</strong></span>
                        <span>NAV1: <strong>{moneyFormat(account.nav1)} VND</strong></span>
                    </div>
                </Card>

                <EditableTable
                    rowKey="id"
                    loading={isFetching}
                    dataSource={transactions}
                    columns={columns}
                    pagination={{
                        ...pagination,
                        size: "small",
                        showSizeChanger: false,
                        onChange: page => {
                            setPagination({ current: page });
                        },
                    }}
                    onSave={onSave}
                    onDelete={onDelete}
                />
            </Layout>
        </div>
    </Layout>
};

export default NavTab;