import React, { useEffect, useState } from "react";
import { Layout, Card, notification, Typography, Button, Modal } from "antd";
import moment from "moment";

import { moneyFormat, useReducedState } from "@Services/utils";
import { DATE_TIME_FORMAT, PAGE_SIZE_NORMAL } from "@Configs";
import EditableTable from "@Components/EditableTable";
import api from "@Services/api";

const OrderBookTab = ({account, forceReload, sources, stocks, increaseForceReload}) => {
    const [unmatchedOrderBook, setUnmatchedOrderBook] = useState<any>([]);
    const [matchedOrderBook, setMatchedOrderBook] = useState<any>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loadingCancelOrder, setLoadingCancelOrder] = useState<boolean>(false);

    const [ paginationUnmatched, setPaginationUnmatched ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_NORMAL,
        total: 0
    });

    const [ paginationMatched, setPaginationMatched ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_NORMAL,
        total: 0
    });

    useEffect(() => {
        fetchUnmatchedOrderBook();
        fetchMatchedOrderBook();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceReload, paginationMatched.current, paginationUnmatched.current]);

    const fetchUnmatchedOrderBook = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get(`order-book/list`, {
            accountId: account.id,
            listStatus: "waiting,ordered,partial-matched",
            page: paginationUnmatched.current,
            pageSize: PAGE_SIZE_NORMAL,
            disableSource: 1
        });

        if (resp) {
            const orderBooks = resp.data.orderBooks.map((orderBook: any) => {
                orderBook.date = moment(orderBook.date);
                return orderBook;
            });
            setUnmatchedOrderBook(orderBooks);
            setPaginationUnmatched({
                ...paginationUnmatched,
                total: resp.data.total
            });
        }
    }

    const fetchMatchedOrderBook = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get(`order-book/list`, {
            accountId: account.id,
            listStatus: "matched,canceled",
            page: paginationMatched.current,
            pageSize: PAGE_SIZE_NORMAL
        });

        if (resp) {
            const orderBooks = resp.data.orderBooks.map((orderBook: any) => {
                orderBook.date = moment(orderBook.date);
                return orderBook;
            });
            setMatchedOrderBook(orderBooks);
            setPaginationMatched({
                ...paginationMatched,
                total: resp.data.total
            });
        }
    }

    const onChangeOrderBookStatus = (status, form) => {
        const values = form.getFieldsValue();

        if (status !== 'matched' && status !== 'partial-matched') {
            form.setFieldsValue({
                matchedVolume: 0,
                matchedPrice: 0
            });
        } else if (status === 'matched') {
            // form.setFieldsValue({ matchedVolume: values.volume });
        }
    };

    const columns = [
        {
            title: 'Mã lô cổ phiếu',
            dataIndex: 'lot',
            key: 'lot',
            editable: false,
            align: 'center',
        },
        {
            title: 'Lệnh',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (value) => {
                if (value === 'buy') {
                    return <span className="text-success font-bold">MUA</span>;
                }
                if (value === 'sell') {
                    return <span className="text-error font-bold">BÁN</span>;
                }
            },
        },
        {
            title: 'Kho',
            dataIndex: 'sourceId',
            key: 'sourceId',
            align: 'center',
            editable: true,
            render: (value, record) => {
                return record.source ? `${record.source?.sourceCode}-${record.source?.securitiesFullname}` : '';
            },
            inputWidth: 250,
            inputType: 'select',
            inputValue: sources.map((source) => {
                return { value: source.id, label: `${source?.sourceCode}-${source?.securitiesFullname}` }
            })
        },
        {
            title: 'Ngày thanh toán',
            dataIndex: 'date',
            key: 'date',
            editable: true,
            inputType: 'date-time',
            inputWidth: 170,
            align: 'center',
            render: (value) => value.format(DATE_TIME_FORMAT),
        },
        {
            title: 'Mã cổ phiếu',
            dataIndex: 'stockId',
            key: 'stockId',
            editable: true,
            inputType: 'select',
            align: 'center',
            inputValue: stocks.map((stock: any) => {
                return {
                    value: stock.id,
                    label: stock.code.toUpperCase()
                }
            }),
            inputDisabled: true,
            // inputWidth: 150,
            render: (value, record) => <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{record.stockCode.toUpperCase()}</span>,
        },
        {
            title: 'Giá',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (value, record) => value ? <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{moneyFormat(value)}</span> : '-',
            editable: true,
            inputType: 'number',
            // inputWidth: 150,
        },
        {
            title: 'Khối lượng',
            dataIndex: 'volume',
            key: 'volume',
            align: 'right',
            render: (value, record) => value ? <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{moneyFormat(value)}</span> : '-',
            editable: true,
            inputType: 'number',
            // inputWidth: 150,
        },
        {
            title: 'Giá khớp TB',
            dataIndex: 'matchedPrice',
            key: 'matchedPrice',
            align: 'right',
            render: (value, record) => value ? <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{moneyFormat(value)}</span> : '-',
            editable: true,
            inputType: 'number',
            // inputWidth: 150,
        },
        {
            title: 'Khối lượng khớp',
            dataIndex: 'matchedVolume',
            key: 'matchedVolume',
            align: 'right',
            render: (value, record) => value ? <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{moneyFormat(value)}</span> : '-',
            editable: true,
            inputType: 'number',
        },
        {
            title: 'Tình trạng',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (value, record) => {
                let _status = '';
                let _subStatus = '';

                if (record.userStatus === 'waiting-cancel') {
                    _subStatus = "(Chờ huỷ)";
                }
                if (value === 'waiting') {
                    _status = "Chờ đặt lệnh";
                }
                if (value === 'ordered') {
                    _status = "Chờ khớp";
                }
                if (value === 'partial-matched') {
                    _status = "Khớp một phần"
                }
                if (value === 'matched' && record.matchedVolume === record.volume) {
                    _status = "Khớp hết";
                }
                if (value === 'matched' && record.matchedVolume !== record.volume) {
                    _status = "Khớp một phần";
                }
                if (value === 'canceled') {
                    _status = "Đã huỷ lệnh";
                }
                return <div>{_status} {_subStatus && <span className="text-error font-bold">{_subStatus}</span>}</div>
            },
            editable: true,
            inputType: 'select',
            inputValue: [
                { value: 'waiting', label: 'Chờ đặt lệnh' },
                { value: 'ordered', label: 'Chờ khớp' },
                { value: 'matched', label: 'Đã khớp' },
                { value: 'partial-matched', label: "Khớp một phần" },
                // { value: 'canceled', label: 'Đã huỷ lệnh' },
            ],
            onChange: onChangeOrderBookStatus,
        },
    ];

    const onSaveOrderBook = async (id, values) => {
        const { volume, matchedVolume, matchedPrice, status } = values;

        if (status === 'matched' || status === 'partial-matched') {
            if (Number(matchedVolume) > Number(volume)) {
                notification.error({
                    message: 'Khối lượng khớp không được vượt quá khối lượng lô'
                });
                return;
            }
            if (Number(matchedVolume) === 0 || Number(matchedPrice) === 0) {
                notification.error({
                    message: 'Khối lượng khớp và giá khớp trung bình không được để trống'
                });
                return;
            }
        } else {
            values = {
                ...values,
                matchedVolume: 0,
                matchedPrice: 0
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`order-book/${id}/update`, {
            ...values
        });

        if (err) {
            notification.error({
                message: 'Có lỗi xảy ra',
            })

            if (err?.response?.data?.message) {
                notification.error({
                    message: err.response.data.message,
                })
            }

            if (err?.response?.data?.errors) {
                notification.error({
                    message: err.response.data.errors[0].message
                });
            }

            return;
        }

        increaseForceReload();

        return notification.success({
            message: 'Cập nhật lệnh thành công',
        })
    }

    const onCancelOrder = async () => {
        setLoadingCancelOrder(true);

        const [err, resp]: any = await api.post(`order-book/cancel-orders`, {
            ids: selectedRowKeys
        })

        if (err) {
            notification.error({
                message: 'Có lỗi xảy ra',
            })

            if (err?.response?.data?.message) {
                notification.error({
                    message: err.response.data.message,
                })
            }

            if (err?.response?.data?.errors) {
                notification.error({
                    message: err.response.data.errors[0].message
                });
            }

            setLoadingCancelOrder(false);
            return;
        }

        setLoadingCancelOrder(false);
        notification.success({
            message: 'Huỷ lệnh thành công',
        });
        setSelectedRowKeys([]);
        increaseForceReload();
    };

    const openConfirmCancelOrder = () => {
        Modal.confirm({
            centered: true,
            content: 'Bạn chắc chắn muốn huỷ những lệnh này?',
            onOk() {
                onCancelOrder();
            },
        });
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
    };

    const hasSelected = selectedRowKeys.length > 0;

    return <Layout>
        <Layout>
            <Card className="card-table-header">
                <Typography.Title level={5} className="mb-0">Lệnh chưa thực hiện</Typography.Title>
            </Card>
            <div className="my-2">
                <Button size="small" onClick={openConfirmCancelOrder} disabled={!hasSelected} loading={loadingCancelOrder}>Huỷ lệnh</Button>
                <span style={{ marginLeft: 8 }}>{hasSelected ? `Đã chọn ${selectedRowKeys.length} lệnh` : ''}</span>
            </div>

            <EditableTable
                rowKey="id"
                dataSource={unmatchedOrderBook}
                columns={columns}
                onSave={onSaveOrderBook}
                anyoneEditable
                pagination={{
                    ...paginationUnmatched,
                    size: 'small',
                    showSizeChanger: false,
                    onChange: page => {
                        setPaginationUnmatched({ current: page });
                    }
                }}
                onDelete={null}
                rowSelection={rowSelection}
            />
        </Layout>
        <Layout>
            <Card className="card-table-header">
                <Typography.Title level={5} className="mb-0">Lệnh đã thực hiện</Typography.Title>
            </Card>
            <div className="my-2">
                <Button size="small" onClick={openConfirmCancelOrder} disabled={!hasSelected} loading={loadingCancelOrder}>Huỷ lệnh</Button>
                <span style={{ marginLeft: 8 }}>{hasSelected ? `Đã chọn ${selectedRowKeys.length} lệnh` : ''}</span>
            </div>
            <EditableTable
                rowKey="id"
                dataSource={matchedOrderBook}
                columns={columns}
                onSave={onSaveOrderBook}
                anyoneEditable
                pagination={{
                    ...paginationMatched,
                    size: 'small',
                    showSizeChanger: false,
                    onChange: page => {
                        setPaginationMatched({ current: page });
                    }
                }}
                onDelete={null}
                rowSelection={rowSelection}
            />
        </Layout>
    </Layout>
};

export default OrderBookTab;