import React, { useState } from "react";
import { Layout, Typography, Space, notification } from "antd";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { toRoute } from "@Services/utils";
import api from "@Services/api";
import routes from "@Routes";

import Loading from "@Components/Loading";
import BackButton from "@Components/BackButton";
import InfoTable from "./InfoTable";
import AssetAndMarginTable from "./AssetAndMarginTable";
import StocksOwnedByBorrowerTable from "./StocksOwnedByBorrowerTable";
import FeeAndProfitTable from "./FeeAndProfitTable";
import RefundAndCommissionTable from "./RefundAndCommissionTable";
import TransactionModal from "./TransactionModal";

const SourceDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams() as any;
    const [sourceDetail, setSourceDetail] = useState<NSApp.Source>();
    const [contracts, setContracts] = useState<NSApp.Contract[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isTransactionModalVisible, setIsTransactionModalVisible] = useState<boolean>(false);
    // const [isCommissionModalVisible, setIsCommissionModalVisible] = useState<boolean>(false);

    // const [forceReload, setForceReload] = useState<number>(0);
    const [transactionType, setTransactionType] = useState<string>('');

    const fetchSourceDetail = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`source/detail/${id}`, {});

        if (err) {
            notification.error({
                message: "Không tìm thấy kho!"
            });
            return navigate(routes.LENDERS_ASSET);
        }

        if (!resp?.data) {
            notification.error({
                message: "Không tìm thấy kho!"
            });
            return navigate(routes.LENDERS_ASSET);
        }

        setSourceDetail(resp.data);
        setContracts(resp.data.contracts);
        setIsFetching(false);
    }

    useEffectOnce(() => {
        fetchSourceDetail();
    });

    const openTransactionModal = (type) => {
        setTransactionType(type)
        setIsTransactionModalVisible(true);
    };

    // const openCommissionModal = () => {
    //     setIsCommissionModalVisible(true);
    // };

    // const increaseForceReload = () => setForceReload(forceReload + 1);

    if (isFetching) {
        return <Loading />;
    }

    if (!sourceDetail) {
        return <Layout />;
    }

    return (
        <Layout>
            <Layout className="flex-row justify-between items-center bg-gray p-4">
                <Space size="large" className="mr-6">
                    <BackButton/>
                    <Space size="large" className="">
                        <span>Số kho: <strong>{sourceDetail.sourceCode}</strong></span>
                        <span>Người cho vay: <Link className="font-bold" to={toRoute(routes.LENDER_DETAIL_ASSET, { id: sourceDetail.profile.id })}>{sourceDetail.profile.fullname}</Link></span>
                        <span>Phí mua: <strong>{sourceDetail.feeBuyStock ? `${sourceDetail.feeBuyStock}%` : '-'}</strong></span>
                        <span>Phí bán: <strong>{sourceDetail.feeSellStock ? `${sourceDetail.feeSellStock}%` : '-'}</strong></span>
                    </Space>
                </Space>
            </Layout>

            <Layout className="px-4 py-2">
                <div className="flex justify-between items-center mt-1 mb-2">
                    <Typography.Title level={4} className="uppercase font-bold mb-0">Chi tiết kho</Typography.Title>
                </div>
                <div className="mb-4">
                    <InfoTable sourceDetail={sourceDetail} />
                </div>

                <AssetAndMarginTable sourceDetail={sourceDetail} />
                <StocksOwnedByBorrowerTable sourceDetail={sourceDetail} />
                <FeeAndProfitTable sourceDetail={sourceDetail}/>
                <RefundAndCommissionTable sourceDetail={sourceDetail} />

                <TransactionModal
                    fetchSourceDetail={fetchSourceDetail}
                    profile={sourceDetail}
                    isVisible={isTransactionModalVisible}
                    setIsVisible={setIsTransactionModalVisible}
                    type={transactionType}
                    id={id}
                />

                {/* <RefundAndCommissionModal
                    profile={sourceDetail}
                    isVisible={isCommissionModalVisible}
                    setIsVisible={setIsCommissionModalVisible}
                    contracts={contracts}
                    onSubmit={() => {
                        increaseForceReload();
                    }}
                /> */}
            </Layout>
        </Layout>
    )
}

export default SourceDetail;