import React, { useEffect, useState } from "react";
import { Radio, Alert, Button, DatePicker, Select, Modal, Form, InputNumber, Row, Col, Divider, notification, Typography, Space } from "antd";

import { DATE_TIME_FORMAT } from "@Configs";
import { moneyFormat, formatterMoney, parserMoney } from "@Services/utils";
import api from "@Services/api";
import moment from "moment";

const labelCol = 6;
const wrapperCol = 18;

const TradeModal = ({ initialValues, assetStatus, sources, stocks, forceReload, profile, account, isVisible, setIsVisible, type, setType, increaseForceReload, setTransactionData }) => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [orderBookStatus, setOrderBookStatus] = useState('waiting');
    const [errors, setErrors] = useState<any>([]);
    const [portfolios, setPortfolios] = useState<NSApp.StockPortfolio[]>([]);

    const [form] = Form.useForm();

    const expectedPurchasingPower = assetStatus?.expectedPurchasingPower || 0;

    let typeLabel = '';
    if (type === 'buy') {
        typeLabel = 'mua';
    }
    if (type === 'sell') {
        typeLabel = 'bán';
    }
    if (type === 'dividend') {
        typeLabel = 'cổ tức'
    }

    const onCancelModal = () => {
        setIsVisible(false);
        setOrderBookStatus('waiting');
        setTransactionData();
        form.resetFields();
    }

    const onChangeType = (e) => {
        setType(e.target.value);
    }

    const onChangeOrderBookStatus = (value) => {
        setOrderBookStatus(value);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const onNextStep = async () => {
        try {
            await form.validateFields();

            if (type === "sell") {
                const portfolioId = form.getFieldValue('portfolioId');
                const portfolioSelected = portfolios.find(element => element.id === portfolioId);

                const lotVolume = portfolioSelected?.volume || 0;
                const volume = form.getFieldValue('volume');

                if (volume > lotVolume) {
                    notification.error({
                        message: "Khối lượng bán không được lớn hơn khối lượng đang có"
                    })
                    throw new Error('Khối lượng bán không được lớn hơn khối lượng đang có');
                }

                const transactionType = form.getFieldValue('transactionType');

                if (transactionType === 'matched') {
                    const matchedVolume = form.getFieldValue('matchedVolume');
                    if (matchedVolume > volume) {
                        notification.error({
                            message: "Khối lượng khớp không được lớn hơn khối lượng đang có"
                        })
                        throw new Error('Khối lượng khớp không được lớn hơn khối lượng đang có');
                    }
                }
            }
        } catch (err) {
            return;
        }
        nextStep();
    }

    const onFinish = async (values) => {
        // trả cổ tức
        values.type = type;
        if (type === "dividend") {
            values.price = 0;
            values.type = "buy";
            values.matchedVolume = values.volume;
            values.matchedPrice = 0;
            values.status = "matched";
            values.isDividend = true;
        }
        if (!values.matchedVolume) {
            values.matchedVolume = 0;
        }

        if (!values.matchedPrice) {
            values.matchedPrice = 0;
        }

        values.accountId = account.id;

        const [err, resp]: any = await api.post('order-book/create', {
            ...values,
        });


        if (resp) {
            setErrors([]);
            notification.success({
                message: 'Đặt lệnh thành công!',
            });
            setCurrentStep(0);
            setIsVisible(false);
            setTransactionData();
            increaseForceReload();
            return;
        }

        notification.error({
            message: "Không thể tạo lệnh!"
        })

        if (err?.response?.data?.errors) {
            setErrors(err.response.data.errors);
        }

        if (err?.response?.data?.message) {
            setErrors([{ msg: err.response.data.message }])
        }
    };

    const fetchPortfolios = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get(`portfolio/lots/${account.id}`, {
            page: 1,
            pageSize: 40,
        });

        if (resp) {
            setPortfolios(resp.data.lots);
        }
    }

    useEffect(() => {
        fetchPortfolios();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, forceReload]);

    const initialSellValues = {...initialValues};

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [form, initialValues]);


    const onChangePortfolio = (value) => {
        const currentPortfolio = portfolios.find(portfolio => portfolio.id === value);
        setTransactionData({
            ...currentPortfolio,
            date: moment(currentPortfolio?.date),
            status: 'waiting'
        });
    };

    const FirstStep = () => {
        const volume = Form.useWatch('volume', form);

        const fillAllMatchedVolume = () => {
            form.setFieldsValue({ matchedVolume: volume });
        };

        return (
            <>
                <div className="my-6 text-center">
                    <Radio.Group className="custom-radio" defaultValue={type} onChange={onChangeType} buttonStyle="solid">
                        <Radio.Button className="custom-radio-item item-success" value="buy">MUA</Radio.Button>
                        <Radio.Button className="custom-radio-item item-error" value="sell">BÁN</Radio.Button>
                        <Radio.Button className="custom-radio-item" value="dividend">CỔ TỨC</Radio.Button>
                    </Radio.Group>
                </div>

                {(type === 'buy' || type === 'dividend') && (
                    <Form.Item
                        name="stockId"
                        label="Mã cổ phiếu"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) => {
                                return option?.title.toLowerCase().includes(input.toLowerCase())
                            }}
                        >
                            {stocks.map((stock) => {
                                return <Select.Option
                                    key={stock.id}
                                    value={stock.id}
                                    title={stock.code.toUpperCase()}
                                >
                                    <span>{stock.code.toUpperCase()} - {stock.fullname}</span>
                                </Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                )}
                {type === 'sell' && (
                    <Form.Item
                        name="portfolioId"
                        label="Lô cổ phiếu"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Select
                            onChange={onChangePortfolio}
                            showSearch
                            filterOption={(input, option) => {
                                return option?.title?.toLowerCase().includes(input.toLowerCase())
                            }}
                        >
                            {portfolios?.map((portfolio) => {
                                return <Select.Option
                                    key={portfolio.id}
                                    value={portfolio.id}
                                    title={portfolio.stockCode.toUpperCase()}
                                >
                                    <span>{portfolio.lot} - {portfolio.stockCode.toUpperCase()} ({portfolio.volume})</span>
                                </Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                )}

                {type !== 'dividend' && (
                <Form.Item
                    name="price"
                    label="Giá"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <InputNumber
                        className="w-full"
                        controls={false}
                        formatter={value => formatterMoney(value)}
                        parser={value => parserMoney(value)}
                    />
                </Form.Item>)}
                <Form.Item
                    name="volume"
                    label="Khối lượng"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <InputNumber
                        className="w-full"
                        controls={false}
                        formatter={value => formatterMoney(value)}
                        parser={value => parserMoney(value)}
                    />
                </Form.Item>

                {(type === 'buy' || type === 'dividend') && (
                    <Form.Item
                        name="sourceId"
                        label="Kho"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Select>
                            {sources.map((source: any) => {
                                return <Select.Option
                                    key={source.id}
                                    value={source.id}
                                >
                                    <span>{source.sourceCode} ({moneyFormat(source.purchasingPower)})</span>
                                </Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                )}

                {type !== 'dividend' && (
                    <Form.Item
                        name="status"
                        label="Tình trạng đặt lệnh"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Select onChange={onChangeOrderBookStatus}>
                            <Select.Option value="waiting">Chờ đặt lệnh</Select.Option>
                            <Select.Option value="ordered">Đã đặt lệnh</Select.Option>
                            <Select.Option value="partial-matched">Khớp một phần</Select.Option>
                            <Select.Option value="matched">Đã khớp lệnh</Select.Option>
                            <Select.Option value="canceled">Đã huỷ lệnh</Select.Option>
                        </Select>
                    </Form.Item>
                )}

                {(orderBookStatus === "matched" || orderBookStatus === "partial-matched") && (
                    <>
                        <Form.Item
                            name="matchedPrice"
                            label="Giá khớp trung bình"
                            rules={[
                                {
                                    required: true,
                                    message: 'Không được để trống',
                                },
                            ]}
                        >
                            <InputNumber
                                className="w-full"
                                controls={false}
                                formatter={value => formatterMoney(value)}
                                parser={value => parserMoney(value)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="matchedVolume"
                            label="Khối lượng khớp"
                            rules={[
                                {
                                    required: true,
                                    message: 'Không được để trống',
                                },
                            ]}
                        >
                            <InputNumber
                                className="w-full"
                                controls={false}
                                formatter={value => formatterMoney(value)}
                                parser={value => parserMoney(value)}
                                addonAfter={volume ? <span className="cursor-pointer text-gray-500" onClick={fillAllMatchedVolume}>Tất cả</span> : false}
                            />
                        </Form.Item>
                    </>
                )}

                <Form.Item
                    name="date"
                    label="Ngày thanh toán"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <DatePicker className="w-full" placeholder="" format={DATE_TIME_FORMAT} showTime />
                </Form.Item>
            </>
        )
    };

    const SecondStep = () => {
        const volume = Form.useWatch('volume', form);
        const stockId = Form.useWatch('stockId', form);
        const sourceId = Form.useWatch('sourceId', form);
        const price = Form.useWatch('price', form);
        // const matchedPrice = Form.useWatch('matchedPrice', form);
        // const matchedVolume = Form.useWatch('matchedVolume', form);
        const portfolioId = Form.useWatch('portfolioId', form);
        const lot = portfolios?.find((portfolio) => portfolio.id === portfolioId);

        const stock = stocks.find(s => String(s.id) === String(stockId));
        const source = type === 'buy' ? sources.find(s => String(s.id) === String(sourceId)) : sources.find(s => String(s.id) === String(lot?.sourceId));

        const transactionValue = price * volume;
        const fee = type === 'buy' ? (price * volume * source?.feeBuyStock / 100) : (price * volume * source?.feeSellStock / 100);

        const totalTransactionValue = type === "buy" ? transactionValue + fee : transactionValue - fee;
        const amountPurchasingPower = type === 'dividend' ? expectedPurchasingPower : (type === "buy" ? expectedPurchasingPower - totalTransactionValue : expectedPurchasingPower + totalTransactionValue);

        const listInfos = [
            { key: 'expectedPurchasingPower', label: 'Sức mua hiện tại', value: expectedPurchasingPower, color: 'success' },
            { key: 'transactionValue', label: `Giá trị ${typeLabel}`, value: transactionValue, color: 'error' },
            { key: 'fee', label: 'Phí giao dịch', value: fee, color: 'error' },
            { key: 'totalTransactionValue', label: `Tổng giá trị ${typeLabel}`, value: totalTransactionValue, color: 'error' },
            { key: 'amountPurchasingPower', label: 'Sức mua còn lại', value: amountPurchasingPower, color: 'success' },
        ]

        const ListItem = ({ label, value, color }) => {
            return <div className="flex justify-between">
                <Typography.Text>{label}:</Typography.Text>
                <Typography.Text className={`font-bold text-${color}`}>{moneyFormat(value)} VND</Typography.Text>
            </div>
        }

        return (
            <div className="my-10">
                <Divider />
                <Space direction="vertical" className="py-4 text-base w-full">
                    <Typography.Text>
                        <strong className={type === "buy" ? 'text-success' : 'text-error'}>{typeLabel.toUpperCase()}</strong>{" "}
                        <strong>{moneyFormat(volume)}</strong> cổ phiếu <strong>{stock?.code.toUpperCase()}</strong> với giá mỗi cổ phiếu{" "}
                        <strong>{moneyFormat(price)}</strong> ở kho <strong>{source?.sourceCode}</strong>
                    </Typography.Text>

                    {listInfos.map((item) => {
                        return <ListItem key={item.key} label={item.label} value={item.value} color={item.color}/>
                    })}
                </Space>
                <Divider />
            </div>
        )
    };

    return (
        <Modal
            centered
            visible={isVisible}
            onCancel={onCancelModal}
            destroyOnClose={true}
            maskClosable={false}
            footer={null}
            width={650}
        >
            <Row className="mt-7">
                <Col span={6} className="flex items-center">
                    <span className="text-base font-bold">Đặt lệnh</span>
                </Col>
                <Col span={18}>
                    <div className="label-input"> {profile.fullname} - {profile.shortId}</div>
                </Col>
            </Row>
            {(!!errors || !!errors.length) && (
                <Row className="mt-4">
                    <Col span={24}>
                        {errors.map((error: any, index: number) => {
                            return <Alert
                                key={index}
                                className="mb-1"
                                type={"error"}
                                message={error.msg}
                                showIcon
                            />
                        })}
                    </Col>
                </Row>
            )}
            <Form
                form={form}
                layout="horizontal"
                labelAlign="left"
                colon={false}
                labelCol={{ span: labelCol }}
                wrapperCol={{ span: wrapperCol }}
                initialValues={{
                    ...initialSellValues,
                    status: 'waiting',
                }}
                onFinish={onFinish}
            >
                <div className="steps-content">
                    <div className={currentStep === 0 ? "block" : "hidden"}>
                        <FirstStep />
                    </div>
                    <div className={currentStep === 1 ? "block" : "hidden"}>
                        <SecondStep />
                    </div>
                </div>
                <div className="steps-action text-right">
                    {currentStep > 0 && (
                    <Button className="mr-2" onClick={prevStep}>Trở lại</Button>
                    )}
                    {currentStep === 0 && (
                    <Button type="primary" onClick={onNextStep}>Tiếp tục</Button>
                    )}
                    {currentStep === 1 && (
                    <Button type="primary" htmlType="submit">Đặt lệnh</Button>
                    )}
                </div>
            </Form>
        </Modal>
    )
};

export default TradeModal;