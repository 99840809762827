import React, { useEffect, useState } from "react";
import {
    Layout,
    Space,
    Button,
    Form,
    Row,
    Col,
    Input,
    DatePicker,
    Typography,
    notification,
    Alert,
    Modal,
    InputNumber,
} from "antd";
import { useNavigate, useParams, Link } from "react-router-dom";
import moment from "moment";

import { formatterPercent, parserPercent, toRoute } from "@Services/utils";
import api from "@Services/api";
import routes from "@Routes";
import { DATE_FORMAT } from "@Configs";

import Loading from "@Components/Loading";
import BackButton from "@Components/BackButton";

const labelCol = 6;
const wrapperCol = 14;

const BorrowerDetailInfo = () => {
    const navigate = useNavigate();
    const { id } = useParams() as any;
    const [borrower, setBorrower] = useState<NSApp.Borrower>();
    const [errors, setErrors] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchDetail = async () => {
        setLoading(true);
        const [err, resp]: any = await api.get(`borrower/detail/${id}`, {});

        setLoading(false);
        if (err) {
            notification.error({
                message: "Không tìm thấy người vay!",
            });
            return navigate(routes.BORROWERS);
        }

        if (!resp?.data?.borrower) {
            notification.error({
                message: "Không tìm thấy người vay!",
            });
            return navigate(routes.BORROWERS);
        }
        setBorrower(resp.data.borrower);
    };

    useEffect(() => {
        fetchDetail();
    }, [id]);

    if (!borrower) {
        return <Layout />;
    }

    const onUpdateDetail = async (values) => {
        setErrors([]);

        const {
            fullname,
            shortId,
            birthday,
            address,
            email,
            phone,
            bank_id,
            bank_name,
            bank_branch,
            interest_rate,
            margin_ratio,
            maintenance_margin_ratio,
            identity_card_number,
            date_of_issued,
            place_of_issued,
        } = values;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`borrower/update/${id}`, {
            fullname,
            shortId,
            birthday: birthday ? moment(birthday).format(DATE_FORMAT) : null,
            address,
            email,
            phone,
            identity_card_number,
            date_of_issued: date_of_issued ? moment(date_of_issued).format(DATE_FORMAT) : null,
            place_of_issued,
            bank_id,
            bank_name,
            bank_branch,
            interest_rate,
            margin_ratio,
            maintenance_margin_ratio,
        });

        if (!err) {
            notification.success({
                message: "Cập nhật thông tin thành công!",
            });
        }

        if (err?.response?.data?.errors) {
            setErrors(err.response.data.errors);
        }

        if (err?.response?.data?.message) {
            setErrors([{ msg: err.response.data.message }]);
        }
    };

    const openDeleteConfirm = () => {
        Modal.confirm({
            content: (
                <span>
                    Bạn chắc chắn muốn xoá người vay{" "}
                    <strong>{borrower.fullname}</strong>?
                </span>
            ),
            okText: "Có",
            cancelText: "Không",
            onOk: () => onDelete(),
        });
    };

    const onDelete = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`borrower/delete/${id}`, {});

        if (err) {
            notification.error({
                message: err?.response?.data?.message,
            });
            return;
        }

        notification.success({
            message: (
                <span>
                    Xoá người vay <strong>{borrower.fullname}</strong> thành
                    công!
                </span>
            ),
        });
        return navigate(routes.BORROWERS);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Layout>
            <Layout className="flex-row justify-between items-center bg-gray p-4">
                <Space size="large" className="mr-6">
                    <BackButton />
                    <span>
                        Tên: <strong>{borrower.fullname}</strong>
                    </span>
                    <span>
                        ID: <strong>{borrower.shortId}</strong>
                    </span>
                </Space>
                <Space>
                    {/* <Button className='custom-btn-tab' onClick={() => navigate(`/borrower/${id}/asset`)}>Tài sản</Button> */}
                    <Link to={toRoute(routes.BORROWER_DETAIL_ASSET, { id })}>
                        <Button className="custom-btn-tab">Tài sản</Button>
                    </Link>
                    <Button className="custom-btn-tab active">
                        Thông tin cơ bản
                    </Button>
                </Space>
                <div />
            </Layout>

            <Layout className="p-4">
                <Typography.Title
                    level={4}
                    className="uppercase font-bold mb-6"
                >
                    Thông tin cơ bản
                </Typography.Title>
                {(!!errors || !!errors.length) && (
                    <Row className="mb-4">
                        <Col xs={24} lg={11}>
                            {errors.map((error: any, index: number) => {
                                return (
                                    <Alert
                                        key={index}
                                        className="mb-1"
                                        type={"error"}
                                        message={error.msg}
                                        showIcon
                                    />
                                );
                            })}
                        </Col>
                    </Row>
                )}
                <Form
                    layout="horizontal"
                    labelAlign="left"
                    colon={false}
                    labelCol={{ span: labelCol }}
                    wrapperCol={{ span: wrapperCol }}
                    onFinish={onUpdateDetail}
                    initialValues={{
                        fullname: borrower.fullname,
                        birthday: borrower.birthday ? moment(borrower.birthday) : null,
                        address: borrower.address,
                        email: borrower.email,
                        phone: borrower.phone,
                        shortId: borrower.shortId,
                        identity_card_number: borrower.identity_card_number,
                        date_of_issued: borrower.date_of_issued ? moment(borrower.date_of_issued) : null,
                        place_of_issued: borrower.place_of_issued,
                        bank_id: borrower.bank_id,
                        bank_name: borrower.bank_name,
                        bank_branch: borrower.bank_branch,
                        interest_rate: borrower.interest_rate,
                        margin_ratio: borrower.margin_ratio,
                        maintenance_margin_ratio: borrower.maintenance_margin_ratio,
                    }}
                >
                    <Row>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="fullname"
                                label="Họ và tên"
                                rules={[
                                    {
                                        required: true,
                                        message: "Không được để trống",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="birthday" label="Ngày sinh">
                                <DatePicker
                                    className="w-full"
                                    format={DATE_FORMAT}
                                />
                            </Form.Item>
                            <Form.Item name="address" label="Địa chỉ">
                                <Input />
                            </Form.Item>
                            <Form.Item name="email" label="Email">
                                <Input disabled />
                            </Form.Item>
                            <Form.Item name="phone" label="Số điện thoại">
                                <Input />
                            </Form.Item>
                            <Form.Item name="shortId" label="ID">
                                <Input
                                    placeholder="ID chỉ bao gồm các chữ số"
                                    required={true}
                                />
                            </Form.Item>
                            <Form.Item
                                name="identity_card_number"
                                label="Số  CMND/CCCD"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="date_of_issued" label="Ngày cấp">
                                <DatePicker
                                    className="w-full"
                                    format={DATE_FORMAT}
                                />
                            </Form.Item>
                            <Form.Item name="place_of_issued" label="Nơi cấp">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item name="bank_id" label="Số tài khoản NH">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="bank_name"
                                label="Tên tài khoản NH"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="bank_branch" label="Chi nhánh NH">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="interest_rate"
                                label="Lãi suất vay/ngày (%)"
                                rules={[
                                    {
                                        required: true,
                                        message: "Không được để trống",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="w-full"
                                    controls={false}
                                    formatter={(value) =>
                                        formatterPercent(value)
                                    }
                                    parser={(value) => parserPercent(value)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="margin_ratio"
                                label="Tỉ lệ ký quỹ (%)"
                                rules={[
                                    {
                                        required: true,
                                        message: "Không được để trống",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="w-full"
                                    controls={false}
                                    formatter={(value) =>
                                        formatterPercent(value)
                                    }
                                    parser={(value) => parserPercent(value)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="maintenance_margin_ratio"
                                label="Tỉ lệ force sell (%)"
                                rules={[
                                    {
                                        required: true,
                                        message: "Không được để trống",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="w-full"
                                    controls={false}
                                    formatter={(value) =>
                                        formatterPercent(value)
                                    }
                                    parser={(value) => parserPercent(value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
						<Col xs={24} lg={12} />
						<Col xs={24} lg={12}>
                            <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                                <div className="flex justify-end">
                                    <Button
                                        className="mr-2"
                                        type="primary"
                                        danger
                                        onClick={openDeleteConfirm}
                                    >
                                        Xoá
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Lưu
                                    </Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Layout>
    );
};

export default BorrowerDetailInfo;
