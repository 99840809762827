import React, { useState } from "react";
import { Collapse, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { moneyFormat, toRoute, useReducedState } from "@Services/utils";
import { PAGE_SIZE_SMALL } from "@Configs";
import api from "@Services/api";
import routes from "@Routes";
import FullscreenToggle from "@Components/FullscreenToggle";

const DashboardMarginCallAndForceSell = () => {
    const [borrowers, setBorrowers] = useState([]);
    const [pagination, setPagination] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_SMALL,
        total: 0
    })
    const navigate = useNavigate();

    const fetchBorrowers = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get("/borrower/list-margin-call-and-force-sell", {
            page: 1,
            pageSize: PAGE_SIZE_SMALL,
        });

        setBorrowers(resp.data.borrowers);
        setPagination({
            ...pagination,
            total: resp.data.total
        });
    }

    useEffectOnce(() => {
        fetchBorrowers();
    });

    const columns: ColumnsType<any> = [
        {
            title: 'Tên',
            dataIndex: 'profileId',
            key: 'profileId',
            render: (value, record) => record.profile.fullname
        },
        {
            title: 'ID',
            dataIndex: 'profileId',
            key: 'profileId',
            align: 'center',
            render: (value, record) => record.profile.shortId
        },
        {
            title: 'Tổng giá trị mua',
            dataIndex: 'aggregate_total_buy_value',
            key: 'aggregate_total_buy_value',
            align: 'right',
            render: (value) => value ? moneyFormat(value) : '-',
        },
        {
            title: 'NAV2',
            dataIndex: 'aggregate_nav2',
            key: 'aggregate_nav2',
            align: 'right',
            render: (value) => value ? moneyFormat(value) : '-',
        },
        {
            title: 'Tỉ lệ hiện tại',
            dataIndex: 'aggregate_current_margin_ratio',
            key: 'aggregate_current_margin_ratio',
            align: 'right',
            render: (value) => value ? `${value}%` : '-',
        },
        {
            title: 'Tỉ lệ ký quỹ',
            dataIndex: 'profile',
            key: 'profile',
            align: 'right',
            render: (value) => value.margin_ratio ? `${value.margin_ratio}%` : '-',
        },
        {
            title: 'Tỉ lệ force sell',
            dataIndex: 'profile',
            key: 'profile',
            align: 'right',
            render: (value) => value.maintenance_margin_ratio ? `${value.maintenance_margin_ratio }%` : '-',
        },
        {
            title: 'Sức mua',
            dataIndex: 'aggregate_expected_purchasing_power',
            key: 'aggregate_expected_purchasing_power',
            align: 'right',
            render: (value) => value ? moneyFormat(value) : '-',
        },
        {
            title: 'Yêu cầu nộp tiền',
            dataIndex: 'aggregate_deposit_require',
            key: 'aggregate_deposit_require',
            align: 'right',
            render: (value) => <Typography.Text type="danger">{value ? moneyFormat(value) : '-'}</Typography.Text>,
        },
    ];

    return (
        <div id="marginCallAndForceSellCollapse">
            <Collapse defaultActiveKey="margin" collapsible="header" className="h-full">
                <Collapse.Panel
                    key="margin"
                    header={<span className="font-bold">Margin Call và Force Sell ({pagination.total})</span>}
                    extra={<FullscreenToggle elementId={'marginCallAndForceSellCollapse'}/>}
                >
                    <Table
                        bordered
                        rowKey={r => r.profileId}
                        dataSource={borrowers}
                        columns={columns}
                        pagination={{
                            ...pagination,
                            size: "small",
                            showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                setPagination({ current: page, pageSize });
                            }
                        }}
                        rowClassName="cursor-pointer"
                        onRow={(record) => {
                            return {
                                onClick: () => navigate(toRoute(routes.BORROWER_DETAIL_ASSET, { id: record.profileId })),
                                onContextMenu: () => window.open(toRoute(routes.BORROWER_DETAIL_ASSET, { id: record.profileId })),
                            };
                        }}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
    )
};

export default DashboardMarginCallAndForceSell;