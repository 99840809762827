import React, { useState } from "react";
import { Layout, Row, Col, Typography, Form, Input, Button, Select, Alert, notification } from "antd";
import { useAuthState } from "@Contexts/Auth";
import api from "@Services/api";

const labelCol = 3;
const wrapperCol = 8;

const Profile = () => {
    let { authState, setAuthState } = useAuthState() as any;
    let { currentUser } = authState;

    const [errors, setErrors]: any = useState([]);

    const onFinish = async (values) => {
        setErrors([]);

        const [err, resp]: any = await api.post(`admin/update-profile/`, {
            fullname: values.fullname,
            phone: values.phone
        });


        if (!err) {
            const updatedUser = {
                ...currentUser,
                fullname: values.fullname,
                phone: values.phone,
            }
            setAuthState({ currentUser: updatedUser });

            notification.success({
                message: 'Cập nhật tài khoản thành công!',
            });
        }

        if (err?.response?.data?.errors) {
            setErrors(err.response.data.errors);
        }

        if (err?.response?.data?.message) {
            setErrors([{ msg: err.response.data.message }])
        }
    }

    if (!currentUser) return <Layout />;

    return (
        <Layout className="p-4">
            <Typography.Title level={3} className="uppercase font-bold mb-1">
                Thông tin cá nhân
            </Typography.Title>
            <Layout className="mt-10">
                {(!!errors || !!errors.length) && (
                    <Row className="mb-4">
                        <Col xs={24} lg={11}>
                            {errors.map((error: any, index: number) => {
                                return <Alert
                                    key={index}
                                    className="mb-1"
                                    type={"error"}
                                    message={error.msg}
                                    showIcon
                                />
                            })}
                        </Col>
                    </Row>
                )}
                <Form
                    layout="horizontal"
                    labelAlign="left"
                    colon={false}
                    labelCol={{ span: labelCol }}
                    wrapperCol={{ span: wrapperCol }}
                    initialValues={{
                        fullname: currentUser.fullname,
                        id: currentUser.username,
                        email: currentUser.email,
                        phone: currentUser.phone,
                        role: currentUser.type,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item name="fullname" label="Họ và tên">
                        <Input />
                    </Form.Item>
                    <Form.Item name="id" label="ID">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="email" label="Email">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="phone" label="Số điện thoại">
                        <Input />
                    </Form.Item>
                    <Form.Item name="role" label="Role">
                        <Select disabled>
                            <Select.Option value="admin">Admin</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                        <div className="flex justify-end">
                            <Button type="primary" htmlType="submit">Lưu</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Layout>
        </Layout>
    );
}

export default Profile;