import _ from 'lodash';
import React, { useState, useRef, useEffect } from "react";
import { useEffectOnce, useUnmount } from 'react-use';
import { Layout, Card, Space, Button, Typography, Table } from "antd";
import { ColumnsType } from 'antd/lib/table';

import api from "@Services/api";
import { moneyFormat, roundDecimalNumber } from "@Services/utils";
import { renderValueWithColor } from '@Services/renderSpecial';
import { PAGE_SIZE_SMALL } from "@Configs";

type StocksOwnedTableProps = {
    account: NSApp.Account;
    setTradeType?: (type: string) => void;
    setIsTradeModalVisible?: (isTradeModalVisible: boolean) => void;
    forceReload?: number;
    hasAction?: boolean;
    sourceId?: string;
}

const StocksOwnedTable = (props: StocksOwnedTableProps) => {
    const { account, setTradeType, setIsTradeModalVisible, forceReload, sourceId } = props;
    const [listStock, setListStock] = useState<NSApp.StockPortfolioSummary[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const [sumTotalBuyValue, setSumTotalBuyValue] = useState<number>(0);
    const [sumTotalMarketValue, setSumTotalMarketValue] = useState<number>(0);
    const [sumTotalChangeValue, setSumTotalChangeValue] = useState<number>(0);
    const [sumTotalChangeValueRatio, setSumTotalChangeValueRatio] = useState<number>(0);

    const fetchPortfolioInterval = useRef<any>(null);

    const openTradeModal = (type) => {
        setTradeType && setTradeType(type);
        setIsTradeModalVisible && setIsTradeModalVisible(true);
    };

    const fetchPortfolio = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`portfolio/summary-list/${account.id}`, {
            sourceId: sourceId ? sourceId : ''
        });

        setIsFetching(false);

        if (err) {
            return;
        }

        const _listStocks = resp.data;

        const _sumTotalBuyValue = _.sumBy(_listStocks, 'buyValue');
        const _sumTotalMarketValue = _.sumBy(_listStocks, 'currentValue');
        const _sumTotalChangeValue = _.sumBy(_listStocks, 'changeValue');
        const _sumTotalChangeValueRatio = ( _sumTotalChangeValue / _sumTotalBuyValue ) * 100;

        setSumTotalBuyValue(_sumTotalBuyValue);
        setSumTotalMarketValue(_sumTotalMarketValue);
        setSumTotalChangeValue(_sumTotalChangeValue);
        setSumTotalChangeValueRatio(_sumTotalChangeValueRatio);

        setListStock(resp.data);
    }

    const intervalFetchData = () => {
        if (fetchPortfolioInterval.current) {
            clearInterval(fetchPortfolioInterval.current);
        }

        fetchPortfolioInterval.current = setInterval(async () => {
            await fetchPortfolio();
        }, 60 * 1000);
    }

    useEffectOnce(() => {
        intervalFetchData();
    });

    useEffect(() => {
        fetchPortfolio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceReload]);

    useUnmount(() => {
        if (fetchPortfolioInterval.current) {
            clearInterval(fetchPortfolioInterval.current);
            fetchPortfolioInterval.current = null;
        }
    })

    const columns: ColumnsType<any> = [
        {
            title: 'Cổ phiếu',
            dataIndex: 'stockCode',
            key: 'stockCode',
            align: 'center',
            render: (value: string, record: NSApp.StockPortfolioSummary) => {
                return <span className={`font-bold text-${record.changeValue < 0 ? 'error' : 'success'}`}>{value.toUpperCase()}</span>;
            }
        },
        {
            title: 'Khối lượng',
            dataIndex: 'volume',
            key: 'volume',
            align: 'right',
            render: (value: number) => moneyFormat(value),
        },
        {
            title: 'Khối lượng khả dụng',
            dataIndex: 'availableVolume',
            key: 'availableVolume',
            align: 'right',
            render: (value: number) => moneyFormat(value),
        },
        {
            title: 'Giá mua trung bình',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (value: number) => moneyFormat(value),
        },
        {
            title: 'Giá thị trường',
            dataIndex: 'latestPrice',
            key: 'latestPrice',
            align: 'right',
            render: (value: number) => moneyFormat(value),
        },
        {
            title: 'Giá trị mua',
            dataIndex: 'buyValue',
            key: 'buyValue',
            align: 'right',
            render: (value: number) => moneyFormat(value),
        },
        {
            title: 'Giá trị thị trường',
            dataIndex: 'currentValue',
            key: 'currentValue',
            align: 'right',
            render: (value: number) => moneyFormat(value),
        },
        {
            title: 'Lãi/lỗ',
            dataIndex: 'changeValue',
            key: 'changeValue',
            align: 'right',
            render: (value) => renderValueWithColor(value),
        },
        {
            title: '% Lãi/lỗ',
            dataIndex: 'changeValueRatio',
            key: 'changeValueRatio',
            align: 'right',
            render: (value) => renderValueWithColor(value, 'percent'),
        },
    ];

    return (
        <Layout>
            {!!props.hasAction && (
                <Card className="card-table-header">
                    <div className="flex justify-between items-center">
                        <Typography.Title level={5} className="mb-0">Cổ phiếu đang sở hữu</Typography.Title>
                        <Space>
                            <Button size="small" type="primary" className="custom-btn ant-btn-success" onClick={() => openTradeModal('buy')}>Mua CP</Button>
                            <Button size="small" type="primary" className="custom-btn" danger onClick={() => openTradeModal('sell')}>Bán CP</Button>
                            <Button size="small" className="custom-btn" onClick={() => openTradeModal('dividend')}>Cổ tức</Button>
                        </Space>
                    </div>
                </Card>
            )}
            <Table
                bordered
                rowKey={(r: any) => r.stockCode}
                loading={isFetching}
                dataSource={listStock}
                columns={columns}
                pagination={{ size: 'small', defaultPageSize: PAGE_SIZE_SMALL, showSizeChanger: false }}
                sticky
                summary={() => listStock.length > 0 && (
                    <Table.Summary fixed={'top'}>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} className="text-right font-bold">Tổng</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={3} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={5} className="text-right font-bold">{moneyFormat(sumTotalBuyValue)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={6} className="text-right font-bold">{moneyFormat(sumTotalMarketValue)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={7} className="text-right font-bold">{renderValueWithColor(sumTotalChangeValue)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={8} className="text-right font-bold">{renderValueWithColor(roundDecimalNumber(sumTotalChangeValueRatio), 'percent')}</Table.Summary.Cell>
                    </Table.Summary.Row>
                </Table.Summary>
                )}
            />
        </Layout>
    )
};

export default StocksOwnedTable;