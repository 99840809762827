import React from "react";
import { useEffectOnce } from "react-use";
import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "antd";
import _ from "lodash";
import { useContextSelector } from 'use-context-selector';
import { useAuthState } from "@Contexts/Auth";
import Header from "@Components/Header";
import routes from "@Routes";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import SettingAddNewStock from "./Settings/stocks/AddNew";
import SettingUpdateStock from "./Settings/stocks/Update";
import Profile from "./Profile";

import Users from "./Users";
import AddNewUser from "./Users/AddNewUser";
import EditUser from "./Users/EditUser";

import Borrowers from "./Borrowers";
import AddNewBorrower from "./Borrowers/AddNewBorrower";
import BorrowerDetailAsset from "./Borrowers/Detail/Asset";
import BorrowerDetailInfo from "./Borrowers/Detail/Info";

import LendersAsset from "./Lenders";
import LendersInfo from "./Lenders/LendersInfoList";
import AddNewLender from "./Lenders/AddNewLender";
import LenderDetailAsset from "./Lenders/Detail/Asset";
import LenderDetailSourceManager from "./Lenders/Detail/SourceManager";
import LenderDetailInfo from "./Lenders/Detail/Info";
import LenderDetailTransactionHistory from "./Lenders/Detail/TransactionHistory";
import LenderAddNewSource from "./Lenders/Detail/SourceManager/AddNewSource";

import HistoryMoneyTransaction from "./History/MoneyTransaction";
import HistoryStockTransaction from "./History/StockTransaction";
import HistoryOperationalEfficiency from "./History/OperationalEfficiency";

import SourcesAssetList from "./Lenders/SourcesAssetList";
import SourcesStockList from "./Lenders/SourcesStockList";
import SourceDetail from "./Lenders/SourceDetail";
import AccessDenied from "@Components/AccessDenied";

import { subscribeStockPrice, subscribeOrderBook } from "../../services/realtime";
import { StockContext } from '../../contexts/StocksProvider';

const Home = () => {
    let { authState } = useAuthState() as any;
    let { currentUser } = authState;
    const isSuperAdmin = currentUser?.isSuperAdmin;

    const setStockDefaultGlobalState = useContextSelector(
        StockContext,
        (s) => s[1],
    );

    const initSubscriptionStockPrice = () => {

        subscribeOrderBook((response) => {
            console.log('subscribeOrderBook', response);
        });

        subscribeStockPrice((stockList: NSApp.StockRealtime[]) => {
            if (stockList.length) {

                let newStocksStateArr: any[] = [];

                for (const _stock of stockList) {
                    if (!_stock.stockCode) {
                        continue;
                    }

                    newStocksStateArr.push(_stock);
                }

                const newStocksState = _.keyBy(newStocksStateArr, 'stockCode')

                setStockDefaultGlobalState((oldState: any) => (
                    {
                        ...oldState,
                        ...newStocksState,
                    }
                ));
            }
        });
    }

    useEffectOnce(() => {
        initSubscriptionStockPrice();
    });

    return (
        <Layout className="h-screen">
            <Header />
            <Layout.Content className="overflow-auto">
                <Routes>
                    <Route path={routes.DASHBOARD} element={<Dashboard />} />
                    <Route path={routes.HOME} element={<Navigate replace to={routes.DASHBOARD} />} />

                    <Route path={routes.BORROWERS} element={<Borrowers />} />
                    <Route path={routes.ADD_NEW_BORROWER} element={<AddNewBorrower />} />
                    <Route path={routes.BORROWER_DETAIL_ASSET} element={<BorrowerDetailAsset />} />
                    <Route path={routes.BORROWER_DETAIL_INFO} element={<BorrowerDetailInfo />} />

                    <Route path={routes.LENDERS_ASSET} element={isSuperAdmin ? <LendersAsset /> : <AccessDenied/>} />
                    <Route path={routes.LENDERS_INFO} element={isSuperAdmin ? <LendersInfo /> : <AccessDenied/>} />
                    <Route path={routes.ADD_NEW_LENDER} element={isSuperAdmin ? <AddNewLender /> : <AccessDenied/>} />
                    <Route path={routes.LENDER_ADD_NEW_SOURCE} element={isSuperAdmin ? <LenderAddNewSource /> : <AccessDenied/>} />
                    <Route path={routes.LENDER_DETAIL_ASSET} element={isSuperAdmin ? <LenderDetailAsset /> : <AccessDenied/>} />
                    <Route path={routes.LENDER_DETAIL_SOURCE_MANAGER} element={isSuperAdmin ? <LenderDetailSourceManager /> : <AccessDenied/>} />
                    <Route path={routes.LENDER_DETAIL_INFO} element={isSuperAdmin ? <LenderDetailInfo /> : <AccessDenied/>} />
                    <Route path={routes.LENDER_DETAIL_TRANSACTION_HISTORY} element={isSuperAdmin ? <LenderDetailTransactionHistory /> : <AccessDenied/>} />

                    <Route path={routes.SOURCES_ASSET} element={<SourcesAssetList />} />
                    <Route path={routes.SOURCES_STOCKS} element={<SourcesStockList />} />
                    <Route path={routes.SOURCE_DETAIL} element={<SourceDetail />} />

                    <Route path={routes.HISTORY_MONEY_TRANSACTIONS} element={isSuperAdmin ? <HistoryMoneyTransaction /> : <AccessDenied/>} />
                    <Route path={routes.HISTORY_STOCK_TRANSACTIONS} element={isSuperAdmin ? <HistoryStockTransaction /> : <AccessDenied/>} />
                    <Route path={routes.HISTORY_OPERATIONAL_EFFICIENCY} element={isSuperAdmin ? <HistoryOperationalEfficiency /> : <AccessDenied/>} />

                    <Route path={routes.USERS} element={isSuperAdmin ? <Users /> : <AccessDenied/>} />
                    <Route path={routes.ADD_NEW_USER} element={isSuperAdmin ? <AddNewUser /> : <AccessDenied/>} />
                    <Route path={routes.EDIT_USER} element={isSuperAdmin ? <EditUser /> : <AccessDenied/>} />

                    <Route path={routes.SETTINGS} element={isSuperAdmin ? <Settings /> : <AccessDenied/>} />
                    <Route path={routes.SETTINGS_ADD_STOCK} element={isSuperAdmin ? <SettingAddNewStock /> : <AccessDenied/>} />
                    <Route path={routes.SETTINGS_UPDATE_STOCK} element={isSuperAdmin ? <SettingUpdateStock /> : <AccessDenied/>} />
                    <Route path={routes.PROFILE} element={<Profile />} />
                </Routes>
            </Layout.Content>
        </Layout>
    )
}

export default Home;