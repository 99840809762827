import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { Layout, Card, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import _ from 'lodash';

import { moneyFormat, toRoute } from "@Services/utils";
import api from "@Services/api";
import routes from "@Routes";
import { PAGE_SIZE_SMALL } from "@Configs";

type SummaryFeeAndInterest = {
    index: number;
    source: NSApp.Source;
    account: NSApp.Account;
    purchaseCommission: number;
    sellingCommission: number;
    totalTransactionFee: number;
    minimumLoanInterest: number;
    total: number;
}

const FeeAndProfitTable = ({ sourceDetail }) => {

    const [dataList, setDataList] = useState<SummaryFeeAndInterest[]>([]);
    const [totalPurchaseCommission, setTotalPurchaseCommission] = useState<number>(0);
    const [totalSellingCommission, setSellingCommission] = useState<number>(0);
    const [totalTransactionFee, setTotalTransactionFee] = useState<number>(0);
    const [totalLoanInterest, setTotalLoanInterest] = useState<number>(0);
    const [totalAll, setTotalAll] = useState<number>(0);

    const fetchFeeAndInterest = async () => {
        const [err, resp]: any = await api.get(`source/fee-and-interest/${sourceDetail.id}`);

        if (err) {
            return;
        }

        const dataList = resp.data;
        setDataList(dataList);

        const _totalPurchaseCommission = _.sumBy(dataList, 'purchaseCommission');
        const _totalSellingCommission = _.sumBy(dataList, 'sellingCommission');
        const _totalTransactionFee = _.sumBy(dataList, 'totalTransactionFee');
        const _totalMinimumLoanInterest = _.sumBy(dataList, 'minimumLoanInterest');
        const _total = _.sumBy(dataList, 'total');

        setTotalPurchaseCommission(_totalPurchaseCommission);
        setSellingCommission(_totalSellingCommission);
        setTotalTransactionFee(_totalTransactionFee);
        setTotalLoanInterest(_totalMinimumLoanInterest);
        setTotalAll(_total);
    };

    useEffectOnce(() => {
        fetchFeeAndInterest();
    });

    const columns : ColumnsType<SummaryFeeAndInterest> = [
        {
            title: '',
            dataIndex: 'account',
            key: 'account',
            render: (value, record) => <Link className="font-bold" to={toRoute(routes.BORROWER_DETAIL_ASSET, { id: value.profile?.id })}>
                {value.profile?.fullname} - ID: {value.profile?.shortId}
            </Link>
        },
        {
            title: 'Hoa hồng phí mua',
            dataIndex: 'purchaseCommission',
            key: 'purchaseCommission',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Hoa hồng phí bán',
            dataIndex: 'sellingCommission',
            key: 'sellingCommission',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Tổng HH phí giao dịch',
            dataIndex: 'totalTransactionFee',
            key: 'totalTransactionFee',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Lãi cho vay',
            dataIndex: 'minimumLoanInterest',
            key: 'minimumLoanInterest',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Tổng thu',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
    ];

    return (
        <Layout>
            <Card className="card-table-header">
                <Typography.Title level={5} className="mb-0">Bảng theo dõi phí và lãi</Typography.Title>
            </Card>
            <Table
                bordered
                sticky
                rowKey={r => r.account.profile.id}
                dataSource={dataList}
                columns={columns}
                pagination={{ defaultPageSize: PAGE_SIZE_SMALL, size: 'small' }}
                summary={() => dataList.length > 0 &&  (
                    <Table.Summary fixed={'top'}>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} className="text-right font-bold">Tổng</Table.Summary.Cell>
                            <Table.Summary.Cell index={1} className="text-right font-bold">{moneyFormat(totalPurchaseCommission)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={2} className="text-right font-bold">{moneyFormat(totalSellingCommission)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={3} className="text-right font-bold">{moneyFormat(totalTransactionFee)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(totalLoanInterest)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={5} className="text-right font-bold">{moneyFormat(totalAll)}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        </Layout>
    )
};

export default FeeAndProfitTable;