import React, { useState } from "react";
import { Alert, Row, Col, Button, Modal, Form, Input, InputNumber, Select, notification, Popconfirm, DatePicker } from "antd";
import { formatterMoney, parserMoney } from "@Services/utils";
import api from "@Services/api";
import { DATE_FORMAT } from "@Configs";

const labelCol = 6;
const wrapperCol = 18;

const CloseContractModal = ({ profile, isVisible, setIsVisible, contracts, setContracts, onSubmit }) => {
    const [errors, setErrors]: any = useState([]);
    const [form] = Form.useForm();

    const onCancelModal = () => {
        setErrors([]);
        setIsVisible(false);
        form.resetFields();
    }

    const onChangeSelectedContract = (value) => {
        const selectedContract = contracts.find(contract => contract.id === value);
        const _balance = Number(selectedContract.contractValue);

        if (selectedContract) {
            form.setFieldsValue({
                source: selectedContract.source.sourceCode,
                contractValue: selectedContract.contractValue,
                balance: _balance
            });
        }
    };

    const onFinish = async (values) => {
        const [err, resp]: any = await api.post(`contract/close/${values.contract_id}`, {
            date: values.date
        });

        if (err) {
            setErrors(err);

            if (err?.response?.data?.errors) {
                setErrors(err.response.data.errors);
            }

            if (err?.response?.data?.message) {
                setErrors([err.response.data.message]);
                notification.error({
                    message: err.response.data.message,
                });
            }

            return;
        }

        onSubmit()
        onCancelModal();
        return notification.success({
            message: "Đóng hợp đồng thành công"
        });

    }

    const onConfirm = async () => {
        await form.submit()
    }

    return (
        <Modal
            centered
            title={"Đóng hợp đồng"}
            visible={isVisible}
            onCancel={onCancelModal}
            destroyOnClose={true}
            footer={null}
            width={650}
        >
            {(errors.length > 0) && (
                <Row className="mb-4">
                    <Col span={24}>
                        {errors.map((error: any, index: number) => {
                            return <Alert
                                key={index}
                                className="mb-1"
                                type={"error"}
                                message={error.msg}
                                showIcon
                            />
                        })}
                    </Col>
                </Row>
            )}
            <Form
                form={form}
                layout="horizontal"
                labelAlign="left"
                colon={false}
                labelCol={{ span: labelCol }}
                wrapperCol={{ span: wrapperCol }}
                initialValues={{ id: `${profile.fullname} - ${profile.shortId}` }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="id"
                    label="Tài khoản"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="contract_id"
                    label="Số hợp đồng"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    {contracts.length > 0 ? (
                    <Select onChange={onChangeSelectedContract}>
                        {contracts.map(contract => {
                            return <Select.Option key={contract.id} value={contract.id}>{contract.contractNo}</Select.Option>
                        })}
                    </Select>
                    ) : (
                        <Input disabled placeholder="Chưa có hợp đồng nào" />
                    )}
                </Form.Item>
                <Form.Item
                    name="date"
                    label="Ngày đóng hợp đồng"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống"
                        }
                    ]}
                >
                    <DatePicker
                        className="w-full"
                        placeholder=""
                        format={DATE_FORMAT}
                    />
                </Form.Item>
                <Form.Item
                    name="source"
                    label="Kho"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="balance"
                    label="Tổng tiền nạp/rút"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <InputNumber
                        disabled
                        className="w-full"
                        controls={false}
                        formatter={value => formatterMoney(value)}
                        parser={value => parserMoney(value)}
                    />
                </Form.Item>
                <Form.Item
                    name="note"
                    label="Nội dung"
                >
                    <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                    <div className="flex justify-end">
                        <Popconfirm
                            title="Bạn có chắc chắn muốn đóng hợp đồng này?"
                            onConfirm={onConfirm}
                            okText="Đồng ý"
                            cancelText="Hủy"
                        >
                            <Button type="primary" htmlType="submit">Thêm</Button>
                        </Popconfirm>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default CloseContractModal;