import React from 'react';
import { moneyFormat, getColorClsName } from "@Services/utils";

export const renderValueWithColor = (value: number, type: string = 'normal', fontWeight: string = 'normal') => {
    if (value === null) {
        return '-';
    }
    if (value === Infinity) {
        return '♾️';
    }

    const _value = type === 'percent' ? `${value.toFixed(2)}%` : moneyFormat(value);
    return <span className={`${getColorClsName(value)} font-${fontWeight}`}>{_value}</span>;
}