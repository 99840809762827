import React, { useState, useEffect, useRef, useCallback } from "react";
import { useEffectOnce, useUnmount } from 'react-use';
import { Collapse, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { DATE_TIME_FORMAT } from "@Configs";
import { moneyFormat, toRoute } from "@Services/utils";
import FullscreenToggle from "@Components/FullscreenToggle";
import { subscribeNotification } from "@Services/novuRealtime";

import routes from "@Routes";
import api from "@Services/api";

const TOTAL_ADMIN = 3;
const TABLE_COLORS = ['table-bg-blue', 'table-bg-orange', 'table-bg-green'];

const DashboardOrderBook = () => {
    const [buyOrderBook, setBuyOrderBook] = useState<any>([]);
    const [sellOrderBook, setSellOrderBook] = useState<any>([]);
    const [totalBuyOrder, setTotalBuyOrder] = useState<number>(0);
    const [totalSellOrder, setTotalSellOrder] = useState<number>(0);

    const [totalUnseen, setTotalUnseen] = useState(0);
    const notificationSocketRef = useRef<any>(null);
    const fetchOrderBookInterval = useRef<any>(null);

    const navigate = useNavigate();

    const fetchBuyOrderBook = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get(`order-book/get-waiting-and-ordered-list`, {
            type: 'buy',
            page: 1,
            pageSize: 1000,
        });

        if (resp) {
            const _orderBook = resp.data.orderBooks.map((orderBook: any) => {
                orderBook.date = moment(orderBook.date);
                return orderBook;
            });

            setBuyOrderBook(_orderBook);
            setTotalBuyOrder(resp.data.total);
        }
    };

    const assignOrder = (list, index) => {
        return list.filter(order => order.id % TOTAL_ADMIN === index);
    }

    const fetchSellOrderBook = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get(`order-book/get-waiting-and-ordered-list`, {
            type: 'sell',
            page: 1,
            pageSize: 1000,
        });

        if (resp) {
            const _orderBook = resp.data.orderBooks.map((orderBook: any) => {
                orderBook.date = moment(orderBook.date);
                return orderBook;
            });
            setSellOrderBook(_orderBook);
            setTotalSellOrder(resp.data.total);
        }
    };

    const intervalFetchData = () => {
        const TEN_SECONDS = 10 * 1000;
        setTimeout(() => {
            if (fetchOrderBookInterval.current) {
                clearInterval(fetchOrderBookInterval.current);
            }

            fetchOrderBookInterval.current = setInterval(async () => {
                await fetchBuyOrderBook();
                await fetchSellOrderBook();
            }, TEN_SECONDS);
        }, TEN_SECONDS)
    }

    const initSubscriptionNotification = useCallback(async () => {
        const socketInstance = await subscribeNotification((data: any) => {
            const { unseenCount } = data;

            if (!isNaN(unseenCount)) {
                setTotalUnseen(unseenCount);
            }
        });

        notificationSocketRef.current = socketInstance;
    }, []);

    useEffect(() => {
        initSubscriptionNotification();
    }, [initSubscriptionNotification]);

    useUnmount(() => {
        if (notificationSocketRef.current) {
            notificationSocketRef.current.io.disconnect();
        }

        if (fetchOrderBookInterval.current) {
            clearInterval(fetchOrderBookInterval.current);
            fetchOrderBookInterval.current = null;
        }
    });

    useEffectOnce(() => {
        intervalFetchData();
    });

    useEffect(() => {
        fetchBuyOrderBook();
        fetchSellOrderBook();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalUnseen]);

    const columns: ColumnsType<any> = [
        {
            title: 'Tên',
            dataIndex: 'account',
            key: 'name',
            render: (value) => value.profile.fullname,
        },
        {
            title: 'Mã lô cổ phiếu',
            dataIndex: 'lot',
            key: 'lot',
            align: 'center',
        },
        {
            title: 'Lệnh',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (value) => {
                if (value === 'buy') {
                    return <span className="text-success font-bold">MUA</span>;
                }
                if (value === 'sell') {
                    return <span className="text-error font-bold">BÁN</span>;
                }
            },
        },
        {
            title: 'Kho',
            dataIndex: 'source',
            key: 'sourceCode',
            align: 'center',
            render: (value) => value?.sourceCode,
        },
        {
            title: 'Ngày thanh toán',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            render: (value) => value.format(DATE_TIME_FORMAT),
        },
        {
            title: 'Mã cổ phiếu',
            dataIndex: 'stockCode',
            key: 'stockCode',
            align: 'center',
            render: (value, record) => <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{value.toUpperCase()}</span>,
        },
        {
            title: 'Giá',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (value, record) => <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{moneyFormat(value)}</span>,
        },
        {
            title: 'Khối lượng',
            dataIndex: 'volume',
            key: 'volume',
            align: 'right',
            render: (value, record) => <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{moneyFormat(value)}</span>,
        },
        {
            title: 'Tình trạng',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (value, record) => {
                let _status = '';
                let _subStatus = '';

                if (record.userStatus === 'waiting-cancel') {
                    _subStatus = "(Chờ huỷ)";
                }
                if (value === 'waiting') {
                    _status = "Chờ đặt lệnh";
                }
                if (value === 'ordered') {
                    _status = "Đã đặt lệnh";
                }
                if (value === 'partial-matched') {
                    _status = "Khớp một phần"
                }
                if (value === 'matched') {
                    _status = "Đã khớp lệnh";
                }
                if (value === 'canceled') {
                    _status = "Đã huỷ lệnh";
                }
                return <div>{_status} {_subStatus && <span className="text-error font-bold">{_subStatus}</span>}</div>
            },
        },
    ];

    const OrderBookTable = ({ list, color = '' }) => {
        return (
            <Table
                bordered
                rowKey={r => `${r.lot}-${r.id}`}
                dataSource={list}
                columns={columns}
                pagination={{
                    size: "small",
                    pageSize: 5,
                }}
                rowClassName={`cursor-pointer ${color && color}`}
                onRow={(record) => {
                    return {
                        onClick: () => navigate(toRoute(routes.BORROWER_DETAIL_ASSET, { id: record.account.profileId }), { state: { tabKey: 'order-book' }}),
                        onContextMenu: () => window.open(toRoute(routes.BORROWER_DETAIL_ASSET, { id: record.account.profileId }))
                    };
                }}
            />
        );
    };

    return (
        <div id="orderBookCollapse">
            <Collapse defaultActiveKey="order_book" collapsible="header" className="h-full">
                <Collapse.Panel
                    key="order_book"
                    header={<span className="font-bold">Lệnh chưa thực hiện ({totalBuyOrder + totalSellOrder})</span>}
                    extra={<FullscreenToggle elementId={'orderBookCollapse'}/>}
                >
                    <OrderBookTable list={sellOrderBook} />
                    {[...Array(TOTAL_ADMIN)].map((e, i) => {
                        const ordersList = assignOrder(buyOrderBook, i);
                        if (!ordersList.length) {
                            return <div key={i}></div>;
                        }

                        return <OrderBookTable
                            key={i}
                            list={ordersList}
                            color={TABLE_COLORS[i]}
                        />
                    })}
                </Collapse.Panel>
            </Collapse>
        </div>
    )
};

export default DashboardOrderBook;