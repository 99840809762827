import React, { useEffect, useState } from "react";
import { Layout, Button, Table, Typography, Input, Spin, notification, Select, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { useNavigate, Link } from "react-router-dom";

import { useReducedState, moneyFormat, toRoute } from "@Services/utils";
import { PAGE_SIZE_NORMAL } from "@Configs";
import api from "@Services/api";
import routes from "@Routes";

const Borrowers = () => {
    const navigate = useNavigate();

    const [listBorrowers, setListBorrowers] = useState<NSApp.Borrower[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>('');
    const [filter, setFilter] = useState<string>('');

    const [sumTotalBuyValue, setSumTotalBuyValue] = useState<number>(0);
    const [sumNav2, setSumNav2] = useState<number>(0);
    const [sumPurchasingPower, setSumPurchasingPower] = useState<number>(0);
    const [sumDepositRequired, setSumDepositRequired] = useState<number>(0);

    const [form] = Form.useForm();

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_NORMAL,
        total: 0
    });

    const fetchBorrowers = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`borrower/list`, {
            filter, searchKey, page: pagination.current, pageSize: PAGE_SIZE_NORMAL
        });

        if (err) {
            notification.error({
                message: err?.response?.data?.message
            });
            return;
        }

        let {
            borrowers, total,
            sumTotalBuyValue,
            sumNav2,
            sumPurchasingPower,
            sumDepositRequired,
        } = resp?.data;

        setListBorrowers(borrowers);
        setPagination({ total });
        setSumTotalBuyValue(sumTotalBuyValue);
        setSumNav2(sumNav2);
        setSumPurchasingPower(sumPurchasingPower);
        setSumDepositRequired(sumDepositRequired);
        setIsFetching(false);
    }

    useEffect(() => {
        fetchBorrowers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current, searchKey, filter]);

    const onSearch = (value: string) => {
        setPagination({current: 1});
        setSearchKey(value);
    };

    const onFinishFilter = (values) => {
        setFilter(values.filter);
    };

    const resetFilter = () => {
        setFilter('');
        setSearchKey('');
        form.setFieldsValue({
            filter: '',
        });
    }

    const exportBorrowersToExcel = async () => {
        if (isExporting) {
            return;
        }

        setIsExporting(true);
        const [err, resp]: any = await api.post(`borrower/export-to-excel`, {
            filter, searchKey
        }, {}, {
            responseType: 'blob', // important
        });

        setIsExporting(false);
        if (err) {
            notification.error(err?.response?.data?.message);
            return;
        }

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'nguoi_vay.xlsx');
        document.body.appendChild(link);
        link.click();
    }

    const columns: ColumnsType<any> = [
            {
                title: 'Tên',
                dataIndex: 'profile.fullname',
                key: 'profile.fullname',
                render: (value, record) => <Link className="font-bold" to={toRoute(routes.BORROWER_DETAIL_ASSET, { id: record['profile.id'] })}>{value}</Link>
            },
            {
                title: 'ID',
                dataIndex: 'profile.shortId',
                key: 'profile.shortId',
                render: (value) => <div className="text-one-line">{value}</div>
            },
            {
                title: 'Tổng giá trị mua',
                dataIndex: 'aggregate_total_buy_value',
                key: 'aggregate_total_buy_value',
                align: 'right',
                render: (value) => value !== null ? moneyFormat(value) : '-',
            },
            {
                title: 'NAV2',
                dataIndex: 'aggregate_nav2',
                key: 'aggregate_nav2',
                align: 'right',
                render: (value) => value !== null ? moneyFormat(value) : '-',
            },
            {
                title: 'Tỉ lệ hiện tại',
                dataIndex: 'aggregate_current_margin_ratio',
                key: 'aggregate_current_margin_ratio',
                align: 'right',
                render: (value) => value !== null ? `${value}%` : '-',
            },
            {
                title: 'Tỉ lệ ký quỹ',
                dataIndex: 'profile.margin_ratio',
                key: 'profile.margin_ratio',
                align: 'right',
                render: (value) => value !== null ? `${value}%` : '-',
            },
            {
                title: 'Tỉ lệ force sell',
                dataIndex: 'profile.maintenance_margin_ratio',
                key: 'profile.maintenance_margin_ratio',
                align: 'right',
                render: (value) => value !== null ? `${value}%` : '-',
            },
            {
                title: 'Sức mua',
                dataIndex: 'aggregate_expected_purchasing_power',
                key: 'aggregate_expected_purchasing_power',
                align: 'right',
                render: (value) => value !== null ? moneyFormat(value) : '-',
            },
            {
                title: 'Yêu cầu nộp tiền',
                dataIndex: 'aggregate_deposit_require',
                key: 'aggregate_deposit_require',
                align: 'right',
                render: (value) => {
                    if (value === null) return '-';
                    if (value === 0) return '0';
                    return <Typography.Text type="danger">{moneyFormat(value)}</Typography.Text>
                },
            },
    ];

    return (
        <Layout className="p-4">
            <Layout className="flex-row justify-between items-center mb-4">
                <div className="flex">
                    <Typography.Title level={4} className="uppercase font-bold mb-0 mr-4">Danh sách người vay</Typography.Title>
                    <Button onClick={() => navigate(routes.ADD_NEW_BORROWER)}>Thêm mới</Button>
                </div>
                <div
                    className="flex flex-col mr-2 text-gray-500 hover:text-gray-800 cursor-pointer"
                    onClick={exportBorrowersToExcel}
                >
                    {isExporting ?
                        <Spin />
                    :
                        <UploadOutlined className="text-lg" />
                    }
                    <span className="text-xs">Export</span>
                </div>
            </Layout>

            <div className="mb-2">
                <Typography.Text><Typography.Link onClick={resetFilter}>Tất cả</Typography.Link> ({pagination.total})</Typography.Text>
            </div>

            <div className="flex mb-4">
                <Form
                    form={form}
                    layout="inline"
                    onFinish={onFinishFilter}
                    initialValues={{ filter: '' }}
                    className="mr-4"
                >
                    <Form.Item name="filter" className="mr-0">
                        <Select style={{ width: 250 }}>
                            <Select.Option value="">Tất cả người vay</Select.Option>
                            <Select.Option value="expectedPurchasingPowerNotZero">Sức mua {'>'} 0</Select.Option>
                            {/* <Select.Option value="currentMarginRatioLessThanMarginRatio">Tỉ lệ hiện tại {'<='} tỉ lệ ký quỹ</Select.Option> */}
                            <Select.Option value="depositRequireNotZero">Yêu cầu nộp tiền {'>'} 0</Select.Option>
                        </Select>
                    </Form.Item>
                    <Button htmlType="submit">Lọc</Button>
                </Form>

                <Input.Search
                    placeholder="Nhập Tên, ID"
                    allowClear
                    enterButton="Tìm kiếm"
                    style={{ width: 300 }}
                    onSearch={onSearch}
                />
            </div>

            <Table
                bordered
                loading={isFetching}
                rowKey={(r: any) => r.id}
                dataSource={listBorrowers}
                columns={columns}
                pagination={{
                    ...pagination,
                    size: 'small',
                    showSizeChanger: false,
                    onChange: page => {
                        setPagination({ current: page });
                    },
                }}
                sticky
                summary={() => listBorrowers.length > 0 && (
                    <Table.Summary fixed={'top'}>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} className="text-right font-bold">Tổng</Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumTotalBuyValue)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumNav2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumPurchasingPower)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumDepositRequired)}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        </Layout>
    );
}

export default Borrowers;