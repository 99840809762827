import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
    const navigateTo = useNavigate();

    const goBack = () => {
        navigateTo(-1);
    }

    return <div className="cursor-pointer flex items-center text-primary" onClick={goBack}>
        <ArrowLeftOutlined className="mr-1"/>Trở lại
    </div>
};

export default BackButton;