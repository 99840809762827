import React, { useState } from "react";
import { Button, DatePicker, Select, Modal, Form, Input, Row, Col, InputNumber, Typography, notification, Alert } from "antd";

import { moneyFormat, formatterMoney } from "@Services/utils";
import { DATE_TIME_FORMAT } from "@Configs";
import api from "@Services/api";

const labelCol = 6;
const wrapperCol = 18;

const TransactionModal = ({ isVisible, setIsVisible, type, setType, profile, account, updateAccount, increaseForceReload }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState<number>(0);
    const [errors, setErrors] = useState<any[]>([]);
    const currentNAV1 = account.nav1;

    let typeLabel = '';
    if (type === 'deposit') {
        typeLabel = 'Nạp';
    }
    if (type === 'withdrawal') {
        typeLabel = 'Rút';
    }

    const onCancelModal = () => {
        setValue(0);
        setErrors([]);
        setIsVisible(false);
    }

    const onChangeValue = (e) => {
        if (e) {
            setValue(e);
        } else {
            setValue(0);
        }
    }

    const onChangeType = (value) => {
        setType(value);
    }

    const onFinish = async (values) => {
        setLoading(true);
        let err, resp
        if (type === "deposit") {
            [err, resp] = await api.post(`borrower/deposit/${profile.id}`, {
                amount: values.value,
                date: values.date,
                note: values.content
            }) as any
        }

        if (type === 'withdrawal') {
            [err, resp] = await api.post(`borrower/withdrawal/${profile.id}`, {
                amount: values.value,
                date: values.date,
                note: values.content
            }) as any
        }
        setLoading(false);

        if (err) {
            if (err?.response?.data?.errors) {
                setErrors(err.response.data.errors);
            }

            if (err?.response?.data?.message) {
                setErrors([{ msg: err.response.data.message }])
            }

            notification.error({
                message: `Có lỗi trong quá trình ${typeLabel} tiền`
            })
            return;
        }

        const { account } = resp.data;

        setErrors([]);
        notification.success({
            message: `${typeLabel} tiền thành công!`,
        });
        updateAccount(account);
        increaseForceReload();
        onCancelModal();
    }

    return (
        <Modal
            centered
            title={`${typeLabel} Tiền`}
            visible={isVisible}
            onCancel={onCancelModal}
            destroyOnClose={true}
            footer={null}
            width={650}
        >
            {(errors.length > 0) && (
                <Row className="mb-4">
                    <Col span={24}>
                        {errors.map((error: any, index: number) => {
                            return <Alert
                                key={index}
                                className="mb-1"
                                type={"error"}
                                message={error.msg}
                                showIcon
                            />
                        })}
                    </Col>
                </Row>
            )}
            <Form
                layout="horizontal"
                labelAlign="left"
                colon={false}
                labelCol={{ span: labelCol }}
                wrapperCol={{ span: wrapperCol }}
                initialValues={{ type: type, id: `${profile.fullname} - ${profile.shortId}` }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="id"
                    label="Tài khoản"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name="date"
                    label="Ngày"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <DatePicker className="w-full" placeholder="" format={DATE_TIME_FORMAT} showTime />
                </Form.Item>
                <Form.Item
                    name="type"
                    label="Loại giao dịch"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <Select onChange={onChangeType} disabled>
                        <Select.Option value="deposit">Nạp tiền</Select.Option>
                        <Select.Option value="withdrawal">Rút tiền</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="value"
                    label="Số tiền"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <InputNumber
                        className="w-full"
                        onChange={onChangeValue}
                        formatter={value => formatterMoney(value)}
                    />
                </Form.Item>
                <Row className="mb-6">
                    <Col offset={6} span={18}>
                        <div className="flex justify-between">
                            <span>NAV1 hiện tại:</span>
                            <span className="font-bold">{moneyFormat(currentNAV1)} VND</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Số tiền {typeLabel.toLowerCase()}:</span>
                            <Typography.Text className="font-bold" type={type === 'deposit' ? 'success' : 'danger'}>{moneyFormat(value)} VND</Typography.Text>
                        </div>
                        <div className="flex justify-between">
                            <span>NAV1 sau khi {typeLabel.toLowerCase()} tiền:</span>
                            <Typography.Text className="font-bold" type={type === 'deposit' ? 'success' : 'danger'}>
                                {moneyFormat(type === 'deposit' ? currentNAV1 + value : currentNAV1 - value)} VND
                            </Typography.Text>
                        </div>
                    </Col>
                </Row>
                <Form.Item
                    name="content"
                    label="Nội dung"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                    <div className="flex justify-end">
                        <Button type="primary" htmlType="submit" loading={loading}>Thêm</Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default TransactionModal;