import React, { useState }  from "react";
import { Modal, Form, Row, Col, Alert, Input, InputNumber, DatePicker, Button, message, notification } from "antd";
import { formatterMoney, parserMoney } from "@Services/utils";
import { DATE_FORMAT } from "@Configs";
import api from "@Services/api";

const labelCol = 6;
const wrapperCol = 18;

const TransactionModal = ({ id, isVisible, setIsVisible, profile, type, fetchSourceDetail }) => {
    const [errors, setErrors]: any = useState([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    let modalTitle = '';
    if (type === 'deposit') {
        modalTitle = 'BÙ LỖ KHO';
    }
    if (type === 'withdrawal') {
        modalTitle = 'RÚT LÃI KHO';
    }

    const onCancelModal = () => {
        setErrors([]);
        setIsVisible(false);
        form.resetFields();
    }

    const onFinishTransaction = async (values) => {
        const endPoint = type === 'deposit' ? `source/cover-losses/${id}` : `source/withdraw-profit/${id}`;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(endPoint, {
            ...values
        });

        if (err) {
            if (err.response?.data?.errors) {
                return notification.error({
                    message: err.response?.data?.errors[0]
                })
            }

            if (err.response?.data?.message) {
                return notification.error({
                    message: err.response?.data?.message
                })
            }
            return notification.error({
                message: "Có lỗi trong quá trình cập nhật kho"
            })
        }

        notification.success({
            message: "Cập nhật thành công"
        })
        fetchSourceDetail();
        onCancelModal();
    };

    return (
        <Modal
            centered
            title={modalTitle}
            visible={isVisible}
            onCancel={onCancelModal}
            destroyOnClose={true}
            footer={null}
            width={650}
        >
            <div className="flex justify-around text-base mb-10">
                <span>Số kho: <strong>{profile.sourceCode}</strong></span>
                <span>Tên chủ TK: <strong>{profile.securitiesFullname}</strong></span>
            </div>
            {(errors.length > 0) && (
                <Row className="mb-4">
                    <Col span={24}>
                        {errors.map((error: any, index: number) => {
                            return <Alert
                                key={index}
                                className="mb-1"
                                type={"error"}
                                message={error.msg}
                                showIcon
                            />
                        })}
                    </Col>
                </Row>
            )}
            <Form
                form={form}
                colon={false}
                labelCol={{ span: labelCol }}
                wrapperCol={{ span: wrapperCol }}
                onFinish={onFinishTransaction}
            >
                <Form.Item
                    name="amount"
                    label="Số tiền"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <InputNumber
                        className="w-full"
                        controls={false}
                        formatter={value => formatterMoney(value)}
                        parser={value => parserMoney(value)}
                    />
                </Form.Item>
                <Form.Item
                    name="date"
                    label="Ngày"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <DatePicker
                        className="w-full"
                        placeholder=""
                        format={DATE_FORMAT}
                    />
                </Form.Item>
                <Form.Item name="note" label="Nội dung">
                    <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                    <div className="flex justify-end">
                        <Button type="primary" htmlType="submit" loading={loading}>Thêm</Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default TransactionModal;