import { useReducer } from "react";

export const useReducedState = (initialState) => {
    const reducer = (prev, updated) => ({...prev, ...updated});
    return useReducer(reducer, initialState);
};

export const toRoute = (baseRoute, params) => {
    let route = baseRoute;
    if (params) {
        Object.keys(params).forEach(k => {
            const value = params[k];
            route = route.replace(':' + k, value);
        });
    }
    return route;
};

export const roundDecimalNumber = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const moneyFormat = (value: number) => {
    return value ? Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
};

export const convertCommaToDot = (value: string) => {
    return value ? value.replace(/,/g, '.') : 0;
}

export const formatterMoney = (value) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const parserMoney = (value) => {
    return value!.replace(/\$\s?|(,*)/g, '');
}

export const formatterPercent = (value) => {
    return `${value}`.replace(/,/g, '.');
}

export const parserPercent = (value) => {
    return value!.replace(/,/g, '.');
}

export const getColorClsName = (value: number) => {
    let clsName = 'text-black';
    if (Math.floor(value) > 0) clsName = 'text-success';
    if (Math.floor(value) < 0) clsName = 'text-error';
    return clsName;
}