import React, { useState, useEffect } from "react";
import api from "@Services/api";
import { useNavigate } from "react-router-dom";
import routes from "@Routes";
import localStorage from "@Services/local-storage";
import { useAuthState } from "@Contexts/Auth";

import Loading from "@Components/Loading";

const RequireAuth = ({ children }: { children: React.ReactElement }) => {
    let { authState, setAuthState } = useAuthState() as any;
    const navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
      checkJwtTokenIsLive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkJwtTokenIsLive = async () => {
        if (authState.currentUser) {
            return setIsLoading(false);
        }

        const accessToken = localStorage.get('accessToken');
        if (!accessToken) {
            return navigate(routes.LOGIN, { replace: true });
        }

        let [err, resp]: any = await api.get('admin/profile');

        if (err) {
            console.error('Error when trying to authenticate user:', err);
            return navigate(routes.LOGIN, { replace: true })
        }

        const user = resp?.data?.user;

        setAuthState({ currentUser: user });
        setIsLoading(false);
    };

    if (isLoading) {
        return <Loading />;
    }

    return children;
}

export default RequireAuth;
