import React, { useState } from "react";
import { useEffectOnce } from 'react-use';
import { Layout, Row, Col, Card, message as Notif, Avatar, Space, Alert } from "antd";
import { useParams } from "react-router-dom";

import NormalFooter from "../../components/NormalFooter";
import api from "../../services/api";
import logo from "@Assets/images/logos/svg/logo_01.svg";
import Loading from "../../components/Loading";



const LockAccount = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState<{content: string, type: "success" | "info" | "error" | "warning" | undefined}>({
        content: "",
        type: "success",
    });

    const { token }: any = useParams();

    const doLockAccount = async () => {
        if (!token) {
            Notif.error({
                key: "lock-account-error",
                content: "Yêu cầu không hợp lệ",
            });
            return;
        }

        let [err, ]: any = await api.post("user/lock-account", {
            token
        });

        setIsLoading(false);
        if (err) {

            const errBody = err?.response?.data;

            if (errBody) {
                if (errBody.name === "TokenExpiredError") {
                    setMessage({
                        type: "error",
                        content: "Yêu cầu đã hết hạn. Xin vui lòng thử lại",
                    });
                    return;
                }
            }

            setMessage({
                type: "error",
                content: "Có lỗi xảy ra. Xin vui lòng thử lại.",
            });

            Notif.error({
                key: "lock-account-error",
                content: "Có lỗi xảy ra. Xin vui lòng thử lại.",
            });
            return;
        }

        Notif.success({
            key: "lock-account-success",
            content: "Tạm khoá tài khoản thành công",
        });

        setMessage({
            type: "success",
            content: "Tạm khoá tài khoản thành công",
        })
    };

    useEffectOnce(() => {
        doLockAccount();
    });

    return (
        <Layout className="auth-page">
            <Row justify="center" align="middle" className="h-full">
                <Col xs={22} sm={18} md={12} lg={10} xl={5}>
                    <Space
                        className="brand flex justify-center mb-4"
                        align="center"
                    >
                        <Avatar shape="square" size={128} src={logo} />
                    </Space>
                    <Card
                        title="Xác nhận khoá tài khoản"
                        headStyle={{ textAlign: "center" }}
                    >
                        {isLoading ?
                            <Loading />
                            :
                            <Alert message={message.content} type={message.type} />
                        }
                    </Card>
                </Col>
            </Row>

            <NormalFooter />
        </Layout>
    );
};

export default LockAccount;
