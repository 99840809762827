import React, { useState, useRef, useEffect } from 'react';
import { useEffectOnce, useUnmount } from 'react-use';
import { Table, Button } from 'antd';
import { ColumnsType } from "antd/lib/table";
import moment from 'moment';
import _ from 'lodash';

import { DATE_FORMAT, DATE_TIME_FORMAT, PAGE_SIZE_SMALL } from '@Configs';
import { moneyFormat } from "@Services/utils";
import api from "@Services/api";

type StocksOwnedByLotProps = {
    account: NSApp.Account;
    onSellClick: (record: any) => void;
    forceReload: number;
    fullTable: boolean;
}

const StocksOwnedByLot = (props: StocksOwnedByLotProps) => {
    const { forceReload, account, fullTable, onSellClick } = props;

    const [isFetching, setIsFetching] = useState(false);
    const [lots, setLots] = useState<NSApp.StockPortfolio[]>([]);
    const [sumTotalBuyValue, setSumTotalBuyValue] = useState<number>(0);
    const [sumTotalSellValue, setSumTotalSellValue] = useState<number>(0);
    const [sumTotalLoanInterest, setSumTotalLoanInterest] = useState<number>(0);
    const [sumTotalAdvanceFee, setSumTotalAdvanceFee] = useState<number>(0);

    const fetchDataInterval = useRef<any>(null);

    const fetchPortfolioLots = async () => {
        setIsFetching(true);

        const [err, resp]: any = await api.get(`portfolio/lots/${account.id}`);

        if (err) {
            return;
        }

        const lots = resp.data.lots;

        const _sumTotalBuyValue = _.sumBy(lots, 'totalBuyValue');
        const _sumTotalSellValue = _.sumBy(lots, 'totalSellValue');
        const _sumTotalLoanInterest = _.sumBy(lots, 'totalLoanInterest');
        const _sumTotalAdvanceFee = _.sumBy(lots, 'totalAdvanceFee');

        setLots(resp.data.lots);
        setSumTotalBuyValue(_sumTotalBuyValue);
        setSumTotalSellValue(_sumTotalSellValue);
        setSumTotalLoanInterest(_sumTotalLoanInterest);
        setSumTotalAdvanceFee(_sumTotalAdvanceFee);
        setIsFetching(false);
    };

    const intervalFetchData = () => {
        if (fetchDataInterval.current) {
            clearInterval(fetchDataInterval.current);
        }

        fetchDataInterval.current = setInterval(async () => {
            await fetchPortfolioLots();
        }, 60 * 1000);
    }

    useEffectOnce(() => {
        intervalFetchData();
    });

    useEffect(() => {
        fetchPortfolioLots();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceReload]);

    useUnmount(() => {
        if (fetchDataInterval.current) {
            clearInterval(fetchDataInterval.current);
            fetchDataInterval.current = null;
        }
    });

    let columns: ColumnsType<any> = [
        {
            title: 'Tổng giá trị mua',
            children: [
                {
                    title: 'Mã lô CP',
                    dataIndex: 'lot',
                    key: 'lot',
                    align: 'center',
                    width: fullTable ? 120 : undefined,
                    render: (value) => <span>{value}</span>
                },
                {
                    title: 'Kho',
                    dataIndex: 'source.sourceCode',
                    key: 'source.sourceCode',
                    align: 'center',
                    width: fullTable ? 120 : undefined,
                    render: (value) => <span>{value}</span>

                },
                {
                    title: 'Ngày thanh toán',
                    dataIndex: 'date',
                    key: 'date',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_TIME_FORMAT),
                },
                {
                    title: 'Mã CP',
                    dataIndex: 'stockCode',
                    key: 'stockCode',
                    align: 'center',
                    width: fullTable ? 100 : undefined,
                    render: (value, record) => <span className={`font-bold text-${record.expectedProfit < 0 ? 'error' : 'success'}`}>{value.toUpperCase()}</span>,
                },
                {
                    title: 'Khối lượng',
                    dataIndex: 'volume',
                    key: 'volume',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Giá',
                    dataIndex: 'matchedPrice',
                    key: 'matchedPrice',
                    width: fullTable ? 120 : undefined,
                    render: (value) => moneyFormat(value),
                    align: 'right',
                },
                {
                    title: 'Giá trị mua',
                    dataIndex: 'buyValue',
                    key: 'buyValue',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Phí giao dịch',
                    dataIndex: 'buyFee',
                    key: 'buyFee',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Tổng giá trị mua',
                    dataIndex: 'totalBuyValue',
                    key: 'totalBuyValue',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Kết quả hiện tại',
                    dataIndex: 'expectedProfit',
                    key: 'expectedProfit',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => <span className={`font-bold text-${value < 0 ? 'error' : 'success'}`}>{moneyFormat(value)}</span>,
                },
                {
                    title: 'Đặt lệnh',
                    key: 'order',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (record: NSApp.StockCodeLotToSell) => {
                        // Check khi cổ phiếu chờ về

                        const sellData = {
                            volume: record.matchedVolume,
                            portfolioId: record.id,
                        }

                        if (!record.isSellable) {
                            return <div className='text-gray-600'>{moment(record.sellableDay).format(DATE_FORMAT)}</div>;
                        } else {
                            return (
                                <Button danger type="primary" size="small" className="font-bold" onClick={() => onSellClick({
                                    ...sellData,
                                })}>BÁN</Button>
                            );
                        }
                    },
                },
            ],
        },
    ];

    const extraColumns = [
        {
            title: 'Tổng giá trị thị trường',
            children: [
                {
                    title: 'Ngày hiện tại',
                    dataIndex: 'currentDate',
                    key: 'currentDate',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_FORMAT),
                },
                {
                    title: 'Giá thị trường',
                    dataIndex: 'latestPrice',
                    key: 'latestPrice',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Giá trị thị trường',
                    dataIndex: 'totalMarketValue',
                    key: 'totalMarketValue',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Phí bán',
                    dataIndex: 'sellFree',
                    key: 'sellFree',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Tổng GT thị trường',
                    dataIndex: 'totalSellValue',
                    key: 'totalSellValue',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
            ],
        },
        {
            title: 'Khoản vay',
            children: [
                {
                    title: 'Số tiền vay',
                    dataIndex: 'totalBuyValue',
                    key: 'totalBuyValue',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Ngày phát sinh',
                    dataIndex: 'date',
                    key: 'date',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_TIME_FORMAT),
                },
                {
                    title: 'Ngày kết thúc',
                    dataIndex: 'currentDate',
                    key: 'currentDate',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_TIME_FORMAT),
                },
                {
                    title: 'Số ngày vay',
                    dataIndex: 'totalLoanDays',
                    key: 'totalLoanDays',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                },
                {
                    title: 'Lãi suất vay',
                    dataIndex: 'loanInterest',
                    key: 'loanInterest',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                    render: (value) => `${value}%`
                },
                {
                    title: 'Tổng tiền lãi',
                    dataIndex: 'totalLoanInterest',
                    key: 'totalLoanInterest',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
            ],
        },
        {
            title: 'Phí ứng tiền',
            children: [
                {
                    title: 'Lãi suất',
                    dataIndex: 'loanInterest',
                    key: 'loanInterest',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                    render: (value) => `${value}%`
                },
                {
                    title: 'Số ngày',
                    dataIndex: 'totalAdvanceDays',
                    key: 'totalAdvanceDays',
                    align: 'right',
                    width: fullTable ? 100 : undefined,
                },
                {
                    title: 'Ngày phát sinh',
                    dataIndex: 'currentDate',
                    key: 'currentDate',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_TIME_FORMAT),
                },
                {
                    title: 'Ngày kết thúc',
                    dataIndex: 'closeDate',
                    key: 'closeDate',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_TIME_FORMAT),
                },
                {
                    title: 'Số tiền',
                    dataIndex: 'totalAdvanceFee',
                    key: 'totalAdvanceFee',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
            ],
        },
    ];

    if (fullTable) {
        columns = [...columns, ...extraColumns];
    }

    return (
        <Table
            bordered
            rowKey="id"
            dataSource={lots}
            columns={columns}
            loading={isFetching}
            scroll={{ x: 'max-content' }}
            pagination={{ size: 'small', pageSize: PAGE_SIZE_SMALL, showSizeChanger: false }}
            sticky
            summary={() => lots.length > 0 && (
                <Table.Summary fixed={'top'}>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} className="text-center font-bold">Tổng</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={3} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={5} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={6} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={7} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={8} className="text-right font-bold">{moneyFormat(sumTotalBuyValue)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={9} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={10} className="text-right font-bold"></Table.Summary.Cell>
                        {fullTable && (
                            <>
                                <Table.Summary.Cell index={11} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={12} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={13} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={14} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={15} className="text-right font-bold">{moneyFormat(sumTotalSellValue)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={16} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={17} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={18} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={19} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={20} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={21} className="text-right font-bold">{moneyFormat(sumTotalLoanInterest)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={22} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={23} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={24} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={25} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={26} className="text-right font-bold">{moneyFormat(sumTotalAdvanceFee)}</Table.Summary.Cell>
                            </>
                        )}
                    </Table.Summary.Row>
                </Table.Summary>
            )}
        />
    )
}

export default StocksOwnedByLot;
