import React, { useState, useEffect } from "react";
import { Layout, Space, Button, Typography, Table, Form, Select, DatePicker, notification } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import moment from "moment";

import api from "@Services/api";
import { moneyFormat, useReducedState } from "@Services/utils";
import routes from "@Routes";
import { PAGE_SIZE_NORMAL, DATE_FORMAT, DATE_TIME_FORMAT } from "@Configs";

const HistoryMoneyTransaction = () => {
    const [transactions, setTransactions] = useState<any>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);
    const [transactionType, setTransactionType] = useState<string>('');
    const [form] = Form.useForm();

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_NORMAL,
        total: 0
    });

    const fetchTransactions = async (fromDate, toDate, transactionType) => {
        setIsFetching(true);
        const filter = {
            page: pagination.current,
            pageSize: PAGE_SIZE_NORMAL,
        };

        if (fromDate) {
            filter['fromDate'] = fromDate.clone().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (toDate) {
            filter['toDate'] = toDate.clone().endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (transactionType) {
            filter['transactionType'] = transactionType;
        }

        const [err, resp]: any = await api.get(`transaction/list`, filter);

        if (err) {
            notification.error(err?.response?.data?.message);
            return;
        }

        let { transactions, total } = resp?.data;

        transactions = transactions.map(transaction => {
            transaction.date = moment(transaction.date);
            return transaction;
        });

        setTransactions(transactions);
        setPagination({ total });
        setIsFetching(false);
    }

    useEffect(() => {
        fetchTransactions(fromDate, toDate, transactionType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionType, fromDate, toDate, pagination.current]);

    const onFinishFilter = (values) => {
        const { date, transactionType } = values;
        if (date) {
            setFromDate(date[0]);
            setToDate(date[1]);
        } else {
            setFromDate(null);
            setToDate(null);
        }
        setTransactionType(transactionType);
        setPagination({ current: 1 });
    };

    const resetFilter = () => {
        setFromDate(null);
        setToDate(null);
        setTransactionType('');
        setPagination({ current: 1 });
        form.setFieldsValue({
            date: [],
            transactionType: ''
        });
    }

    const columns: ColumnsType<any> = [
        {
            title: 'Ngày',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            width: 200,
            render: (value) => moment(value).format(DATE_TIME_FORMAT),
        },
        {
            title: 'Họ và tên',
            dataIndex: 'account',
            key: 'fullname',
            align: 'center',
            render: (value) => value.profile?.fullname,
        },
        {
            title: 'Reference',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 150,
        },
        {
            title: 'Loại giao dịch',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            width: 150,
            render: (value) => {
                const transactionType = {
                    'deposit': 'Nạp tiền',
                    'withdrawal': 'Rút tiền',
                    'profit': 'Chốt lãi/lỗ',
                    'cover-loss': 'Bù lỗ',
                    'withdrawal-profit': 'Rút lãi',
                };
                return transactionType[value];
            },
        },
        {
            title: 'Nội dung',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (value) => <span className="font-bold">{moneyFormat(value)}</span>,
        },
    ];

    return (
        <Layout>
            <Layout className="bg-gray p-4">
                <Space>
                    <Button className='custom-btn-tab active'>Nạp rút tiền</Button>
                    <Link to={routes.HISTORY_STOCK_TRANSACTIONS}><Button className='custom-btn-tab'>Giao dịch cổ phiếu</Button></Link>
                    <Link to={routes.HISTORY_OPERATIONAL_EFFICIENCY}><Button className='custom-btn-tab'>Hiệu quả hoạt động</Button></Link>
                </Space>
            </Layout>
            <Layout className="px-4 pt-4">
                <Typography.Title level={4} className="uppercase font-bold mb-4">Lịch sử nạp rút tiền người vay</Typography.Title>
                <Typography.Text><Typography.Link onClick={resetFilter}>Tất cả giao dịch</Typography.Link> ({pagination.total})</Typography.Text>
                <Layout className="flex-row mt-2 mb-5 justify-between items-center">
                    <Form
                        form={form}
                        layout="inline"
                        onFinish={onFinishFilter}
                        initialValues={{ transactionType: '' }}
                    >
                        <Form.Item name="date">
                            <DatePicker.RangePicker
                                format={DATE_FORMAT}
                                placeholder={['Từ ngày', 'Đến ngày']}
                            />
                        </Form.Item>
                        <Form.Item name="transactionType">
                            <Select style={{ width: 200 }}>
                                <Select.Option value="">Tất cả giao dịch</Select.Option>
                                <Select.Option value="deposit">Nạp tiền</Select.Option>
                                <Select.Option value="withdrawal">Rút tiền</Select.Option>
                                <Select.Option value="profit">Chốt lãi/lỗ</Select.Option>
                                <Select.Option value="withdrawal-profit">Rút lãi</Select.Option>
                                <Select.Option value="cover-loss">Bù lỗ</Select.Option>
                            </Select>
                        </Form.Item>
                        <Button htmlType="submit">Lọc</Button>
                    </Form>
                </Layout>

                <Table
                    bordered
                    rowKey={(r: any) => r.id}
                    dataSource={transactions}
                    columns={columns}
                    loading={isFetching}
                    sticky
                    pagination={{
                        ...pagination,
                        size: "small",
                        showSizeChanger: false,
                        onChange: page => {
                            setPagination({ current: page });
                        }
                    }}
                />
            </Layout>
        </Layout>
    )
};

export default HistoryMoneyTransaction;