import React, { useState } from "react";
import { Modal, Button, notification, Form, DatePicker } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "@Configs";
import { ColumnsType } from "antd/lib/table";
import { moneyFormat } from "@Services/utils";
import { Table } from "antd";
import moment from "moment";
import api from "@Services/api";

const StatusAction = ({ type, record, increaseForceReload }) => {
    const canRevert = record.profit === record.cover;
    const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);

    const onWithdrawal = async (values) => {
        const isWithdrawal = type === 'withdraw';
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`portfolio/sold-lots/cover/${record.id}`, {
            date: moment(values.date)
        });

        if (err) {
            notification.error({
                message: 'Có lỗi trong quá trình xử lý',
            });

            if (err?.response?.data?.message) {
                notification.error({
                    message: err.response.data.message,
                });
            }

            if (err?.response?.data?.errors) {
                notification.error({
                    message: err.response.data.errors[0].message,
                });
            }

            return;
        }

        increaseForceReload();

        notification.success({
            message: `${isWithdrawal ? "Rút lãi" : "Bù lỗ"} thành công!`,
        });
    };

    const onRevert = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`portfolio/sold-lots/revert/${record.id}`, {});

        if (err) {
            notification.error({
                message: 'Có lỗi trong quá trình xử lý',
            });

            if (err?.response?.data?.message) {
                notification.error({
                    message: err.response.data.message,
                });
            }

            if (err?.response?.data?.errors) {
                notification.error({
                    message: err.response.data.errors[0].message,
                });
            }

            return;
        }

        increaseForceReload();

        notification.success({
            message: "Hoàn tác thành công!",
        });
    };

    const openWithdrawModal = () => {
        setIsWithdrawModalVisible(true);
    };

    const openConfirmRevertModal = () => {
        Modal.confirm({
            centered: true,
            title: `Bạn chắc chắn muốn hoàn tác?`,
            onOk: () => onRevert(),
        })
    };

    if (canRevert) {
        return <Button size="small" onClick={openConfirmRevertModal} icon={<SyncOutlined />}>Hoàn tác</Button>
    }

    return <>
        {type === "withdraw"
            ? <Button size="small" type="primary" className="custom-btn ant-btn-success" onClick={openWithdrawModal}>Rút lãi</Button>
            : <Button size="small" type="primary" className="custom-btn" danger onClick={openWithdrawModal}>Bù lỗ</Button>
        }
        <Modal
            centered
            footer={null}
            closable={false}
            destroyOnClose={true}
            visible={isWithdrawModalVisible}
        >
            <Form layout="vertical" onFinish={onWithdrawal} preserve={false}>
                <Form.Item
                    required={false}
                    name="date"
                    label="Chọn ngày thực hiện"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <DatePicker className="w-full" placeholder="" format={DATE_FORMAT} />
                </Form.Item>
                <Form.Item className="flex justify-end mb-0">
                    <Button className="mr-2" onClick={() => setIsWithdrawModalVisible(false)}>Huỷ</Button>
                    <Button type="primary" htmlType="submit">{type === 'withdraw' ? 'Rút lãi' : 'Bù lỗ'}</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

const SoldStockLotTable = ({
    increaseForceReload,
    isFetching,
    lots,
    pagination,
    setPagination,
    fullTable,
    sumTotalBuyValue,
    sumTotalSellValue,
    sumTotalLoanInterest,
    sumTotalProfit,
    sumTotalAdvanceFee,
}) => {
    let columns: ColumnsType<any> = [
        {
            title: 'Tổng giá trị mua',
            children: [
                {
                    title: 'Mã lô CP',
                    dataIndex: 'lot',
                    key: 'lot',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                },
                {
                    title: 'Kho',
                    dataIndex: 'sourceCode',
                    key: 'sourceCode',
                    align: 'center',
                    width: fullTable ? 120 : undefined,
                    render: (value, record) => {
                        return record.sourceCode;
                    }
                },
                {
                    title: 'Ngày thanh toán',
                    dataIndex: 'buyDate',
                    key: 'buyDate',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_TIME_FORMAT),
                },
                {
                    title: 'Mã cổ phiếu',
                    dataIndex: 'stockCode',
                    key: 'stockCode',
                    align: 'center',
                    width: fullTable ? 100 : undefined,
                    render: (value, record) => <span className={`font-bold text-${record.profit < 0 ? 'error' : 'success'}`}>{value.toUpperCase()}</span>,
                },
                {
                    title: 'Khối lượng',
                    dataIndex: 'volume',
                    key: 'volume',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Giá mua',
                    dataIndex: 'buyMatchedPrice',
                    key: 'buyMatchedPrice',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Giá trị mua',
                    dataIndex: 'buyValue',
                    key: 'buyValue',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Phí giao dịch',
                    dataIndex: 'feeBuy',
                    key: 'feeBuy',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Tổng giá trị mua',
                    dataIndex: 'totalBuyValue',
                    key: 'totalBuyValue',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Kết quả',
                    dataIndex: 'profit',
                    key: 'profit',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => <span className={`font-bold text-${value < 0 ? 'error' : 'success'}`}>{moneyFormat(value)}</span>,
                },
                {
                    title: 'Tình trạng',
                    key: 'status',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value, record) => {
                        if (record.profit === 0) {
                            return <></>;
                        }
                        return <StatusAction increaseForceReload={increaseForceReload} type={record.profit > 0 ? "withdraw" : "cover"} record={record}/>
                    }
                },
            ]
        }
    ];

    const extraColumns = [
        {
            title: 'Tổng giá trị bán',
            children: [
                {
                    title: 'Ngày bán',
                    dataIndex: 'date',
                    key: 'date',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_FORMAT),
                },
                {
                    title: 'Giá bán',
                    dataIndex: 'matchedPrice',
                    key: 'matchedPrice',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Giá trị bán',
                    dataIndex: 'sellValue',
                    key: 'sellValue',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Phí bán',
                    dataIndex: 'feeSell',
                    key: 'feeSell',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Tổng giá trị bán',
                    dataIndex: 'totalSellValue',
                    key: 'totalSellValue',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
            ],
        },
        {
            title: 'Khoản vay',
            children: [
                {
                    title: 'Số tiền vay',
                    dataIndex: 'totalBuyValue',
                    key: 'totalBuyValue',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
                {
                    title: 'Ngày phát sinh',
                    dataIndex: 'buyDate',
                    key: 'buyDate',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_TIME_FORMAT),
                },
                {
                    title: 'Ngày kết thúc',
                    dataIndex: 'date',
                    key: 'date',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_TIME_FORMAT),
                },
                {
                    title: 'Số ngày vay',
                    dataIndex: 'totalLoanDays',
                    key: 'totalLoanDays',
                    align: 'center',
                    width: fullTable ? 120 : undefined,
                },
                {
                    title: 'Lãi suất vay',
                    dataIndex: 'loanInterest',
                    key: 'loanInterest',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                    render: (value) => `${value}%`
                },
                {
                    title: 'Tổng tiền lãi',
                    dataIndex: 'totalLoanInterest',
                    key: 'totalLoanInterest',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
            ],
        },
        {
            title: 'Phí ứng tiền',
            children: [
                {
                    title: 'Lãi suất',
                    dataIndex: 'loanInterest',
                    key: 'ratio',
                    align: 'right',
                    width: fullTable ? 120 : undefined,
                    render: (value) => `${value}%`
                },
                {
                    title: 'Số ngày',
                    dataIndex: 'totalAdvanceDays',
                    key: 'totalAdvanceDays',
                    align: 'center',
                    width: fullTable ? 100 : undefined,
                },
                {
                    title: 'Ngày phát sinh',
                    dataIndex: 'date',
                    key: 'date',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_TIME_FORMAT),
                },
                {
                    title: 'Ngày kết thúc',
                    dataIndex: 'endAdvanceFeeDate',
                    key: 'endAdvanceFeeDate',
                    align: 'center',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moment(value).format(DATE_TIME_FORMAT),
                },
                {
                    title: 'Tổng phí ứng tiền',
                    dataIndex: 'advanceFee',
                    key: 'advanceFee',
                    align: 'right',
                    width: fullTable ? 150 : undefined,
                    render: (value) => moneyFormat(value),
                },
            ],
        }
    ];

    if (fullTable) {
        columns = [...columns, ...extraColumns];
    }

    return (
        <Table
            bordered
            rowKey="id"
            dataSource={lots}
            columns={columns}
            loading={isFetching}
            scroll={{ x: 'max-content' }}
            pagination={{ ...pagination, size: 'small', showSizeChanger: false }}
            sticky
            summary={() => lots.length > 0 && (
                <Table.Summary fixed={'top'}>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} className="text-center font-bold">Tổng</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={3} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={5} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={6} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={7} className="text-right font-bold"></Table.Summary.Cell>
                        <Table.Summary.Cell index={8} className="text-right font-bold">{moneyFormat(sumTotalBuyValue)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={9} className="text-right font-bold">{moneyFormat(sumTotalProfit)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={10} className="text-right font-bold"></Table.Summary.Cell>
                        {fullTable && (
                            <>
                                <Table.Summary.Cell index={11} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={12} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={13} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={14} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={15} className="text-right font-bold">{moneyFormat(sumTotalSellValue)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={16} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={17} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={18} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={19} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={20} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={21} className="text-right font-bold">{moneyFormat(sumTotalLoanInterest)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={22} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={23} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={24} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={25} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={26} className="text-right font-bold">{moneyFormat(sumTotalAdvanceFee)}</Table.Summary.Cell>
                            </>
                        )}
                    </Table.Summary.Row>
                </Table.Summary>
            )}
        />
    )
}

export default SoldStockLotTable;