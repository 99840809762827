const routes = {
    LOGIN: '/user/login',
    REGISTER: '/user/register',
    FORGOT_PASSWORD: '/user/forgot-password',
    USER_LOCK_ACCOUNT: '/user/lock-account/:token',
    RESET_PASSWORD: '/user/reset-password/:userType/:token',

    HOME: '/',
    DASHBOARD: '/dashboard',

    SETTINGS: '/settings',
    SETTINGS_ADD_STOCK: '/settings/add-new-stock',
    SETTINGS_UPDATE_STOCK: '/settings/update/:id',
    PROFILE: '/profile',

    USERS: '/users',
    ADD_NEW_USER: '/users/add-new-user',
    EDIT_USER: '/users/edit-user/:id',

    BORROWERS: '/borrowers',
    ADD_NEW_BORROWER: '/borrowers/add-new-borrower',
    EDIT_BORROWER: '/borrowers/edit-borrower',
    BORROWER_DETAIL_ASSET: '/borrower/:id/asset',
    BORROWER_DETAIL_INFO: '/borrower/:id/info',

    LENDERS_ASSET: '/lenders/asset',
    LENDERS_INFO: '/lenders/info',
    ADD_NEW_LENDER: '/lenders/add-new-lender',
    EDIT_LENDER: '/lenders/edit-lender',
    LENDER_DETAIL_ASSET: '/lender/:id/asset',
    LENDER_DETAIL_SOURCE_MANAGER: '/lender/:id/source-manager',
    LENDER_DETAIL_INFO: '/lender/:id/info',
    LENDER_DETAIL_TRANSACTION_HISTORY: '/lender/:id/transaction-history',
    LENDER_ADD_NEW_SOURCE: '/lender/:id/add-new-source',

    SOURCES_ASSET: '/sources/asset',
    SOURCES_STOCKS: '/sources/stocks',
    SOURCE_DETAIL: '/source/:id',

    HISTORY_MONEY_TRANSACTIONS: '/history/money-transactions',
    HISTORY_STOCK_TRANSACTIONS: '/history/stock-transactions',
    HISTORY_OPERATIONAL_EFFICIENCY: '/history/operational-efficiency',
}

export default routes;