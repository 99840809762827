import React, { useEffect, useState } from "react";
import { Layout, Button, Space, Card, Typography, notification } from "antd";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useEffectOnce } from "react-use";

import routes from "@Routes";
import { toRoute, useReducedState } from "@Services/utils";
import api from "@Services/api";
import { useAuthState } from "@Contexts/Auth";

import AccessDenied from "@Components/AccessDenied";
import Loading from "@Components/Loading";
import BackButton from "@Components/BackButton";
import EditableTable from "@Components/EditableTable";
import { PAGE_SIZE_SMALL } from "@Configs";

const SourceManager = () => {
    const navigate = useNavigate();
    const { id } = useParams() as any;
    const [lender, setLender] = useState<any>();
    const [sources, setSources] = useState([]);
    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_SMALL,
        total: 0
    });
    const [isFetching, setIsFetching] = useState<boolean>(false);
    let { authState } = useAuthState() as any;
    let { currentUser } = authState;

    const fetchDetail = async () => {
        const [err, resp]: any = await api.get(`lender/detail/${id}`, {});

        if (err) {
            notification.error({
                message: "Không tìm thấy người cho vay!"
            });
            return navigate(routes.LENDERS_ASSET);
        }

        if (!resp?.data?.lender) {
            notification.error({
                message: "Không tìm thấy người cho vay!"
            });
            return navigate(routes.LENDERS_ASSET);
        }

        setLender(resp.data.lender);
    }

    const fetchSources = async () => {
        const [err, resp]: any = await api.get(`source/list`, {
            profileId: id, page: pagination.current, pageSize: PAGE_SIZE_SMALL
        });

        if (err) {
            notification.error(err?.response?.data?.message);
            return;
        }

        let { sources, total } = resp?.data;

        setSources(sources);
        setPagination({ total });
    }

    const onUpdateSource = async (id, values) => {
        const [err, resp]: any = await api.post(`source/update/${id}`, {
            sourceCode: values.sourceCode,
            securitiesId: values.securitiesId,
            securitiesFullname: values.securitiesFullname,
            securitiesCompany: values.securitiesCompany,
            feeSellStock: values.feeSellStock,
            feeBuyStock: values.feeBuyStock,
            marginRatio: values.marginRatio,
        })

        if (resp) {
            notification.success({
                message: 'Cập nhật kho thành công!',
            });

            fetchSources();

            return true;
        }

        if (err?.response?.data?.errors) {
            notification.error({
                message: err.response.data.errors[0]
            });
        }

        if (err?.response?.data?.message) {
            notification.error({
                message: err.response.data.message
            });
        }
        return false;
    }

    const fetchData = async () => {
        setIsFetching(true);
        await Promise.all([fetchDetail(), fetchSources()]);
        setIsFetching(false);
    };

    useEffectOnce(() => {
        fetchData();
    });

    useEffect(() => {
        fetchSources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current]);

    const columns = [
        {
            title: 'Số kho',
            dataIndex: 'sourceCode',
            key: 'sourceCode',
            editable: true,
            inputType: "input",
            render: (value, record) => <Link className="font-bold" to={toRoute(routes.SOURCE_DETAIL, { id: record.id })}>{value}</Link>
        },
        {
            title: 'Số tài khoản chứng khoán',
            dataIndex: 'securitiesId',
            key: 'securitiesId',
            editable: true,
            inputType: "input",
        },
        {
            title: 'Tên chủ tài khoản',
            dataIndex: 'securitiesFullname',
            key: 'securitiesFullname',
            editable: true,
            inputType: "input"
        },
        {
            title: 'Công ty chứng khoán',
            dataIndex: 'securitiesCompany',
            key: 'securitiesCompany',
            editable: true,
            inputType: "input"
        },
        {
            title: 'Tỉ lệ ký quỹ (%)',
            dataIndex: 'marginRatio',
            key: 'marginRatio',
            editable: true,
            inputType: "input",
            render: (value) => value ? `${value}%` : '-'
        },
        {
            title: 'Phí mua',
            dataIndex: 'feeBuyStock',
            key: 'feeBuyStock',
            editable: true,
            inputType: "input"
        },
        {
            title: 'Phí bán',
            dataIndex: 'feeSellStock',
            key: 'feeSellStock',
            editable: true,
            inputType: "input"
        },
    ];

    if (isFetching) {
        return <Loading />;
    }

    if (!lender) {
        return <Loading />;
    }

    if (!isFetching && currentUser && !currentUser?.isSuperAdmin) {
        return <AccessDenied />
    }

    return (
        <Layout>
            <Layout className="flex-row justify-between items-center bg-gray p-4">
                <Space size="large" className="mr-6">
                    <BackButton />
                    <span>Tên: <strong>{lender.fullname}</strong></span>
                    <span>ID: <strong>{lender.shortId}</strong></span>
                </Space>
                <Space>
                    <Button className='custom-btn-tab' onClick={() => navigate(toRoute(routes.LENDER_DETAIL_ASSET, { id }))}>Tài sản</Button>
                    <Button className='custom-btn-tab active'>Quản lý kho</Button>
                    <Button className='custom-btn-tab' onClick={() => navigate(toRoute(routes.LENDER_DETAIL_TRANSACTION_HISTORY, { id }))}>Lịch sử giao dịch</Button>
                    <Button className='custom-btn-tab' onClick={() => navigate(toRoute(routes.LENDER_DETAIL_INFO, { id }))}>Thông tin cơ bản</Button>
                </Space>
                <div/>
            </Layout>
            <Layout className="px-4 py-2">
                <div className="flex justify-between items-center mt-2 mb-4">
                    <Typography.Title level={4} className="uppercase font-bold mb-0">Quản lý kho</Typography.Title>
                    <Button type="primary" onClick={() => navigate(toRoute(routes.LENDER_ADD_NEW_SOURCE, { id }))}>Thêm kho</Button>
                </div>

                <Card className="card-table-header">
                    <Typography.Title level={5} className="mb-0">Các kho đang sở hữu</Typography.Title>
                </Card>
                <EditableTable
                    rowKey="id"
                    dataSource={sources}
                    columns={columns}
                    onSave={onUpdateSource}
                    onDelete={null}
                    pagination={{
                        ...pagination,
                        size: 'small',
                        onChange: page => {
                            setPagination({ current: page });
                        },
                    }}
                />
            </Layout>
        </Layout>
    )
};

export default SourceManager;