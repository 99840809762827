import React, { useEffect, useState } from "react";
import { Popconfirm, Layout, Button, notification, Table, Typography, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from 'react-use';

import routes from "@Routes";
import api from "@Services/api";
import { useReducedState } from "@Services/utils";
import { PAGE_SIZE_NORMAL } from "@Configs";
import { useAuthState } from "@Contexts/Auth";

const Users = () => {
    const navigate = useNavigate();
    let { authState } = useAuthState() as any;
    let { currentUser } = authState;

    const [ listAdmins, setListAdmins ] = useState<NSApp.Admin[]>([]);
    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_NORMAL,
        total: 0
    });

    const fetchAdmins = async (filter = '') => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('admin/list', {
            filter, page: pagination.current, pageSize: PAGE_SIZE_NORMAL
        });

        if (err) {
            notification.error(err?.response?.data?.message);
            return;
        }

        let { admins, total } = resp?.data;

        setListAdmins(admins);
        setPagination({ total });
    };

    useEffectOnce(() => {
        fetchAdmins();
    });

    useEffect(() => {
        fetchAdmins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current]);

    const onDelete = async (id, username) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`admin/delete/${id}`, {});

        if (err) {
            notification.error({
                message: err?.response?.data?.message
            });
            return;
        }

        notification.success({
            message: `Xoá tài khoản ${username} thành công!`
        })

        fetchAdmins();
    }

    const goToEditPage = (id: string) => {
        navigate(`/users/edit-user/${id}`);
    }

    const columns = [
        {
            title: 'Tên',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (value, record) => (
                <div>
                    {currentUser?.isSuperAdmin ? (
                        <div>
                            <div className="text-blue-500 font-bold cursor-pointer" onClick={() => goToEditPage(record.id)}>{value}</div>
                            <span className="text-gray-500 cursor-pointer mr-2" onClick={() => goToEditPage(record.id)}>Sửa</span>
                            {!record.isSuperAdmin && (
                            <Popconfirm
                                title="Bạn có muốn xoá tài khoản này không ?"
                                onConfirm={() => onDelete(record.id, record.username)}
                                okText="Có"
                                cancelText="Không"
                            >
                                <span className="text-rose-500 cursor-pointer">Xoá</span>
                            </Popconfirm>
                            )}
                        </div>
                    ) : (
                        <span className="font-bold">{value}</span>
                    )}
                </div>
            )
        },
        {
            title: 'ID',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Role',
            dataIndex: 'isSuperAdmin',
            key: 'isSuperAdmin',
            render: (value) => {
                return <div>{value ? "Super Admin" : "Admin"}</div>
            }
        },
    ];

    const onSearch = async (values) => {
        await fetchAdmins(values);
    }

    return (
        <Layout className="p-4">
            <Layout className="flex-row justify-between items-center mb-5">
                <div className="flex">
                    <Typography.Title level={3} className="uppercase font-bold mb-0 mr-4">Danh sách quản trị viên</Typography.Title>
                    {currentUser?.isSuperAdmin && <Button onClick={() => navigate(routes.ADD_NEW_USER)}>Thêm mới</Button>}
                </div>
                <div>
                    <Input.Search
                        placeholder="Nhập Tên, ID hoặc Email"
                        allowClear
                        enterButton="Tìm kiếm"
                        style={{ minWidth: 300 }}
                        onSearch={onSearch}
                    />
                </div>
            </Layout>

            <div className="mb-3">
                <span>Tất cả ({ pagination.total })</span>
            </div>

            <Table
                bordered
                dataSource={listAdmins}
                columns={columns}
                rowKey={(r: any) => r?.id}
                pagination={{
                    ...pagination,
                    size: 'small',
                    onChange: (page, pageSize) => {
                        setPagination({ current: page, pageSize });
                    },
                }}
            />
        </Layout>
    );
}

export default Users;