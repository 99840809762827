import React, { useEffect, useState } from "react";
import { Layout, Card, Typography, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import api from '@Services/api';
import { DATE_TIME_FORMAT, PAGE_SIZE_SMALL } from '@Configs';
import { useReducedState, moneyFormat } from "@Services/utils";

const RefundAndCommissionTable = ({ sourceDetail }) => {

    const [transactions, setTransactions] = useState<NSApp.ContractTransaction[]>([]);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_SMALL,
        total: 0
    });

    const fetchRefundAndCommissionTransactions = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`source/transactions/refund-commission/${sourceDetail.id}`, {
            page: pagination.current, pageSize: pagination.pageSize
        });

        setIsFetching(false);
        if (err) {
            return;
        }

        const _transactions = resp.data.transactions.map(t => {
            return {
                ...t,
                date: moment(t.date)
            }
        })

        setTotalAmount(resp.data.totalAmount);
        setTransactions(_transactions);
        setPagination({ total: resp.data.total })
    };

    useEffect(() => {
        fetchRefundAndCommissionTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current]);

    const columns: ColumnsType<any> = [
        {
            title: 'Ngày',
            dataIndex: 'date',
            key: 'date',
            render: (value) => value.format(DATE_TIME_FORMAT),
        },
        {
            title: 'Reference',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Số hợp đồng',
            dataIndex: 'contractId',
            key: 'contractId',
        },
        {
            title: 'Loại giao dịch',
            dataIndex: 'type',
            key: 'type',
            render: (value) => {
                if (value === 'refund') return 'Hoàn phí';
                if (value === 'commission') return 'Hoa hồng'
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (value) => moneyFormat(value),
        },
    ];

    return (
        <Layout>
            <Card className="card-table-header">
                <div className="flex justify-between items-center">
                    <Typography.Title level={5} className="mb-0">Lãi margin CTCK và hoàn phí</Typography.Title>
                </div>
            </Card>
            <Table
                bordered
                loading={isFetching}
                rowKey={(r: any) => r.id}
                dataSource={transactions}
                columns={columns}
                pagination={{
                    ...pagination,
                    size: 'small',
                    onChange: page => {
                        setPagination({ current: page });
                    },
                }}
                summary={() => (transactions.length > 0) &&  (
                    <Table.Summary fixed={'top'}>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                            <Table.Summary.Cell index={3}></Table.Summary.Cell>
                            <Table.Summary.Cell index={4}></Table.Summary.Cell>
                            <Table.Summary.Cell index={5} className="text-right font-bold">
                                Tổng: {moneyFormat(totalAmount)}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        </Layout>
    )
};

export default RefundAndCommissionTable;