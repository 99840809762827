import React, { useState } from "react";
import { Layout, Typography, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "@Contexts/Auth";

import api from "@Services/api";
import routes from "@Routes";
import AccessDenied from "@Components/AccessDenied";
import StockForm from './Form';

const AddNewStock = () => {
    const navigate = useNavigate();
    const [errors, setErrors]: any = useState([]);
    const [loading, setLoading] = useState(false);

    let { authState } = useAuthState() as any;
    let { currentUser } = authState;

    const onAddNewStock = async (values: any) => {
        setErrors([]);
        setLoading(true);

        const [err, resp]: any = await api.post(`stock/add`, {
            ...values,
            code: values.code.toLowerCase()
        });

        console.log(err, resp);
        setLoading(false);
        if (!err) {
            notification.success({
                message: 'Tạo mã cổ phiếu mới thành công!',
            });
            return navigate(routes.SETTINGS);
        }

        if (err?.response?.data?.errors) {
            setErrors(err.response.data.errors);
        }

        if (err?.response?.data?.message) {
            setErrors([{ msg: err.response.data.message }])
        }
    };

    if (!currentUser?.isSuperAdmin) {
        return <AccessDenied />
    }

    return (
        <Layout className="p-4">
            <Typography.Title level={3} className="uppercase font-bold mb-1">
                Thêm mới cổ phiếu
            </Typography.Title>

            <StockForm
                initialValues={{ exchange: 'hose', status: 'active' }}
                mode='add-new'
                onSubmit={onAddNewStock}
                loading={loading}
                errors={errors}
            />
        </Layout>
    )
}

export default AddNewStock;