import React, { useState } from "react";
import { notification, Avatar, Card, Form, Input, Button } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from "react-router-dom";

import routes from "@Routes";
import api from "@Services/api";

import logo from "@Assets/images/logos/svg/logo_01.svg";

const ResetPassword = () => {
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const { userType, token } = useParams();
    const isAdminSite = userType === 'admin';

    const onFinish = async (values) => {
        let { password } = values;

        const reqUrl = isAdminSite ? 'admin/reset-password' : 'user/reset-password';

        // eslint-disable-next-line
        let [err, resp]: any = await api.post(reqUrl, {
            password,
            recoveryPasswordToken: token,
        })

        if (err) {
            notification.error({
                message: "Có lỗi xảy ra!",
            });
            return;
        }
        if (resp.data) {
            if (isAdminSite) {
                notification.success({
                    message: "Khôi phục mật khẩu thành công!",
                });

                navigate(routes.LOGIN);
            } else {
                setIsSuccessful(true);
            }
        }
    };

    return (
        <div className="h-screen flex flex-col justify-center items-center p-4">
            <Avatar
                shape="square"
                size={window.innerWidth < 576 ? 160 : 200}
                src={logo}
            />
            {isSuccessful ? (
                <Card className="w-full max-w-md">
                    Đổi mật khẩu thành công! Vui lòng đăng nhập tại App ở để tiếp tục trải nghiệm!
                </Card>
            ) : (
                <div>
                    <Card className="card-description w-full max-w-md">
                        <p className="mb-1">Vui lòng nhập mật khẩu mới vào ô bên dưới</p>
                        <small className="text-gray-500 italic">*Mật khẩu chứa ít nhất 8 ký tự, 1 chữ cái hoa, 1 chữ cái thường, 1 số và 1 ký tự đặc biệt</small>
                    </Card>

                    <Card className="w-full max-w-md">
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="Mật khẩu mới"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Không được để trống',
                                    },
                                    {
                                        pattern: new RegExp(/^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/),
                                        message: 'Mật khẩu chưa đủ bảo mật'
                                    }
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="Xác nhận lại mật khẩu"
                                name="confirmPassword"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Không được để trống',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Xác nhận mật khẩu không khớp');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <div className="flex justify-end">
                                <Button htmlType="submit" type="primary">Lưu mật khẩu</Button>
                            </div>
                        </Form>
                    </Card>
                    <Link className="flex items-center text-slate-600 hover:text-black mt-2" to={routes.LOGIN}>
                        <ArrowLeftOutlined className="mr-2" />
                        Trở lại đăng nhập
                    </Link>
                </div>
            )}
        </div>
    )
}

export default ResetPassword;