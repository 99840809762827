import React, { useState, useEffect, useCallback } from "react";
import { Layout, Space, Button, Typography, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { toRoute, moneyFormat } from "@Services/utils";
import { PAGE_SIZE_NORMAL } from "@Configs";
import routes from "@Routes";
import api from "@Services/api";

const Lenders = () => {
    const [listLenders, setListLenders] = useState<NSApp.Lender[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>();

    const [sumTotalContractValue, setSumTotalContractValue] = useState<number>(0);
    const [sumTotalMinimumInterestValue, setSumTotalMinimumInterestValue] = useState<number>(0);
    const [sumTotalInterestValue, setSumTotalInterestValue] = useState<number>(0);
    const [sumTotalInterestWithDrawnValue, setSumTotalInterestWithDrawnValue] = useState<number>(0);
    const [sumTotalInterestAndFee, setSumTotalInterestAndFee] = useState<number>(0);
    const [sumTotalCommissionAndRefundFee, setSumTotalCommissionAndRefundFee] = useState<number>(0);

    const fetchLendersSummary = useCallback(async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get("lender/list-summary", {
            filter: searchKey
        });
        setIsFetching(false);

        if (err) {
            return;
        }

        setListLenders(resp.data.lenders);

        setSumTotalContractValue(resp.data.sumTotalContractValue);
        setSumTotalMinimumInterestValue(resp.data.sumTotalMinimumInterestValue);
        setSumTotalInterestValue(resp.data.sumTotalInterestValue);
        setSumTotalInterestWithDrawnValue(resp.data.sumTotalInterestWithDrawnValue);
        setSumTotalInterestAndFee(resp.data.sumTotalInterestAndFee)
        setSumTotalCommissionAndRefundFee(resp.data.sumTotalCommissionAndRefundFee);
    }, [searchKey]);

    const onSearch = (value: string) => {
        setSearchKey(value);
    }

    useEffect(() => {
        fetchLendersSummary();
    }, [fetchLendersSummary])

    const columns: ColumnsType<any> = [
        {
            title: 'Tên',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (value, record) => <Link className="font-bold" to={toRoute(routes.LENDER_DETAIL_ASSET, { id: record.id })}>{value}</Link>
        },
        {
            title: 'ID',
            dataIndex: 'shortId',
            key: 'shortId',
            align: 'center',
            render: (value) => <div className="text-one-line">{value}</div>
        },
        {
            title: 'Tổng tiền nạp/rút',
            dataIndex: 'contracts',
            key: 'contracts.totalContractValue',
            align: 'right',
            render: (value, record) => (
                <span
                    // onClick={() => onOpenContractTransactionHistoryModal(record.id)}
                    className="text-primary underline cursor-pointer">
                    {moneyFormat(value.totalContractValue)}
                </span>
            ),
        },
        {
            title: 'Lãi tối thiểu',
            dataIndex: 'contracts',
            key: 'contracts.minimumInterestValue',
            align: 'right',
            render: (value) => moneyFormat(value.totalMinimumInterestValue)
        },
        {
            title: 'Lãi đến hạn được rút',
            dataIndex: 'contracts',
            key: 'contracts.totalInterestValue',
            align: 'right',
            render: (value) => moneyFormat(value.totalInterestValue)
        },
        {
            title: 'Lãi đến hạn đã rút',
            dataIndex: 'contracts',
            key: 'contracts.totalInterestWithDrawnValue',
            align: 'right',
            render: (value) => moneyFormat(value.totalInterestWithDrawnValue)
        },
        {
            title: 'Hoa hồng phí giao dịch',
            dataIndex: 'contracts',
            key: 'contracts.totalCommissionAndRefundFee',
            align: 'right',
            render: (value) => moneyFormat(value.totalCommissionAndRefundFee)
        },
        {
            title: 'Tổng lãi và phí',
            dataIndex: 'contracts',
            key: 'contracts.totalInterestAndFee',
            align: 'right',
            render: (value) => moneyFormat(value.totalMinimumInterestValue + value.totalCommissionAndRefundFee)
        },
    ];

    return (
        <Layout>
            <Layout className="bg-gray p-4">
                <Space>
                    <Button className='custom-btn-tab active'>Tài sản</Button>
                    <Link to={routes.LENDERS_INFO}><Button className='custom-btn-tab'>Thông tin</Button></Link>
                </Space>
            </Layout>
            <Layout className="px-4 pt-4">
                <Layout className="flex-row justify-between items-center mb-6">
                    <Typography.Title level={4} className="uppercase font-bold mb-0 mr-4">Tài sản người cho vay</Typography.Title>
                    <div>
                        <Input.Search
                            placeholder="Nhập Tên, ID hoặc Email"
                            allowClear
                            enterButton="Tìm kiếm"
                            style={{ minWidth: 300 }}
                            onSearch={onSearch}
                        />
                    </div>
                </Layout>

                <div className="mb-3">
                    <span>Tất cả ({ listLenders.length })</span>
                </div>

                <Table
                    bordered
                    rowKey={(r: any) => r.id}
                    dataSource={listLenders}
                    columns={columns}
                    loading={isFetching}
                    sticky
                    pagination={{ size: 'small', defaultPageSize: PAGE_SIZE_NORMAL, showSizeChanger: false }}
                    summary={() => listLenders.length > 0 && (
                        <Table.Summary fixed={'top'}>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} className="text-right font-bold">Tổng</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumTotalContractValue)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumTotalMinimumInterestValue)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumTotalInterestValue)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumTotalInterestWithDrawnValue)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumTotalCommissionAndRefundFee)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumTotalInterestAndFee)}</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />

            </Layout>
        </Layout>
    )
};

export default Lenders;