import React, { useState } from "react";
import { Layout, Card, Typography, Tooltip } from "antd";
import { ColumnWidthOutlined } from "@ant-design/icons";
import { useEffectOnce } from "react-use";
import localStorage from "@Services/local-storage";
import StockOwnedLotTable from "../components/StockOwnedLotTable";

type StocksOwnedTabProps = {
    account: NSApp.Account;
    setTradeType: (type: string) => void;
    setIsTradeModalVisible: (isTradeModalVisible: boolean) => void;
    setTransactionData: (transactionData: any) => void;
    forceReload: number;
}

const StocksOwnedTab = (props: StocksOwnedTabProps) => {
    const { forceReload, account, setTradeType, setIsTradeModalVisible, setTransactionData } = props;
    const [fullTable, setFullTable] = useState<boolean>(false);

    const initFullTable = async () => {
        let fullTableLocalStorage = await localStorage.get('borrowerStocksOwnerTable');

        if (fullTableLocalStorage == null || fullTableLocalStorage === undefined) {
            setFullTable(true);
        } else {
            setFullTable(fullTableLocalStorage);
        }
    };

    useEffectOnce(() => {
        initFullTable();
    });

    const openTradeModal = async (values: any) => {
        setTransactionData(values);
        setTradeType('sell');
        setIsTradeModalVisible(true);
    };

    const changeTableType = () => {
        setFullTable(!fullTable);
        localStorage.set('borrowerStocksOwnerTable', String(!fullTable));
    };

    return <Layout>
        <Card className="card-table-header">
            <div className="flex justify-between items-center">
                <Typography.Title level={5} className="mb-0">Giá trị mua cổ phiếu theo lô</Typography.Title>
                <Tooltip placement="left" title={fullTable ? "Xem bảng thu gọn" : "Xem bảng đầy đủ"}>
                    <ColumnWidthOutlined onClick={changeTableType} className="cursor-pointer" style={{ fontSize: 20 }} />
                </Tooltip>
            </div>
        </Card>

        <StockOwnedLotTable
            forceReload={forceReload}
            account={account}
            onSellClick={openTradeModal}
            fullTable={fullTable}
        />
    </Layout>
};

export default StocksOwnedTab;