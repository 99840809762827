import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { Layout, Card, Typography, Tooltip } from "antd";
import { ColumnWidthOutlined } from "@ant-design/icons";
import localStorage from "@Services/local-storage";
import { useReducedState } from "@Services/utils";
import api from "@Services/api";
import { PAGE_SIZE_SMALL } from "@Configs";
import SoldStockLotTable from "../components/SoldStockLotTable";

const SoldStocksTab = ({ forceReload, increaseForceReload, account }) => {
    const [lots, setLots] = useState<any[]>([]);
    const [sumTotalBuyValue, setSumTotalBuyValue] = useState<number>(0);
    const [sumTotalSellValue, setSumTotalSellValue] = useState<number>(0);
    const [sumTotalLoanInterest, setSumTotalLoanInterest] = useState<number>(0);
    const [sumTotalProfit, setSumTotalProfit] = useState<number>(0);
    const [sumTotalAdvanceFee, setSumTotalAdvanceFee] = useState<number>(0);
    const [fullTable, setFullTable] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState(false);

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_SMALL,
        total: 0
    });

    const fetchSoldPortfolioLots = async () => {
        setIsFetching(true);

        const [err, resp]: any = await api.get(`portfolio/sold-lots/${account.id}`, {
            page: pagination.current,
            pageSize: PAGE_SIZE_SMALL
        });

        if (err) {
            return;
        }

        const { lots, total, totalBuyValue, totalLoanInterest, totalProfit, totalSellValue, totalAdvanceFee } = resp.data;

        setLots(lots);
        setSumTotalBuyValue(totalBuyValue);
        setSumTotalSellValue(totalSellValue);
        setSumTotalLoanInterest(totalLoanInterest);
        setSumTotalProfit(totalProfit);
        setSumTotalAdvanceFee(totalAdvanceFee);
        setPagination({ total });
        setIsFetching(false);
    };

    const changeTableType = () => {
        setFullTable(!fullTable);
        localStorage.set('borrowerSoldStocksTable', String(!fullTable));
    };

    const initFullTable = async () => {
        let fullTableLocalStorage = await localStorage.get('borrowerSoldStocksTable');

        if (fullTableLocalStorage == null || fullTableLocalStorage === undefined) {
            setFullTable(true);
        } else {
            setFullTable(fullTableLocalStorage);
        }
    };

    useEffectOnce(() => {
        initFullTable();
    });

    useEffect(() => {
        fetchSoldPortfolioLots();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current, forceReload]);

    return <Layout>
        <Card className="card-table-header">
            <div className="flex justify-between items-center">
                <Typography.Title level={5} className="mb-0">Cổ phiếu đã bán</Typography.Title>
                <Tooltip placement="left" title={fullTable ? "Xem bảng thu gọn" : "Xem bảng đầy đủ"}>
                    <ColumnWidthOutlined onClick={changeTableType} className="cursor-pointer" style={{ fontSize: 20 }} />
                </Tooltip>
            </div>
        </Card>

        <SoldStockLotTable
            pagination={{
                ...pagination,
                onChange: page => {
                    setPagination({ current: page });
                },
            }}
            setPagination={setPagination}
            isFetching={isFetching}
            increaseForceReload={increaseForceReload}
            lots={lots}
            fullTable={fullTable}
            sumTotalBuyValue={sumTotalBuyValue}
            sumTotalSellValue={sumTotalSellValue}
            sumTotalLoanInterest={sumTotalLoanInterest}
            sumTotalProfit={sumTotalProfit}
            sumTotalAdvanceFee={sumTotalAdvanceFee}
        />
    </Layout>
};

export default SoldStocksTab;