import localStorage from "./local-storage";
import * as config from "../config";

import { Manager } from 'socket.io-client';

const SocketManager = new Manager(config.REALTIME_BASE_URL, {
    reconnection: true,
    transports: ['websocket'],
    query: {
        accessToken: localStorage.get("accessToken"),
    },
});

export const stockPriceSocket = SocketManager.socket('/stockPrice');
export const orderBookSocket = SocketManager.socket('/orderBook');

export const subscribeStockPrice = (cb: any) => {
    stockPriceSocket.on('stockPrice', (stockList: NSApp.StockRealtime[]) => cb(stockList));
}

export const subscribeOrderBook = (cb: any) => {
    orderBookSocket.on('orderBook', (response: any) => cb(response));
}

export default SocketManager;
