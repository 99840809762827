import React, { useState } from "react";
import { Layout, Typography, Row, Col, Form, Input, Button, DatePicker, Alert, notification, InputNumber } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import api from "@Services/api";
import routes from "@Routes";
import { DATE_FORMAT } from "@Configs";
import { formatterPercent, parserPercent } from "@Services/utils";

const labelCol = 6;
const wrapperCol = 14;

const AddNewBorrower = () => {
    const navigate = useNavigate();
    const [errors, setErrors]: any = useState([]);
    const [loading, setLoading] = useState(false);

    const onAddNewBorrower = async values => {
        setErrors([]);
        setLoading(true);

        const {
            fullname,
            birthday,
            address,
            email,
            phone,
            bank_id,
            bank_name,
            bank_branch,
            interest_rate,
            margin_ratio,
            maintenance_margin_ratio,
            identity_card_number,
            date_of_issued,
            place_of_issued,
        } = values;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`borrower/create`, {
            fullname,
            birthday: moment(birthday).format(DATE_FORMAT),
            address,
            email,
            phone,
            bank_id,
            bank_name,
            bank_branch,
            interest_rate,
            margin_ratio,
            maintenance_margin_ratio,
            identity_card_number,
            date_of_issued: moment(date_of_issued).format(DATE_FORMAT),
            place_of_issued,
        });

        setLoading(false);
        if (!err) {
            notification.success({
                message: 'Tạo người vay mới thành công!',
            });
            return navigate(routes.BORROWERS);
        }

        if (err?.response?.data?.errors) {
            setErrors(err.response.data.errors);
        }

        if (err?.response?.data?.message) {
            setErrors([{ msg: err.response.data.message }])
        }
    };

    return (
        <Layout className="p-4">
            <Typography.Title level={3} className="uppercase font-bold mb-1">
                Thêm mới người vay
            </Typography.Title>

            <Layout className="mt-10">
                {(!!errors || !!errors.length) && (
                    <Row className="mb-4">
                        <Col xs={24} lg={11}>
                            {errors.map((error: any, index: number) => {
                                return <Alert
                                    key={index}
                                    className="mb-1"
                                    type={"error"}
                                    message={error.msg}
                                    showIcon
                                />
                            })}
                        </Col>
                    </Row>
                )}
                <Form
                    layout="horizontal"
                    labelAlign="left"
                    colon={false}
                    labelCol={{ span: labelCol }}
                    wrapperCol={{ span: wrapperCol }}
                    onFinish={onAddNewBorrower}
                >
                    <Row>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="fullname"
                                label="Họ và tên"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Không được để trống',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="birthday" label="Ngày sinh">
                                <DatePicker className="w-full" placeholder="" format={DATE_FORMAT} />
                            </Form.Item>
                            <Form.Item name="address" label="Địa chỉ">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Không được để trống',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="phone" label="Số điện thoại">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="identity_card_number"
                                label="Số  CMND/CCCD"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="date_of_issued" label="Ngày cấp">
                                <DatePicker
                                    className="w-full"
                                    format={DATE_FORMAT}
                                />
                            </Form.Item>
                            <Form.Item name="place_of_issued" label="Nơi cấp">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item name="bank_id" label="Số tài khoản NH">
                                <Input />
                            </Form.Item>
                            <Form.Item name="bank_name" label="Tên tài khoản NH">
                                <Input />
                            </Form.Item>
                            <Form.Item name="bank_branch" label="Chi nhánh NH">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="interest_rate"
                                label="Lãi suất vay/ngày (%)"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Không được để trống',
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="w-full"
                                    controls={false}
                                    formatter={value => formatterPercent(value)}
                                    parser={value => parserPercent(value)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="margin_ratio"
                                label="Tỉ lệ ký quỹ (%)"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Không được để trống',
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="w-full"
                                    controls={false}
                                    formatter={value => formatterPercent(value)}
                                    parser={value => parserPercent(value)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="maintenance_margin_ratio"
                                label="Tỉ lệ force sell (%)"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Không được để trống',
                                    },
                                ]}
                            >
                                <InputNumber
                                    className="w-full"
                                    controls={false}
                                    formatter={value => formatterPercent(value)}
                                    parser={value => parserPercent(value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
						<Col xs={24} lg={12} />
						<Col xs={24} lg={12}>
                            <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                                <div className="flex justify-end">
                                    <Button className="mr-2" onClick={() => navigate(routes.BORROWERS)}>Huỷ</Button>
                                    <Button type="primary" htmlType="submit" loading={loading}>Thêm mới</Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Layout>
        </Layout>
    )
}

export default AddNewBorrower;