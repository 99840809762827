import React, { useState } from "react";
import { notification, Avatar, Image, Card, Form, Input, Button } from "antd";
import { useLocation } from "react-router-dom";
import api from "@Services/api";
import logo from "@Assets/images/logos/svg/logo_01.svg";
import AppStoreImage from "@Assets/images/logos/png/app-store.png";
import GooglePlayImage from "@Assets/images/logos/png/google-play.png";

const Register = () => {
    const [isSuccessful, setIsSuccessful] = useState(false);
    let location = useLocation();
    const [form] = Form.useForm();

    const query = new URLSearchParams(location.search);
    const code = query.get('code') || "";

    const onFinish = async (values) => {
        let { email, fullname, phone, referenceCode } = values;

        // eslint-disable-next-line
        let [err, resp]: any = await api.post('user/sign-up', {
            email,
            fullname,
            phone,
            referenceCode,
        })

        if (err) {
            notification.error({
                message: "Có lỗi xảy ra, vui lòng thử lại!",
            });
            return;
        }

        if (resp) {
            setIsSuccessful(true);
        }

        // notification.success({
        //     message: "Mở tài khoản thành công!",
        // });

        // navigate(routes.LOGIN);
    };

    return (
        <div className="h-screen flex flex-col justify-center items-center p-4">
            <Avatar
                shape="square"
                size={window.innerWidth < 576 ? 160 : 200}
                src={logo}
            />
            <Card className="w-full max-w-md">
                {isSuccessful ? (
                    <div>
                        <div className="text-2xl text-success text-center font-bold">Mở tài khoản thành công!</div>
                        <div className="text-slate-500 text-justify mt-6">
                            Chúc mừng quý khách đã mở thành công tài khoản trên hệ thống <span className="text-black font-bold">F</span><span className="text-warning font-bold">123</span>.
                            Để sử dụng những dịch vụ tốt nhất của chúng tôi, quý khách vui lòng cài đặt ứng dụng qua các nền tảng sau:
                        </div>
                        <div className="flex flex-row justify-between mt-8">
                            <div className="mr-2">
                                <a href="https://link.f123.vn/tobR" target="_blank" rel="noreferrer">
                                    <Image
                                        preview={false}
                                        src={AppStoreImage}
                                    />
                                </a>
                            </div>
                            <div className="ml-2">
                                <a href="https://link.f123.vn/4Yif" target="_blank" rel="noreferrer">
                                    <Image
                                        preview={false}
                                        src={GooglePlayImage}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={{ referenceCode: code }}
                    >
                        <Form.Item
                            className="mb-2"
                            label="Họ và tên"
                            name="fullname"
                            rules={[
                                {
                                    required: true,
                                    message: 'Họ và tên không được để trống',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className="mb-2"
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Email không đúng định dạng!',
                                },
                                {
                                    required: true,
                                    message: 'Email không được để trống!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className="mb-2"
                            label="Số điện thoại"
                            name="phone"
                            // rules={[
                            //     { len: 10, message: 'Số điện thoại phải có 10 số'},
                            //     () => ({
                            //         validator(rule, value) {
                            //             if (!isNaN(value) && value.startsWith('0')) {
                            //                 return Promise.resolve();
                            //             } else {
                            //                 return Promise.reject('Số điện thoại phải bắt đầu bằng số 0');
                            //             }
                            //         },
                            //     }),
                            // ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            className="mb-2"
                            label="Mã giới thiệu"
                            name="referenceCode"
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item className="mt-5">
                            <Button type="primary" htmlType="submit" block>Mở tài khoản</Button>
                        </Form.Item>
                    </Form>
                )}
            </Card>
        </div>
    );
};

export default Register;