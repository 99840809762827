import React, { useState } from "react";
import { Layout, Button, Space, Row, Col, Typography, Alert, notification, Form, Input, InputNumber } from "antd";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { useAuthState } from "@Contexts/Auth";
import routes from "@Routes";
import { toRoute, formatterPercent, parserPercent } from "@Services/utils";
import api from "@Services/api";
import Loading from "@Components/Loading";
import AccessDenied from "@Components/AccessDenied";

const labelCol = 3;
const wrapperCol = 8;

const LenderAddNewSource = () => {
    const navigate = useNavigate();
    const { id } = useParams() as any;
    const [lender, setLender] = useState<NSApp.Lender>();
    const [errors, setErrors]: any = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    let { authState } = useAuthState() as any;
    let { currentUser } = authState;

    const fetchDetail = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`lender/detail/${id}`, {});

        if (err) {
            notification.error({
                message: "Không tìm thấy người cho vay!"
            });
            return navigate(routes.LENDERS_ASSET);
        }

        if (!resp?.data?.lender) {
            notification.error({
                message: "Không tìm thấy người cho vay!"
            });
            return navigate(routes.LENDERS_ASSET);
        }

        setLender(resp.data.lender);
        setIsFetching(false);
    }

    useEffectOnce(() => {
        fetchDetail();
    });

    const onAddNewSource = async (values: any) => {
        setLoading(true);
        const [err, resp]: any = await api.post('source/create', {
            profileId: id,
            sourceCode: values.sourceCode,
            securitiesId: values.securitiesId,
            securitiesFullname: values.securitiesFullname,
            securitiesCompany: values.securitiesCompany,
            feeSellStock: values.feeSellStock,
            feeBuyStock: values.feeBuyStock,
            marginRatio: values.marginRatio
        })
        setLoading(false);

        if (resp) {
            notification.success({
                message: 'Tạo kho mới thành công!',
            });
            return navigate(toRoute(routes.LENDER_DETAIL_SOURCE_MANAGER, { id }));
        }

        if (err?.response?.data?.errors) {
            setErrors(err.response.data.errors);
        }

        if (err?.response?.data?.message) {
            setErrors([{ msg: err.response.data.message }])
        }
    }

    if (isFetching) {
        return <Loading />;
    }

    if (!lender) {
        return <Layout />;
    }

    if (!isFetching && currentUser && !currentUser?.isSuperAdmin) {
        return <AccessDenied />
    }

    return (
        <Layout>
            <Layout className="flex-row justify-between items-center bg-gray p-4">
                <Space size="large" className="mr-6">
                    <span>Tên: <strong>{lender.fullname}</strong></span>
                    <span>ID: <strong>{lender.shortId}</strong></span>
                </Space>
                <Space>
                    <Link to={toRoute(routes.LENDER_DETAIL_ASSET, { id })}><Button className='custom-btn-tab'>Tài sản</Button></Link>
                    <Link to={toRoute(routes.LENDER_DETAIL_SOURCE_MANAGER, { id })}><Button className='custom-btn-tab'>Quản lý kho</Button></Link>
                    <Link to={toRoute(routes.LENDER_DETAIL_TRANSACTION_HISTORY, { id })}><Button className='custom-btn-tab'>Lịch sử giao dịch</Button></Link>
                    <Link to={toRoute(routes.LENDER_DETAIL_INFO, { id })}><Button className='custom-btn-tab'>Thông tin cơ bản</Button></Link>
                </Space>
                <div/>
            </Layout>
            <Layout className="px-4 py-2">
                <div className="mt-2 mb-4">
                    <Typography.Title level={4} className="uppercase font-bold mb-0">Thêm mới kho</Typography.Title>
                </div>
                {(!!errors || !!errors.length) && (
                    <Row className="mb-4">
                        <Col xs={24} lg={11}>
                            {errors.map((error: any, index: number) => {
                                return <Alert
                                    key={index}
                                    className="mb-1"
                                    type={"error"}
                                    message={error.msg}
                                    showIcon
                                />
                            })}
                        </Col>
                    </Row>
                )}
                <Form
                    layout="horizontal"
                    labelAlign="left"
                    colon={false}
                    labelCol={{ span: labelCol }}
                    wrapperCol={{ span: wrapperCol }}
                    onFinish={onAddNewSource}
                >
                    <Form.Item
                        name="sourceCode"
                        label="Số kho"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="securitiesId"
                        label="Số TK chứng khoán"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="securitiesFullname"
                        label="Tên chủ tài khoản"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="securitiesCompany"
                        label="Công ty chứng khoán"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="feeSellStock"
                        label="Phí bán cổ phiếu (%)"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <InputNumber
                            className="w-full"
                            controls={false}
                            formatter={value => formatterPercent(value)}
                            parser={value => parserPercent(value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="feeBuyStock"
                        label="Phí mua cổ phiếu (%)"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <InputNumber
                            className="w-full"
                            controls={false}
                            formatter={value => formatterPercent(value)}
                            parser={value => parserPercent(value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="marginRatio"
                        label="Tỉ lệ ký quỹ (%)"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <InputNumber
                            className="w-full"
                            controls={false}
                            formatter={value => formatterPercent(value)}
                            parser={value => parserPercent(value)}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                        <div className="text-right">
                            <Button type="primary" htmlType="submit" loading={loading}>Thêm mới</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Layout>
        </Layout>
    )
};

export default LenderAddNewSource;