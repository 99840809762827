import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";

import { moneyFormat } from "@Services/utils";
import { renderValueWithColor } from "@Services/renderSpecial";

const SourceInfoTable = ({ sourceDetail }) => {
    const data = [{ ...sourceDetail }];

    const columns: ColumnsType<any> = [
        {
            title: 'Số kho',
            dataIndex: 'sourceCode',
            key: 'sourceCode',
        },
        {
            title: 'Số TKCK',
            dataIndex: 'securitiesId',
            key: 'securitiesId',
        },
        {
            title: 'Tên chủ TK',
            dataIndex: 'securitiesFullname',
            key: 'securitiesFullname',
        },
        {
            title: 'Tổng tiền nạp/rút',
            dataIndex: 'totalValue',
            key: 'totalValue',
            align: 'right',
            render: (value) => value ? moneyFormat(value) : '-',
        },
        {
            title: 'Lãi lỗ đã chốt',
            dataIndex: 'profit',
            key: 'profit',
            align: 'right',
            render: (value) => renderValueWithColor(value),
        },
        {
            title: 'Lãi/lỗ hiện tại',
            dataIndex: 'totalExpectedProfit',
            key: 'totalExpectedProfit',
            align: 'right',
            render: (value) => renderValueWithColor(value),
        },
        {
            title: 'NAV2',
            dataIndex: 'nav2',
            key: 'nav2',
            align: 'right',
            render: (value) => value ? moneyFormat(value) : '-',
        },
        {
            title: 'Tỉ lệ hiện tại',
            dataIndex: 'currentMarginRatio',
            key: 'currentMarginRatio',
            align: 'right',
            render: (value) => value ? `${value}%` : '-',
        },
        {
            title: 'Sức mua tối đa',
            dataIndex: 'maximumPurchasingPower',
            key: 'maximumPurchasingPower',
            align: 'right',
            render: (value) => value ? moneyFormat(value) : '-',
        },
        {
            title: 'Tổng giá trị mua',
            dataIndex: 'totalBuyValue',
            key: 'totalBuyValue',
            align: 'right',
            render: (value) => value ? moneyFormat(value) : '-',
        },
        {
            title: 'Sức mua còn lại',
            dataIndex: 'purchasingPower',
            key: 'purchasingPower',
            align: 'right',
            render: (value) => value ? moneyFormat(value) : '-',
        },
    ];

    return (
        <Table
            bordered
            rowKey={r => r.sourceCode}
            columns={columns}
            dataSource={data}
            pagination={false}
        />
    )
}

export default SourceInfoTable;