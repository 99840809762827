import React from "react";
import { Layout } from "antd";

import { useAuthState } from "@Contexts/Auth";
import OrderBook from "./OrderBook";
import MarginCallAndForceSell from "./MarginCallAndForceSell";
import Transaction from "./Transaction";

const Dashboard = () => {
    let { authState } = useAuthState() as any;
    let { currentUser } = authState;

    return (
        <Layout className="p-4">
            <div className="mb-4"><OrderBook/></div>
            <div className="mb-4"><MarginCallAndForceSell/></div>
            {!!currentUser?.isSuperAdmin && <div><Transaction/></div>}
        </Layout>
    );
}

export default Dashboard;