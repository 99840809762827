import React from "react";
import { Avatar, Card, Form, Input, Checkbox, Button, notification } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import localStorage from "@Services/local-storage";
import api from "@Services/api";
import { useAuthState } from "@Contexts/Auth";
import routes from "@Routes";
import logo from "@Assets/images/logos/svg/logo_01.svg";

const Login = () => {
    let {setAuthState} = useAuthState() as any;
    let navigate = useNavigate();
    let location: any = useLocation();

    const onFinish = async (values) => {
        let [err, resp]: any = await api.post('admin/login', {
            username: values?.username,
            password: values?.password
        });

        if (err) {
            const status = err?.response?.status;
            if (status && [400, 404].includes(status)) {
                return notification.error({
                    message: 'Tài khoản hoặc mật khẩu không đúng. Vui lòng kiểm tra lại!',
                });
            }

            return notification.error({
                message: 'Đăng nhập thất bại!',
            });
        }

        let {accessToken, user} = resp.data;

        if (!accessToken || !user) {
            return notification.error({
                message: 'Đăng nhập thất bại!',
            });
        }

        notification.success({
            message: 'Đăng nhập thành công!',
            description: <span>Chào mừng trở lại, <strong>{user.fullname}</strong></span>,
        });

        // api.setToken(token);
        api.setAccessToken(accessToken);
        setAuthState({ currentUser: user });
        localStorage.set('accessToken', accessToken);

        if (location?.state?.referrer) {
            navigate(location.state.referrer, {replace: true})
        } else {
            navigate(routes.DASHBOARD, {replace: true});
        }
    }

    return (
        <div className="h-screen flex flex-col justify-center items-center p-4">
            <Avatar
                shape="square"
                size={window.innerWidth < 576 ? 160 : 200}
                src={logo}
            />
            <div className="w-full max-w-md">
                <Card className="w-full mb-2">
                    <Form
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            required={false}
                            label="Email hoặc ID"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Không được để trống',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            required={false}
                            label="Mật khẩu"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Không được để trống',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <div className="flex justify-between items-start">
                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>Lưu mật khẩu</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Đăng nhập</Button>
                            </Form.Item>
                        </div>
                    </Form>
                </Card>
                <Link className="text-slate-600 hover:text-black" to={routes.FORGOT_PASSWORD}>Quên mật khẩu ?</Link>
            </div>
        </div>
    )
}

export default Login;