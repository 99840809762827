import React from "react";
import { Layout, Modal, Form, Input, DatePicker, Select, InputNumber, Button } from "antd";
import { DATE_FORMAT } from "@Configs";
import { formatterPercent, parserPercent } from "@Services/utils";

const labelCol = 6;
const wrapperCol = 18;

const EditContractModal = ({ isVisible, setIsVisible, contract, setContract, onSaveContract }) => {
    if (!contract) {
        return <Layout></Layout>
    };

    const onCancelModal = () => {
        setIsVisible(false);
        setContract(null);
    };

    const onSave = async (values) => {
        await onSaveContract(contract.id, values);
        setIsVisible(false);
    };

    return (
        <Modal
            centered
            title="Điều chỉnh hợp đồng"
            visible={isVisible}
            onCancel={onCancelModal}
            destroyOnClose={true}
            footer={null}
            width={650}
        >
            <Form
                colon={false}
                labelCol={{ span: labelCol }}
                wrapperCol={{ span: wrapperCol }}
                onFinish={onSave}
                initialValues={{
                    contractNo: contract.contractNo,
                    startDate: contract.startDate,
                    expireDate: contract.expireDate,
                    interestPeriod: contract.interestPeriod,
                    interest: contract.interest
                }}
            >
                <Form.Item
                    name="contractNo"
                    label="Số hợp đồng"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="startDate"
                    label="Ngày tạo hợp đồng"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <DatePicker
                        className="w-full"
                        placeholder=""
                        format={DATE_FORMAT}
                    />
                </Form.Item>
                <Form.Item
                    name="expireDate"
                    label="Ngày hết hợp đồng"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <DatePicker
                        className="w-full"
                        placeholder=""
                        format={DATE_FORMAT}
                    />
                </Form.Item>
                <Form.Item
                    name="interestPeriod"
                    label="Kỳ rút lãi"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <Select>
                        <Select.Option value={3}>3 Tháng</Select.Option>
                        <Select.Option value={6}>6 Tháng</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="interest"
                    label="Lãi suất/ngày"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <InputNumber
                        className="w-full"
                        controls={false}
                        formatter={value => formatterPercent(value)}
                        parser={value => parserPercent(value)}
                        addonAfter="%"
                    />
                </Form.Item>
                <Form.Item className="mb-0" wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                    <div className="flex justify-end">
                        <Button type="primary" htmlType="submit">Lưu</Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditContractModal;