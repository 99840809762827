import React, { useState } from "react";
import { useEffectOnce } from 'react-use';
import { Layout, Typography, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "@Contexts/Auth";

import api from "@Services/api";
import routes from "@Routes";
import AccessDenied from "@Components/AccessDenied";
import StockForm from './Form';

const UpdateStock = () => {
    const navigate = useNavigate();
    const { id } = useParams() as any;

    const [initialValue, setInitialValue] = useState<NSApp.Stock>();
    const [errors, setErrors] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    let { authState } = useAuthState() as any;
    let { currentUser } = authState;

    const fetchStockDetail = async () => {
        const [err, resp]: any = await api.get(`stock/detail/${id}`);

        if (err) {
            notification.error({
                message: `Không tải được thông tin cổ phiếu. ${err}`,
            });
            return;
        }

        const _data = resp.data;

        setInitialValue({
            id: _data.id,
            code: _data.code.toUpperCase(),
            fullname: _data.fullname,
            exchange: _data.exchange,
            marginRatio: _data.marginRatio,
            loanRoom: _data.loanRoom,
            upperLimitPrice: _data.upperLimitPrice,
            status: _data.status
        });
    }

    const onAddNewStock = async (values: any) => {
        setErrors([]);
        setLoading(true);

        const [err, resp]: any = await api.post(`stock/update/${id}`, {
            ...values,
            code: values.code.toLowerCase()
        });

        setLoading(false);
        if (!err) {
            notification.success({
                message: 'Cập nhật cổ phiếu thành công!',
            });
            return;
        }

        if (err?.response?.data?.errors) {
            setErrors(err.response.data.errors);
        }

        if (err?.response?.data?.message) {
            setErrors([{ msg: err.response.data.message }])
        }
    };

    useEffectOnce(() => {
        fetchStockDetail();
    });

    if (!currentUser?.isSuperAdmin) {
        return <AccessDenied />
    }

    return (
        <Layout className="p-4">
            <Typography.Title level={3} className="uppercase font-bold mb-1">
                Cập nhật thông tin cổ phiếu
            </Typography.Title>
            {initialValue &&
                <StockForm mode='update' initialValues={initialValue} onSubmit={onAddNewStock} loading={loading} errors={errors} />
            }
        </Layout>
    )
}

export default UpdateStock;