import localStorage from './local-storage';
import { NOVU_SOCKET_BASE_URL } from '@Configs';

import io, { Manager } from 'socket.io-clientV2';


export const SocketManager = async () => {
    const token = localStorage.get('widget_user_auth_token');
    const socketManagerInstance = new Manager(NOVU_SOCKET_BASE_URL, {
        reconnectionDelayMax: 10000,
        transports: ['websocket'],
        query: {
            token,
        },
    });

    return socketManagerInstance;
};

export const subscribeNotification = async (cb: any) => {
    const socketManagerInstance = await SocketManager();

    const notificationSocket = socketManagerInstance.socket('/');

    notificationSocket.on('connect', () => {
        console.log('Novu socket connected');
    });

    notificationSocket.on('disconnect', () => {
        console.log('Novu socket disconnected');
    });

    notificationSocket.on('unseen_count_changed', (data: any) => {
        cb(data);
    });

    return notificationSocket;
};

export default SocketManager;
