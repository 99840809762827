import React, { useState } from "react";
import _ from 'lodash';
import { useEffectOnce } from "react-use";
import { Layout, Card, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";

import { moneyFormat, roundDecimalNumber, toRoute } from "@Services/utils";
import { renderValueWithColor } from "@Services/renderSpecial";
import api from '@Services/api';
import routes from "@Routes";

type SummaryBorrower = {
    index: number;
    account?: NSApp.Account;
    source?: NSApp.Source;
    totalBuyValue: number;
    totalMarketValue: number;
    nav1: number;
    nav2: number;
    totalExpectedProfit: number;
    avgMarginRatio: number;
}

const AssetAndMarginTable = ({ sourceDetail }) => {

    const [borrowers, setBorrowers] = useState<SummaryBorrower[]>([]);
    const [totalBuyValue, setTotalBuyValue] = useState<number>(0);
    const [totalSellValue, setTotalSellValue] = useState<number>(0);
    const [totalNav1Value, setTotalNav1Value] = useState<number>(0);
    const [totalNav2Value, setTotalNav2Value] = useState<number>(0);
    const [totalProfit, setTotalProfit] = useState<number>(0);
    const [avgMarginRatio, setAvgMarginRatio] = useState<number>(0);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchSummaryBorrowers = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`source/summary-borrower/${sourceDetail.id}`);

        setIsFetching(false);

        if (err) {
            return;
        }

        const borrowers = resp.data;
        let _totalBuyValue = _.reduce(borrowers, (sum, p) => {
            return sum + p.totalBuyValue;
        }, 0);

        let _totalSellValue = _.reduce(borrowers, (sum, p) => {
            return sum + p.totalSellValue;
        }, 0);

        let _totalNav1Value = _.reduce(borrowers, (sum, p) => {
            return sum + p.nav1;
        }, 0);

        let _totalNav2Value = _.reduce(borrowers, (sum, p) => {
            return sum + p.nav2;
        }, 0);

        let _totalProfit = _.reduce(borrowers, (sum, p) => {
            return sum + p.totalExpectedProfit;
        }, 0);

        let _avgMarginRatio = (_totalNav2Value / _totalBuyValue) * 100;
        setTotalBuyValue(_totalBuyValue);
        setTotalSellValue(_totalSellValue);
        setTotalNav1Value(_totalNav1Value);
        setTotalNav2Value(_totalNav2Value);
        setTotalProfit(_totalProfit);
        setAvgMarginRatio(_avgMarginRatio);
        setBorrowers(borrowers);
    };

    useEffectOnce(() => {
        fetchSummaryBorrowers();
    });

    const columns : ColumnsType<any> = [
        {
            title: '',
            dataIndex: 'account',
            key: 'account',
            render: (value) => <Link className="font-bold" to={toRoute(routes.BORROWER_DETAIL_ASSET, { id: value.profile?.id })}>
                {value.profile?.fullname} - ID: {value.profile?.shortId}
            </Link>
        },
        // {
        //     title: 'Kho',
        //     dataIndex: 'source',
        //     key: 'source',
        //     align: 'right',
        //     render: (value) => value.sourceCode
        // },
        {
            title: 'Giá trị CP mua',
            dataIndex: 'totalBuyValue',
            key: 'totalBuyValue',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Giá trị CP hiện tại',
            dataIndex: 'totalSellValue',
            key: 'totalSellValue',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Giá trị tài sản ròng',
            dataIndex: 'nav2',
            key: 'nav2',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Tỉ lệ ký quỹ TB',
            dataIndex: 'avgMarginRatio',
            key: 'avgMarginRatio',
            align: 'right',
            render: (value) => `${value}%`
        },
        {
            title: 'Lãi/lỗ - Phí',
            dataIndex: 'totalExpectedProfit',
            key: 'totalExpectedProfit',
            align: 'right',
            render: (value) => renderValueWithColor(value)
        },
        {
            title: 'Cọc',
            dataIndex: 'nav1',
            key: 'nav1',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
    ];

    return (
        <Layout>
            <Card className="card-table-header">
                <Typography.Title level={5} className="mb-0">Bảng theo dõi tài sản và tỉ lệ ký quỹ</Typography.Title>
            </Card>
            <Table
                loading={isFetching}
                bordered
                sticky
                rowKey={r => r.account.profile.id}
                dataSource={borrowers}
                columns={columns}
                pagination={{ size: 'small' }}
                summary={() => !!borrowers.length && (
                    <Table.Summary fixed={'top'}>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} className="text-right font-bold">Tổng</Table.Summary.Cell>
                            <Table.Summary.Cell index={1} className="text-right font-bold">{moneyFormat(totalBuyValue)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={2} className="text-right font-bold">{moneyFormat(totalSellValue)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={3} className="text-right font-bold">{moneyFormat(totalNav2Value)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className="text-right font-bold">{`${roundDecimalNumber(avgMarginRatio)}%`}</Table.Summary.Cell>
                            <Table.Summary.Cell index={5} className="text-right font-bold">{renderValueWithColor(totalProfit)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={6} className="text-right font-bold">{moneyFormat(totalNav1Value)}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        </Layout>
    )
};

export default AssetAndMarginTable;