import React, { useEffect, useState } from "react";
import { Layout, Input, Table, Typography, notification, Space, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";

import { moneyFormat, toRoute, useReducedState } from "@Services/utils";
import { renderValueWithColor } from '@Services/renderSpecial';
import api from '@Services/api';
import { PAGE_SIZE_NORMAL } from "@Configs";
import routes from "@Routes";

const SourcesAssetList = () => {
    const [listSources, setListSources] = useState<NSApp.Source[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const [searchKey, setSearchKey] = useState<string>('');
    const [sumTotalValue, setSumTotalValue] = useState<number>(0);
    const [sumProfit, setSumProfit] = useState<number>(0);
    const [sumTotalExpectedProfit, setSumTotalExpectedProfit] = useState<number>(0);
    const [sumNav2, setSumNav2] = useState<number>(0);
    const [sumMaximumPurchasingPower, setSumMaximumPurchasingPower] = useState<number>(0);
    const [sumTotalBuyValue, setSumTotalBuyValue] = useState<number>(0);
    const [sumPurchasingPower, setSumPurchasingPower] = useState<number>(0);

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_NORMAL,
        total: 0
    });

    const isSmallScreen = window.innerWidth < 1920;

    const fetchSources = async () => {
        setIsFetching(true);

        const [err, resp]: any = await api.get('/source/list', {
            filter: searchKey, page: pagination.current, pageSize: PAGE_SIZE_NORMAL
        });

        setIsFetching(false)

        if (err) {
            notification.error({
                message: err?.response?.data?.message
            });
            return;
        }

        const data = resp.data;
        const sources = data.sources;

        setSumTotalValue(data.sumTotalValue);
        setSumProfit(data.sumProfit);
        setSumTotalExpectedProfit(data.sumTotalExpectedProfit);
        setSumNav2(data.sumNav2);
        setSumMaximumPurchasingPower(data.sumMaximumPurchasingPower);
        setSumTotalBuyValue(data.sumTotalBuyValue);
        setSumPurchasingPower(data.sumPurchasingPower);

        setListSources(sources);
    }

    useEffect(() => {
        fetchSources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current, searchKey]);

    const onSearch = (value: string) => {
        setSearchKey(value);
    };

    const columns: ColumnsType<any> = [
        {
            title: 'Số kho',
            dataIndex: 'sourceCode',
            key: 'sourceCode',
            fixed: 'left',
            render: (value, record) => <Link className="font-bold" to={toRoute(routes.SOURCE_DETAIL, { id: record.id })}>{value}</Link>
        },
        {
            title: 'Số TKCK',
            dataIndex: 'securitiesId',
            key: 'securitiesId',
        },
        {
            title: 'Tên chủ TK',
            dataIndex: 'securitiesFullname',
            key: 'securitiesFullname',
        },
        {
            title: 'Tổng tiền nạp/rút',
            dataIndex: 'totalValue',
            key: 'totalValue',
            align: 'right',
            width: isSmallScreen ? 180 : undefined,
            render: (value) => value !== null ? moneyFormat(value) : '-',
        },
        {
            title: 'Lãi/lỗ đã chốt',
            dataIndex: 'profit',
            key: 'profit',
            align: 'right',
            width: isSmallScreen ? 160 : undefined,
            render: (value) => renderValueWithColor(value),
        },
        {
            title: 'Lãi/lỗ hiện tại',
            dataIndex: 'totalExpectedProfit',
            key: 'totalExpectedProfit',
            align: 'right',
            width: isSmallScreen ? 160 : undefined,
            render: (value) => renderValueWithColor(value),
        },
        {
            title: 'NAV2',
            dataIndex: 'nav2',
            key: 'nav2',
            align: 'right',
            width: isSmallScreen ? 180 : undefined,
            render: (value) => value !== null ? moneyFormat(value) : '-',
        },
        {
            title: 'Tỉ lệ hiện tại',
            dataIndex: 'currentMarginRatio',
            key: 'currentMarginRatio',
            align: 'right',
            render: (value) => value !== null ? `${value}%` : '-',
        },
        {
            title: 'Sức mua tối đa',
            dataIndex: 'maximumPurchasingPower',
            key: 'maximumPurchasingPower',
            align: 'right',
            width: isSmallScreen ? 170 : undefined,
            render: (value) => value !== null ? moneyFormat(value) : '-',
        },
        {
            title: 'Tổng giá trị mua',
            dataIndex: 'totalBuyValue',
            key: 'totalBuyValue',
            align: 'right',
            width: isSmallScreen ? 180 : undefined,
            render: (value) => value !== null ? moneyFormat(value) : '-',
        },
        {
            title: 'Sức mua còn lại',
            dataIndex: 'purchasingPower',
            key: 'purchasingPower',
            align: 'right',
            width: isSmallScreen ? 180 : undefined,
            render: (value) => value !== null ? moneyFormat(value) : '-',
        },
    ];

    return (
        <Layout>
            <Layout className="bg-gray p-4">
                <Space>
                    <Button className='custom-btn-tab active'>Tài sản</Button>
                    <Link to={routes.SOURCES_STOCKS}><Button className='custom-btn-tab'>Cổ phiếu</Button></Link>
                </Space>
            </Layout>
            <Layout className="px-4 pt-4">
                <Layout className="flex-row justify-between items-center mb-4">
                    <Typography.Title level={4} className="uppercase font-bold mb-0 mr-4">Danh sách kho</Typography.Title>
                    <div>
                        <Input.Search
                            placeholder="Nhập số kho"
                            allowClear
                            enterButton="Tìm kiếm"
                            style={{ width: 300 }}
                            onSearch={onSearch}
                        />
                    </div>
                </Layout>

                <div className="mb-3">
                    <span>Tất cả ({ listSources.length })</span>
                </div>

                <Table
                    bordered
                    rowKey={(r: any) => r.id}
                    dataSource={listSources}
                    columns={columns}
                    loading={isFetching}
                    scroll={{ x: 'max-content' }}
                    pagination={{
                        ...pagination,
                        size: 'small',
                        onChange: page => {
                            setPagination({ current: page });
                        },
                    }}
                    sticky
                    summary={() => (
                        <Table.Summary fixed={'top'}>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} className="text-right font-bold">Tổng</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumTotalValue)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{renderValueWithColor(sumProfit)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{renderValueWithColor(sumTotalExpectedProfit)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumNav2)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold"></Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumMaximumPurchasingPower)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumTotalBuyValue)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="text-right font-bold">{moneyFormat(sumPurchasingPower)}</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </Layout>
        </Layout>
    )
};

export default SourcesAssetList;