import axios from 'axios';
import localStorage from "./local-storage";
import * as config from "../config";

class Api {
    accessToken: string | null = null;

    constructor() {
        axios.defaults.baseURL = config.API_BASE_URL;

        // TODO: check why we cannot use local-storage module here
        const accessToken = localStorage.get('accessToken');
        if (accessToken) {
            this.setAccessToken(accessToken);
        }
    }

    setAccessToken = (accessToken: string) => {
        this.accessToken = accessToken;
        axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
    };

    get = async (url: string, params = {}) => {
        return new Promise((resolve) => {
            axios.get(url, { params })
                .then(data => resolve([null, data]))
                .catch(e => resolve([e, null]))
        });
    }

    _request = async (method, url, params, headers = {}, options = {}) => {
        params = params || {};
        headers = {
            ...axios.defaults.headers.common,
            ...headers
        }

        return new Promise((resolve) => {
            axios({
                method, url, data: params, headers,
                ...options
            })
                .then(data => resolve([null, data]))
                .catch(e => resolve([e, null]))
        });
    };

    post = async (url, params, headers = {}, options={}) => this._request('post', url, params, headers, options);
    patch = async (url, params, headers = {}) => this._request('patch', url, params, headers);
    put = async (url, params, headers = {}) => this._request('put', url, params, headers);
    delete = async (url, params = {}, headers = {}) => this._request('delete', url, params, headers);
}

export default new Api();
