import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";

const FullscreenToggle = ({ elementId }) => {
    const [fullscreen, setFullscreen] = useState(false);

    const openFullscreen = () => {
        let elem = document.getElementById(elementId);

        if (elem && elem['requestFullscreen']) {
            elem['requestFullscreen']();
        } else if (elem && elem['webkitRequestFullscreen']) { /* Safari */
            elem['webkitRequestFullscreen']();
        } else if (elem && elem['mozRequestFullScreen']) { /* Mozilla */
            elem['mozRequestFullScreen']();
        } else if (elem && elem['msRequestFullscreen']) { /* IE11 */
            elem['msRequestFullscreen']();
        }
        setFullscreen(!fullscreen);
    };

    const closeFullscreen = () => {
        if (document['exitFullscreen']) {
            document['exitFullscreen']();
        } else if (document['webkitExitFullscreen']) { /* Safari */
            document['webkitExitFullscreen']();
        } else if (document['mozCancelFullScreen']) { /* Mozilla */
            document['mozCancelFullScreen']();
        } else if (document['msExitFullscreen']) { /* IE11 */
            document['msExitFullscreen']();
        }
        setFullscreen(!fullscreen);
    };

    useEffect(() => {
        if (document.addEventListener) {
            document.addEventListener('fullscreenchange', exitHandler, false);
            document.addEventListener('webkitfullscreenchange', exitHandler, false); /* Safari */
            document.addEventListener('mozfullscreenchange', exitHandler, false); /* Mozilla */
            document.addEventListener('MSFullscreenChange', exitHandler, false); /* IE11 */
        }

        function exitHandler() {
            if (!document['webkitIsFullScreen'] && !document['mozFullScreen'] && !document['msFullscreenElement']) {
                setFullscreen(false);
            }
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {

    })

    return (
        <Button size="small" className="button-gray" onClick={fullscreen ? closeFullscreen : openFullscreen}>
            {fullscreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
        </Button>
    )
};

export default FullscreenToggle;