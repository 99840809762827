import React, { useState, useEffect } from "react";
import { Layout, Space, Button, Typography, DatePicker, Select, Input, notification, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams, Link } from "react-router-dom";
import moment from "moment";
import { useEffectOnce } from "react-use";

import { useAuthState } from "@Contexts/Auth";
import api from "@Services/api";
import { toRoute, moneyFormat, useReducedState } from "@Services/utils";
import { DATE_FORMAT, PAGE_SIZE_SMALL } from "@Configs";
import routes from "@Routes";

import Loading from "@Components/Loading";
import EditableTable from "@Components/EditableTable";
import BackButton from "@Components/BackButton";
import AccessDenied from "@Components/AccessDenied";

const LenderTransactionHistory = () => {
    const navigate = useNavigate();
    const { id } = useParams() as any;
    let { authState } = useAuthState() as any;
    let { currentUser } = authState;

    const [pagination, setPagination] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_SMALL,
        total: 0,
    });

    const [form] = Form.useForm();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [transactionType, setTransactionType] = useState<string>('');
    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);
    const [searchString, setSearchString] = useState('');
    const [contractNo, setContractNo] = useState('');

    const [transactions, setTransactions] = useState([]);

    const [lender, setLender] = useState<NSApp.Lender>();

    const fetchDetail = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`lender/detail/${id}`, {});

        if (err) {
            notification.error({
                message: "Không tìm thấy người cho vay!"
            });
            return navigate(routes.LENDERS_ASSET);
        }

        if (!resp?.data?.lender) {
            notification.error({
                message: "Không tìm thấy người cho vay!"
            });
            return navigate(routes.LENDERS_ASSET);
        }

        setLender(resp.data.lender);
        setIsFetching(false);
    }

    const fetchTransactions = async (
        fromDate,
        toDate,
        searchString,
        transactionType,
        contractNo
    ) => {
        const filter = {
            page: pagination.current, pageSize: PAGE_SIZE_SMALL, profileId: id
        };

        if (fromDate) {
            filter['fromDate'] = fromDate.clone().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (toDate) {
            filter['toDate'] = toDate.clone().endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if (searchString) {
            filter['ref'] = searchString
        }

        if (transactionType) {
            filter['transactionType'] = transactionType;
        }

        if(contractNo) {
            filter['contractNo'] = contractNo;
        }

        const [err, resp]: any = await api.get(`contract/transactions`, filter);

        if (err) {
            notification.error(err?.response?.data?.message);
            return;
        }

        if (resp) {
            setTransactions(resp.data.transactions.map((item: any) => {
                return {
                    ...item,
                    date: moment(item.date),
                }
            }));

            setPagination({
                ...pagination,
                total: resp.data.total,
            });
        }
    }


    useEffect(() => {
        fetchTransactions(fromDate, toDate, searchString, transactionType, contractNo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionType, fromDate, toDate, searchString, contractNo, pagination.current]);

    const onFinishFilter = (values) => {
        const { date, transactionType, contractNo } = values;
        if (date) {
            setFromDate(date[0]);
            setToDate(date[1]);
        } else {
            setFromDate(null);
            setToDate(null);
        }
        if(contractNo){
            setContractNo(contractNo);
        }
        setTransactionType(transactionType);
        setPagination({ current: 1 });
    };

    const onSaveTransaction = async (id, values) => {
        const [err, resp]: any = await api.post(`contract/transactions/${id}`, {
            ...values
        });

        if (err) {
            notification.error({
                message: "Cập nhật thất bại!"
            });
            notification.error(err?.response?.data?.message);
            return;
        }

        const transaction = resp.data.contractTransaction;
        const _transactions: any = transactions.map((item: any) => {
            if (item.id === transaction.id) {
                transaction.date = moment(transaction.date)
                return transaction;
            }
            return item;
        });
        setTransactions(_transactions);
        notification.success({
            message: "Cập nhật thành công!"
        });
    }

    const onDeleteTransaction = async (id) => {
        const [err, resp]: any = await api.delete(`contract/transactions/${id}`);

        if (err) {
            notification.error({
                message: "Xóa thất bại!"
            });

            if (err?.response?.data?.message) {
                notification.error({
                    message: err.response.data.message
                });
            }

            if (err?.response?.data?.errors) {
                notification.error({
                    message: err?.response?.data.errors[0].msg
                });
            }

            return;
        }

        const _transactions: any = transactions.filter((item: any) => item.id !== id);
        setTransactions(_transactions);
        notification.success({
            message: "Xóa thành công!"
        });
    }

    const resetFilter = () => {
        setFromDate(null);
        setToDate(null);
        setTransactionType('');
        setContractNo('');
        setPagination({ current: 1 });
        form.setFieldsValue({
            date: [],
            transactionType: '',
            contractNo: ''
        });
    }

    const onSearch = (value: string) => {
        setSearchString(value);
        setPagination({
            current: 1
        })
    }

    useEffectOnce(() => {
        fetchDetail();
    });

    const columns = [
        {
            title: 'Ngày',
            dataIndex: 'date',
            key: 'date',
            editable: true,
            inputType: 'date',
            // inputWidth: 200,
            render: (value) => value.format(DATE_FORMAT),
        },
        {
            title: 'Code',
            dataIndex: 'type',
            key: 'type',
            editable: false,
            inputType: 'select',
            inputValue: [
                { value: 'deposit', label: 'NAP' },
                { value: 'withdrawal', label: 'RUT' },
                { value: 'commission', label: 'HHO' },
                { value: 'refund', label: 'HPI' },
            ],
            render: (value) => {
                switch (value) {
                    case 'deposit':
                        return 'NAP';

                    case 'withdrawal':
                        return 'RUT';

                    case 'commission':
                        return 'HHO';

                    case 'refund':
                        return 'HPI';

                    case 'withdrawal-available-interest':
                        return 'RLI';
                    default:
                        return '';
                }
            }
        },
        {
            title: 'Reference',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Kho',
            dataIndex: 'sourceId',
            key: 'sourceId',
            render: (value, record) => {
                return record.contract.source.sourceCode
            }
        },
        {
            title: 'Số hợp đồng',
            dataIndex: 'contractId',
            key: 'contractId',
            render: (value, record) => {
                return record.contract.contractNo
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'note',
            key: 'note',
            editable: true,
            inputType: 'input',
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (value) => `${moneyFormat(value)} đ`,
            editable: true,
            inputType: 'number',
            inputWidth: 200,
        },
    ];

    if (isFetching) {
        return <Loading />;
    }

    if (!lender) {
        return <Layout />;
    }

    if (!isFetching && currentUser && !currentUser?.isSuperAdmin) {
        return <AccessDenied />
    }

    return <Layout>
        <Layout className="flex-row justify-between items-center bg-gray p-4">
            <Space size="large" className="mr-6">
                <BackButton />
                <span>Tên: <strong>{lender.fullname}</strong></span>
                <span>ID: <strong>{lender.shortId}</strong></span>
            </Space>
            <Space>
                <Link to={toRoute(routes.LENDER_DETAIL_ASSET, { id })}><Button className='custom-btn-tab'>Tài sản</Button></Link>
                <Link to={toRoute(routes.LENDER_DETAIL_SOURCE_MANAGER, { id })}><Button className='custom-btn-tab'>Quản lý kho</Button></Link>
                <Button className='custom-btn-tab active'>Lịch sử giao dịch</Button>
                <Link to={toRoute(routes.LENDER_DETAIL_INFO, { id })}><Button className='custom-btn-tab'>Thông tin cơ bản</Button></Link>
            </Space>
            <div/>
        </Layout>
        <Layout className="px-4 py-2">
            <Layout className="flex-row justify-between items-center mb-4">
                <Typography.Title level={4} className="uppercase font-bold mb-0">Lịch sử giao dịch</Typography.Title>
                <div className="flex flex-col mr-4 text-gray-500 hover:text-gray-800 cursor-pointer">
                    <UploadOutlined className="text-lg" />
                    <span className="text-xs">Export</span>
                </div>
            </Layout>
            <Typography.Text><Typography.Link onClick={resetFilter}>Tất cả giao dịch</Typography.Link> ({pagination.total})</Typography.Text>
            <Layout className="flex-row mt-2 mb-5 justify-between items-center">
                <Form
                    form={form}
                    layout="inline"
                    onFinish={onFinishFilter}
                    initialValues={{ transactionType: '' }}
                >
                    <Form.Item name="contractNo">
                        <Input
                            allowClear
                            placeholder="Nhập số hợp đồng"
                            onChange={(e) => {
                                if(e.target.value === "") {
                                    resetFilter();
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="date">
                        <DatePicker.RangePicker
                            format={DATE_FORMAT}
                            placeholder={['Từ ngày', 'Đến ngày']}
                        />
                    </Form.Item>
                    <Form.Item name="transactionType">
                        <Select style={{ width: 200 }}>
                            <Select.Option value="">Tất cả giao dịch</Select.Option>
                            <Select.Option value="deposit">Nạp tiền</Select.Option>
                            <Select.Option value="withdrawal">Rút tiền</Select.Option>
                            <Select.Option value="refund">Hoàn Phí</Select.Option>
                            <Select.Option value="comission">Hoa hồng</Select.Option>
                        </Select>
                    </Form.Item>
                    <Button htmlType="submit">Lọc</Button>
                </Form>
                <div>
                    <Input.Search
                        placeholder="Nhập Reference"
                        allowClear
                        enterButton="Tìm kiếm"
                        style={{ minWidth: 300 }}
                        onSearch={onSearch}
                    />
                </div>
            </Layout>

            <EditableTable
                rowKey="id"
                dataSource={transactions}
                columns={columns}
                pagination={{
                    ...pagination,
                    size: 'small',
                    onChange: page => {
                        setPagination({ current: page })
                    },
                }}
                onSave={onSaveTransaction}
                onDelete={onDeleteTransaction}
            />
        </Layout>
    </Layout>
};

export default LenderTransactionHistory;