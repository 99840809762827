import React, { useState } from "react";
import { Avatar, Card, Form, Input, Button } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import routes from "@Routes";
import api from "@Services/api";

import logo from "@Assets/images/logos/svg/logo_01.svg";

const ForgotPassword = () => {
    const [step, setStep] = useState(1);

    const onFinish = async (values) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post('admin/forgot-password', {
            email: values.email
        })

        setStep(2);
    }

    return (
        <div className="h-screen flex flex-col justify-center items-center">
            <Avatar
                shape="square"
                size={256}
                src={logo}
            />
            <div>
                <Card className="card-description" style={{ width: 400, marginBottom: '1.25rem' }}>
                    {step === 1 && (
                        'Vui lòng nhập email đã đăng kí hoặc ID của bạn. Bạn sẽ nhận được email hướng dẫn thay đổi mật khẩu'
                    )}
                    {step === 2 && (
                        <>Vui lòng kiểm tra email của bạn để tiến hành thay đổi mật khẩu. Sau đó quay trở lại trang <Link to={routes.LOGIN}>Đăng nhập</Link></>
                    )}
                </Card>

                {step === 1 && (
                <>
                    <Card style={{ width: 400, marginBottom: '1.25rem' }}>
                        <Form
                            layout="vertical"
                            onFinish={onFinish}
                        >
                            <Form.Item
                                required={false}
                                label="Email hoặc ID"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Không được để trống',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <div className="flex justify-end">
                                <Button htmlType="submit" type="primary">Đổi mật khẩu</Button>
                            </div>
                        </Form>
                    </Card>
                    <Link className="flex items-center text-slate-600 hover:text-black" to={routes.LOGIN}>
                        <ArrowLeftOutlined className="mr-2" />
                        Trở lại đăng nhập
                    </Link>
                </>
                )}
            </div>
        </div>
    )
}

export default ForgotPassword;