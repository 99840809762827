import React, { useEffect, useState } from "react";
import { Row, Col, Popconfirm, Alert, notification, Layout, Typography, Form, Input, Button, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import routes from "@Routes";
import api from "@Services/api";

import Loading from "@Components/Loading";

const labelCol = 3;
const wrapperCol = 8;

const EditUser = () => {
    const navigate = useNavigate();

    const [errors, setErrors]: any = useState([]);

    const { id } = useParams() as any;
    const [admin, setAdmin] = useState<NSApp.Admin>();

    const fetchAdmin = async (id: string) => {
        const [err, resp]: any = await api.get(`admin/detail/${id}`);

        if (err) {
            notification.error({
                message: "Không tìm thấy tài khoản!"
            });
            return navigate(routes.USERS);
        }

        if (!resp?.data?.admin) {
            notification.error({
                message: "Không tìm thấy tài khoản!"
            });
            return navigate(routes.USERS);
        }

        setAdmin(resp.data.admin);
    };

    useEffect(() => {
        fetchAdmin(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!admin) {
        return <Loading />
    }

    const onDelete = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`admin/delete/${id}`, {});

        if (err) {
            notification.error({
                message: err?.response?.data?.message
            });
            return;
        }

        notification.success({
            message: `Xoá tài khoản ${admin.username} thành công!`
        })
        return navigate(routes.USERS)
    }

    const onFinish = async (values) => {
        setErrors([]);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`admin/update/${id}`, {
            fullname: values.fullname,
            phone: values.phone
        });


        if (!err) {
            notification.success({
                message: 'Cập nhật tài khoản thành công!',
            });
        }

        if (err?.response?.data?.errors) {
            setErrors(err.response.data.errors);
        }

        if (err?.response?.data?.message) {
            setErrors([{msg: err.response.data.message}])
        }
    }

    return (
        <Layout className="p-4">
            <Typography.Title level={3} className="uppercase font-bold mb-1">
                Cập nhật thông tin quản trị viên
            </Typography.Title>
            <Layout className="mt-10">
                {(!!errors || !!errors.length) && (
                    <Row className="mb-4">
                        <Col xs={24} lg={11}>
                            {errors.map((error: any, index: number) => {
                                return <Alert
                                    key={index}
                                    className="mb-1"
                                    type={"error"}
                                    message={error.msg}
                                    showIcon
                                />
                            })}
                        </Col>
                    </Row>
                )}
                <Form
                    layout="horizontal"
                    labelAlign="left"
                    colon={false}
                    labelCol={{ span: labelCol }}
                    wrapperCol={{ span: wrapperCol }}
                    initialValues={{
                        fullname: admin.fullname,
                        username: admin.username,
                        email: admin.email,
                        phone: admin.phone,
                        role: "admin",
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item name="fullname" label="Họ và tên">
                        <Input />
                    </Form.Item>
                    <Form.Item name="username" label="ID">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="email" label="Email">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="phone" label="Số điện thoại">
                        <Input />
                    </Form.Item>
                    <Form.Item name="role" label="Role">
                        <Select disabled>
                            <Select.Option value="admin">Admin</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                        <div className="flex justify-end">
                            <Button className="mr-2" onClick={() => navigate(routes.USERS)}>Huỷ</Button>
                            <Popconfirm
                                title="Bạn có muốn xoá tài khoản này không ?"
                                onConfirm={onDelete}
                                okText="Có"
                                cancelText="Không"
                            >
                                <Button className="mr-2" type="primary" danger>Xoá</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit">Lưu</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Layout>
        </Layout>
    )
}

export default EditUser;