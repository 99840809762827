import React, { useState, useEffect } from "react";
import { Layout, Dropdown, Menu, Space, notification, Avatar } from "antd";
import { CaretDownOutlined, UserOutlined, LogoutOutlined, AppstoreOutlined, CreditCardOutlined, BankOutlined, SettingOutlined, HistoryOutlined, DollarCircleOutlined } from '@ant-design/icons';
import type { MenuProps } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import localStorage from "@Services/local-storage";
import { useAuthState } from "@Contexts/Auth";

import {
    NovuProvider,
    PopoverNotificationCenter,
    NotificationBell,
    IMessage,
} from '@novu/notification-center';

import routes from "../routes";
import logo from "@Assets/images/logos/svg/logo_04.svg";

import { NOVU_APP_ID } from "@Configs";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    key: React.Key,
    label: React.ReactNode,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return { key, label, icon, children } as MenuItem;
}

const fullItems: MenuItem[] = [
    getItem(routes.DASHBOARD, <Link to={routes.DASHBOARD}>DASHBOARD</Link>, <AppstoreOutlined />),
    getItem(routes.BORROWERS, <Link to={routes.BORROWERS}>NGƯỜI VAY</Link>, <CreditCardOutlined />),
    getItem(routes.SOURCES_ASSET, <Link to={routes.SOURCES_ASSET}>KHO</Link>, <BankOutlined />),
    getItem(routes.LENDERS_ASSET, <Link to={routes.LENDERS_ASSET}>NGƯỜI CHO VAY</Link>, <DollarCircleOutlined />),
    getItem(routes.HISTORY_MONEY_TRANSACTIONS, <Link to={routes.HISTORY_MONEY_TRANSACTIONS}>LỊCH SỬ</Link>, <HistoryOutlined />),
    getItem(routes.USERS, <Link to={routes.USERS}>QUẢN TRỊ VIÊN</Link>, <UserOutlined />),
    getItem(routes.SETTINGS, <Link to={routes.SETTINGS}>CÀI ĐẶT</Link>, <SettingOutlined />),
];

const permissionItems: MenuItem[] = [
    getItem(routes.DASHBOARD, <Link to={routes.DASHBOARD}>DASHBOARD</Link>, <AppstoreOutlined />),
    getItem(routes.BORROWERS, <Link to={routes.BORROWERS}>NGƯỜI VAY</Link>, <CreditCardOutlined />),
    getItem(routes.SOURCES_ASSET, <Link to={routes.SOURCES_ASSET}>KHO</Link>, <BankOutlined />),
];

const Header = () => {
    const [currentRouteKey, setCurrentRouteKey] = useState(routes.DASHBOARD);
    let { authState, setAuthState } = useAuthState() as any;

    let { currentUser } = authState;
    let navigateTo = useNavigate();
    let location = useLocation();

    const onClickMenu = (e) => {
        const route = e.key;
        if (!route){
            setCurrentRouteKey(location?.pathname);
            return true;
        }
        setCurrentRouteKey(route);
        navigateTo(route);
    }

    useEffect(() => {
        if (location?.pathname === routes.HOME) {
            setCurrentRouteKey(routes.DASHBOARD);
        } else if (location?.pathname.startsWith('/borrower')) {
            setCurrentRouteKey(routes.BORROWERS);
        } else if (location?.pathname.startsWith('/lender')) {
            setCurrentRouteKey(routes.LENDERS_ASSET);
        } else if (location?.pathname.startsWith('/source')) {
            setCurrentRouteKey(routes.SOURCES_ASSET);
        } else if (location?.pathname.startsWith('/history')) {
            setCurrentRouteKey(routes.HISTORY_MONEY_TRANSACTIONS);
        }else {
            setCurrentRouteKey(location?.pathname);
        }
    }, [location?.pathname]);

    const onLogout = () => {
        localStorage.remove("accessToken");

        setAuthState({ currentUser: null });

        notification.success({
            message: "Đăng xuất thành công!",
        });

        navigateTo(routes.LOGIN, { replace: true });
    }

    function onNotificationClick(notification: IMessage) {
        const notifUrl = notification.cta.data.url;
        if (notifUrl) {
            navigateTo(notifUrl, { state: { tabKey: 'order-book' }});
        }
    }

    const AccountMenu = (
        <Menu
            items={[
                {
                    key: 'profile',
                    label: <Link to={routes.PROFILE}>Thông tin cá nhân</Link>,
                    icon: <UserOutlined />,
                },
                {
                    key: 'logout',
                    label: <span onClick={onLogout}>Đăng xuất</span>,
                    icon: <LogoutOutlined />,
                },
            ]}
        />
    );

    return (
        <Layout.Header className="flex justify-between">
            <div className="flex justify-center items-center h-12">
                <Avatar shape="square" size={80} src={logo} />
            </div>
            <Menu
                theme="dark"
                mode="horizontal"
                className="flex-1 justify-center"
                onClick={onClickMenu}
                selectedKeys={[currentRouteKey]}
                // defaultOpenKeys={['dashboard']}
                items={currentUser?.isSuperAdmin ? fullItems : permissionItems}
            />
            {currentUser &&
                <div className="h-full flex items-center mr-3">
                    <NovuProvider
                        subscriberId={currentUser.id}
                        applicationIdentifier={NOVU_APP_ID}
                    >
                        <PopoverNotificationCenter
                            onNotificationClick={onNotificationClick}
                            colorScheme="light"
                            showUserPreferences={false}
                        >
                            {({ unseenCount }) => {
                                return <NotificationBell unseenCount={unseenCount} />
                            }}
                        </PopoverNotificationCenter>
                    </NovuProvider>
                </div>
            }
            <Dropdown overlay={AccountMenu} placement="bottomRight" trigger={["click"]}>
                <span className="cursor-pointer text-white hover:text-blue-400">
                    <Space>
                        <span>{currentUser?.fullname}, {currentUser?.isSuperAdmin ? 'Super Admin' : 'Admin'}</span>
                        <CaretDownOutlined className="align-baseline" />
                    </Space>
                </span>
            </Dropdown>
        </Layout.Header>
    )
};

export default Header;