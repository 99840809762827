const getProtocolAndHostName = () => {
    const {href} = window.location;
    const url = new URL(href);
    const {protocol, hostname} = url;
    return {protocol, hostname}
};

const getApiBaseUrl = () => {
    let {protocol, hostname} = getProtocolAndHostName();

    if (hostname === "app-f123.fstock.vn") {
        return `https://api-f123.fstock.vn/`;
    }

    if (hostname === "app.f123.vn") {
        return `https://api.f123.vn/`;
    }

    return `${protocol}//${hostname}:8000/`;
};

const getRealtimeBaseUrl = () => {
    let {protocol, hostname} = getProtocolAndHostName();

    if (hostname === "app-f123.fstock.vn") {
        return `https://realtime-f123.fstock.vn/`;
    }

    if (hostname === "app.f123.vn") {
        return `https://realtime.f123.vn/`;
    }

    return `${protocol}//${hostname}:8001/`;
};

const getNovuAppId = () => {
    let {protocol, hostname} = getProtocolAndHostName();

    if (hostname === "app-f123.fstock.vn") {
        return "F4dpG59JkFHC";
    }

    if (hostname === "app.f123.vn") {
        return "Fuuc4wm4ynyS";
    }

    return `F4dpG59JkFHC`;
}

export const API_BASE_URL = getApiBaseUrl();
export const REALTIME_BASE_URL = getRealtimeBaseUrl();

export const PAGE_SIZE_SMALL = 10;
export const PAGE_SIZE_NORMAL = 15;
export const PAGE_SIZE_LARGE = 20;

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const NOVU_APP_ID = getNovuAppId();
export const NOVU_SOCKET_BASE_URL = 'https://ws.novu.co/';