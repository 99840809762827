import React, { useState } from "react";
import { Alert, notification, Layout, Row, Col, Typography, Form, Input, Checkbox, Button, Select } from "antd";
import { useNavigate } from "react-router-dom";

import routes from "@Routes";
import api from "@Services/api";

const labelCol = 3;
const wrapperCol = 8;

// sets of characters
const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const lower = 'abcdefghijklmnopqrstuvwxyz';
const digit = '0123456789';
const special = '~`!@#$%^&*()_+-={}[]<>/:;"?,.';
const all = upper + lower + digit + special;

// generate random integer not greater than `max`
function rand (max: number) {
    return Math.floor(Math.random() * max);
}
// generate random character of the given `set`
function random (set: any) {
    return set[rand(set.length - 1)];
}

// generate an array with the given `length` of characters of the given `set`
function generate (length: number, set: any) {
    let result: string[] = [];
    while (length--) result.push(random(set));
    return result;
}

// shuffle an array randomly
function shuffle (arr) {
    let result: string[] = [];

    while (arr.length) {
        result = result.concat(arr.splice(rand[arr.length - 1]));
    }
    return result;
}

function genRandPassword (length) {
    let result: string[] = [] // we need to ensure we have some characters

    result = result.concat(generate(1, upper)); // 1 upper case
    result = result.concat(generate(1, lower)); // 1 lower case
    result = result.concat(generate(1, digit)); // 1 digit
    result = result.concat(generate(1, special)); // 1 digit
    result = result.concat(generate(length - 4, all)); // remaining - whatever

    return shuffle(result).join(''); // shuffle and make a string
}

const AddNewUser = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [errors, setErrors]: any = useState([]);

    const autoGeneratePassword = () => {
        const randPassword = genRandPassword(8);
        form.setFieldsValue({
            password: randPassword,
        })
    }

    const onFinish = async (values) => {
        setErrors([]);
        const [err, resp]: any = await api.post('admin/create', {
            email: values.email,
            fullname: values.fullname,
            phone: values.phone,
            password: values.password,
            sendNotif: values.sendNotif
        })

        if (!err) {
            notification.success({
                message: 'Tạo tài khoản thành công!',
            });

            return navigate(routes.USERS);
        }

        if (err?.response?.data?.errors) {
            setErrors(err.response.data.errors);
        }

        if (err?.response?.data?.message) {
            setErrors([{msg: err.response.data.message}])
        }
    }

    return (
        <Layout className="p-4">
            <Typography.Title level={3} className="uppercase font-bold mb-1">Thêm mới quản trị viên</Typography.Title>
            <Typography.Text>Vui lòng nhập thông tin vào tất cả các trường</Typography.Text>

            <Layout className="mt-10">
                {(!!errors || !!errors.length) && (
                    <Row className="mb-4">
                        <Col xs={24} lg={11}>
                            {errors.map((error: any, index: number) => {
                                return <Alert
                                    key={index}
                                    className="mb-1"
                                    type={"error"}
                                    message={error.msg}
                                    showIcon
                                />
                            })}
                        </Col>
                    </Row>
                )}
                <Form
                    layout="horizontal"
                    labelAlign="left"
                    colon={false}
                    form={form}
                    labelCol={{ span: labelCol }}
                    wrapperCol={{ span: wrapperCol }}
                    initialValues={{
                        sendNotif: true,
                        role: 'admin'
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="fullname"
                        label="Họ và tên"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                            {
                                type: 'email',
                                message: 'Email chưa đúng định dạng'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Số điện thoại"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item className="mb-2" wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                        <Button onClick={autoGeneratePassword}>Mật khẩu tự động</Button>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Mật khẩu"
                        className="mb-0"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                            {
                                pattern: new RegExp(/^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/),
                                message: 'Mật khẩu chưa đủ bảo mật'
                            }
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                        <small className="text-gray-500 italic">Mật khẩu chứa ít nhất 8 ký tự, 1 chữ cái hoa, 1 chữ cái thường, 1 số và 1 ký tự đặc biệt</small>
                    </Form.Item>
                    <Form.Item name="sendNotif" label="Gửi thông báo" valuePropName="checked">
                        <Checkbox>Gửi email các thông tin về tài khoản được tạo</Checkbox>
                    </Form.Item>
                    <Form.Item name="role" label="Role">
                        <Select disabled>
                            <Select.Option value="admin">Admin</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                        <div className="flex justify-end">
                            <Button className="mr-2" onClick={() => navigate(routes.USERS)}>Huỷ</Button>
                            <Button type="primary" htmlType="submit">Thêm mới</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Layout>
        </Layout>
    )
}

export default AddNewUser;