import React, { useEffect, useState } from "react";
import { Layout, Card, Button, Typography, Table, notification } from "antd";
import moment from "moment";

import api from '@Services/api';
import { DATE_TIME_FORMAT, PAGE_SIZE_SMALL } from '@Configs';
import { useReducedState, moneyFormat } from "@Services/utils";
import EditableTable from "@Components/EditableTable";

const RefundAndCommissionTable = ({ profile, openCommissionModal, forceReload }) => {

    const [transactions, setTransactions] = useState<NSApp.ContractTransaction[]>([]);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_SMALL,
        total: 0
    });

    const fetchRefundAndCommissionTransactions = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get("contract/transactions/refund-commission/", {
            profileId: profile.id,
            page: pagination.current, pageSize: pagination.pageSize
        });

        setIsFetching(false);
        if (err) {
            return;
        }

        const _transactions = resp.data.transactions.map(t => {
            return {
                ...t,
                date: moment(t.date)
            }
        })

        setTotalAmount(resp.data.totalAmount);
        setTransactions(_transactions);
        setPagination({ total: resp.data.total })
    };

    const onSaveTransaction = async (id, values) => {
        const { date, note, amount } = values;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.post(`contract/transactions/${id}`, {
            amount, note, date
        });

        if (err) {
            return notification.error({
                message: 'Có lỗi xảy ra',
            })
        }

        fetchRefundAndCommissionTransactions();
        return notification.success({
            message: 'Cập nhật giao dịch thành công',
        })
    }

    const onDeleteTransaction = async (id) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.delete(`contract/transactions/${id}`);

        if (err) {
            return notification.error({
                message: 'Có lỗi xảy ra',
            })
        }

        fetchRefundAndCommissionTransactions();
        return notification.success({
            message: 'Xoá giao dịch thành công',
        })
    }

    useEffect(() => {
        fetchRefundAndCommissionTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current, forceReload]);

    const columns = [
        {
            title: 'Ngày',
            dataIndex: 'date',
            key: 'date',
            editable: true,
            inputType: 'date-time',
            inputWidth: 170,
            render: (value) => value.format(DATE_TIME_FORMAT),
        },
        {
            title: 'Reference',
            dataIndex: 'id',
            key: 'id',
            editable: false,
            inputType: 'input',
        },
        {
            title: 'Kho',
            dataIndex: 'contract',
            key: 'contract',
            editable: false,
            inputType: 'input',
            render: (value) => <span>{value.source.sourceCode}</span>,
        },
        {
            title: 'Số hợp đồng',
            dataIndex: 'contractId',
            key: 'contractId',
            editable: false,
            inputType: 'input',
        },
        {
            title: 'Loại giao dịch',
            dataIndex: 'type',
            key: 'type',
            editable: false,
            inputType: 'select',
            inputValue: [
                { value: 'refund', label: 'Hoàn phí' },
                { value: 'commission', label: 'Hoa hồng' },
            ],
            render: (value) => {
                if (value === 'refund') return 'Hoàn phí';
                if (value === 'commission') return 'Hoa hồng'
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'note',
            key: 'note',
            editable: true,
            inputType: 'input',
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: (value) => moneyFormat(value),
            editable: true,
            inputType: 'number',
            inputWidth: 200,
        },
    ];

    return (
        <Layout>
            <Card className="card-table-header">
                <div className="flex justify-between items-center">
                    <Typography.Title level={5} className="mb-0">Lãi margin CTCK và hoàn phí</Typography.Title>
                    <Button size="small" onClick={openCommissionModal}>Thêm</Button>
                </div>
            </Card>
            <EditableTable
                loading={isFetching}
                rowKey="id"
                dataSource={transactions}
                columns={columns}
                pagination={{
                    ...pagination,
                    showSizeChanger: false,
                    onChange: page => {
                        setPagination({ current: page });
                    },
                }}
                onSave={onSaveTransaction}
                onDelete={onDeleteTransaction}
                sticky
                summary={() => !!transactions.length &&  (
                    <Table.Summary fixed={'top'}>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1} ></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                            <Table.Summary.Cell index={3}></Table.Summary.Cell>
                            <Table.Summary.Cell index={4}></Table.Summary.Cell>
                            <Table.Summary.Cell index={5}></Table.Summary.Cell>
                            <Table.Summary.Cell index={6} className="text-right font-bold">
                                Tổng: {moneyFormat(totalAmount)}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        </Layout>
    )
};

export default RefundAndCommissionTable;