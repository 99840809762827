import React, { useState } from "react";
import { Layout, Row, Col, Form, Input, Button, Select, Alert, Switch } from "antd";
import { useNavigate } from "react-router-dom";

import routes from "@Routes";

const labelCol = 6;
const wrapperCol = 14;

type StockFormProps = {
    initialValues: any,
    onSubmit: (values: any) => void;
    errors?: any;
    loading: boolean;
    mode: 'add-new' | 'update';
};

const StockForm = (props: StockFormProps) => {
    const { initialValues, errors, onSubmit, loading, mode } = props;
    console.log('StockForm', initialValues)
    const navigate = useNavigate();

    return (
        <Layout className="mt-10">
            {(!!errors || !!errors?.length) && (
                <Row className="mb-4">
                    <Col xs={24} lg={11}>
                        {errors.map((error: any, index: number) => {
                            return <Alert
                                key={index}
                                className="mb-1"
                                type={"error"}
                                message={error.msg}
                                showIcon
                            />
                        })}
                    </Col>
                </Row>
            )}
            <Form
                layout="horizontal"
                initialValues={initialValues}
                labelAlign="left"
                colon={false}
                labelCol={{ span: labelCol }}
                wrapperCol={{ span: wrapperCol }}
                onFinish={onSubmit}
            >
                <Row>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            name="code"
                            label="Mã cổ phiếu"
                            rules={[
                                {
                                    required: true,
                                    message: 'Không được để trống',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="exchange" label="Sàn giao dịch">
                            <Select>
                                <Select.Option value={'hose'}>HOSE</Select.Option>
                                <Select.Option value={'hnx'}>HNX</Select.Option>
                                <Select.Option value={'upcom'}>UPCOM</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="fullname" label="Tên công ty/ tổ chức">
                            <Input />
                        </Form.Item>
                        <Form.Item name="status" label="Trạng thái">
                            <Select>
                                <Select.Option value={'active'}>Đang hoạt động</Select.Option>
                                <Select.Option value={'inactive'}>Không hoạt động</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            name="marginRatio"
                            label="Tỷ lệ margin"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="loanRoom" label="Room phát vay (CP)">
                            <Input />
                        </Form.Item>
                        <Form.Item name="upperLimitPrice" label="Giá chặn trên">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} lg={12} />
                    <Col xs={24} lg={12}>
                        <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                            <div className="flex justify-end">
                                <Button className="mr-2" onClick={() => navigate(routes.SETTINGS)}>Huỷ</Button>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    {mode === 'add-new' ?
                                        <span>Thêm mới</span>
                                        :
                                        <span>Cập nhật</span>
                                    }
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Layout>
    )
}

export default StockForm;