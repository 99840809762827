import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;

const NormalFooter = () => {
    return (
        <Footer className="text-center py-2">F-123 ©2022 Created by F-CAPITAL</Footer>
    )
};

export default NormalFooter;
