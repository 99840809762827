import React, { useState } from "react";
import { Layout, Card, Table, Space, Button, Typography, notification, Tooltip, Checkbox, Popover, Row, Col } from "antd";
import { SwapOutlined, EditOutlined } from "@ant-design/icons";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { ColumnsType } from "antd/lib/table";
import { useEffectOnce } from "react-use";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

import localStorage from "@Services/local-storage";
import api from "@Services/api";
import { moneyFormat, toRoute } from "@Services/utils";
import { DATE_FORMAT, PAGE_SIZE_SMALL } from "@Configs";
import routes from "@Routes";

import ContractTransactionHistoryModal from "./ContractTransactionHistory";
import EditContractModal from "./EditContractModal";

const defaultColumnsKey = [
    'contractNo', 'source', 'contractValue', 'averageNAV', 'startDate', 'expireDate', 'interestPeriod', 'latestInterestPaymentDate', 'minimumInterestValue', 'balance'
];

const requiredColumnsKey = ['contractNo', 'source', 'contractValue', 'averageNAV', 'balance'];

const LenderStatusTable = ({
    lender,
    openTransactionModal,
    openCloseContractModal,
    contracts,
    setContracts,
    totalContractValue,
    setTotalContractValue,
    totalInterestValue,
    setTotalInterestValue,
    totalInterestWithdrawnValue,
    totalCommissionAndRefundFee,
    totalMinimumInterestValue,
    onForceReload
}) => {
    const [contractTransactionHistory, setContractTransactionHistory] = useState();
    const [currentContract, setCurrentContract] = useState(null);
    const [isContractModalVisible, setIsContractModalVisible] = useState<boolean>(false);
    const [isEditContractModalVisible, setIsEditContractModalVisible] = useState<boolean>(false);

    const columnsKeyLocalStorage = localStorage.get('lenderAssetStatusTable');
    const [columnsKey, setColumnsKey] = useState<CheckboxValueType[]>([]);
    const [checkDefault, setCheckDefault] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    useEffectOnce(() => {
        const _columnsKey = columnsKeyLocalStorage ? columnsKeyLocalStorage : defaultColumnsKey;
        const allColumnsKey = columnsOptions.map(column => column.value);
        const isCheckDefault = _.isEqual(_columnsKey.sort(), defaultColumnsKey.sort());
        const isCheckAll = _columnsKey.length === allColumnsKey.length;

        if (isCheckDefault) setCheckDefault(true);
        if (isCheckAll) setCheckAll(true);
        setColumnsKey(_columnsKey);
    });

    const onSaveContract = async (id, values) => {
        const [err, resp]: any = await api.post(`contract/update/${id}`, {
            contractNo: values.contractNo,
            contractValue: values.contractValue,
            interest: values.interest,
            interestPeriod: values.interestPeriod,
            startDate: values.startDate,
            expireDate: values.expireDate,
            latestInterestPaymentDate: values.latestInterestPaymentDate
        })

        if (err) {
            return notification.error({
                message: "Có lỗi trong quá trình cập nhật hợp đồng"
            })
        }

        const contract = resp.data.contract;
        let diffTotalValue = 0;
        let diffTotalInterest = 0;
        const _contracts = contracts.map(_contract => {
            if (_contract.id === contract.id) {
                if (contract.startDate) {
                    contract.startDate = moment(contract.startDate)
                }

                if (contract.expireDate) {
                    contract.expireDate = moment(contract.expireDate)
                }

                if (contract.latestInterestPaymentDate) {
                    contract.latestInterestPaymentDate = moment(contract.latestInterestPaymentDate);
                }

                diffTotalValue = contract.contractValue - _contract.contractValue;
                diffTotalInterest = contract.interestValue - _contract.interestValue;
                return contract;
            }
            return _contract;
        });

        setContracts(_contracts);
        setTotalContractValue(totalContractValue + diffTotalValue);
        setTotalInterestValue(totalInterestValue + diffTotalInterest);

        return notification.success({
            message: "Cập nhật hợp đồng thành công"
        })
    }

    const fetchContractTransactionHistory = async (id) => {
        const _contract = await contracts.find(contract => contract.id === id);
        if (!_contract) {
            return;
        }
        setContractTransactionHistory(_contract);
    }

    const onOpenContractTransactionHistoryModal = async (value) => {
        await fetchContractTransactionHistory(value);
        setIsContractModalVisible(true);
    }

    const onOpenEditContractModal = (record) => {
        setIsEditContractModalVisible(true);
        setCurrentContract(record);
    };

    const assetStatusColumns: ColumnsType<any> = [
        {
            title: 'Số HĐ',
            dataIndex: 'contractNo',
            key: 'contractNo',
            width: 120,
            render: (value, record) => <span>
                {value} <span onClick={() => onOpenEditContractModal(record)} className="text-primary underline cursor-pointer"><EditOutlined /></span>
            </span>,
        },
        {
            title: 'Kho',
            dataIndex: 'source',
            key: 'source',
            width: 120,
            render: (value) => <Link className="font-bold" to={toRoute(routes.SOURCE_DETAIL, { id: value.id })}>
                {value.sourceCode}
            </Link>
        },
        {
            title: 'Tổng tiền nạp/rút',
            dataIndex: 'contractValue',
            key: 'contractValue',
            align: 'right',
            width: 150,
            render: (value, record) => (
                <span
                    onClick={() => onOpenContractTransactionHistoryModal(record.id)}
                    className="text-primary underline cursor-pointer">
                    {moneyFormat(value)}
                </span>
            ),
        },
        {
            title: 'NAV TB',
            dataIndex: 'averageNAV',
            key: 'averageNAV',
            align: 'right',
            width: 150,
            render: (value) => value ? moneyFormat(value) : 0,
        },
        {
            title: 'Ngày tạo HĐ',
            dataIndex: 'startDate',
            key: 'startDate',
            align: 'center',
            width: 120,
            render: (value) => moment(value).format(DATE_FORMAT),
        },
        {
            title: 'Ngày hết HĐ',
            dataIndex: 'expireDate',
            key: 'expireDate',
            align: 'center',
            width: 120,
            render: (value) => moment(value).format(DATE_FORMAT),
        },
        {
            title: 'Kỳ rút lãi',
            dataIndex: 'interestPeriod',
            key: 'interestPeriod',
            align: 'center',
            width: 100,
            render: (value) => `${value} tháng`
        },
        {
            title: 'Ngày rút lãi',
            dataIndex: 'latestInterestPaymentDate',
            key: 'latestInterestPaymentDate',
            align: 'center',
            width: 120,
            render: (value) => value ? moment(value).format(DATE_FORMAT) : "Chưa rút",
        },
        {
            title: 'Lãi suất/ ngày',
            dataIndex: 'interest',
            key: 'interest',
            align: 'right',
            width: 120,
            render: (value) => `${value}%`
        },
        {
            title: 'Lãi tối thiểu',
            dataIndex: 'minimumInterestValue',
            key: 'minimumInterestValue',
            align: 'right',
            width: 150,
            render: (value) => moneyFormat(value),
        },
        {
            title: 'Lãi đến hạn được rút',
            dataIndex: 'interestValue',
            key: 'interestValue',
            align: 'right',
            width: 160,
            render: (value) => moneyFormat(value),
        },
        {
            title: 'Lãi đến hạn đã rút',
            dataIndex: 'interestWithdrawn',
            key: 'interestWithdrawn',
            align: 'right',
            width: 160,
            render: (value) => moneyFormat(value),
        },
        // {
        //     title: 'Hoa hồng phí GD',
        //     dataIndex: 'commissionTransactionFee',
        //     key: 'commissionTransactionFee',
        //     align: 'right',
        //     width: 160,
        //     render: (value, record) => moneyFormat(value)
        // },
        {
            title: 'Tổng lãi và phí',
            key: 'balance',
            align: 'right',
            width: 160,
            render: (value, record) => moneyFormat(record.minimumInterestValue)
        },
    ];

    const visibleColumns: any = assetStatusColumns.filter((column: any) => columnsKey.includes(column.key));

    const columnsOptions = [
        { label: 'Số hợp đồng', value: 'contractNo', disabled: true },
        { label: 'Kho', value: 'source', disabled: true },
        { label: 'Tổng tiền nạp rút', value: 'contractValue', disabled: true },
        { label: 'NAV TB', value: 'averageNAV', disabled: true },
        { label: 'Ngày tạo HĐ', value: 'startDate' },
        { label: 'Ngày hết HĐ', value: 'expireDate' },
        { label: 'Kỳ rút lãi', value: 'interestPeriod' },
        { label: 'Ngày rút lãi', value: 'latestInterestPaymentDate' },
        { label: 'Lãi suất/ ngày', value: 'interest' },
        { label: 'Lãi tối thiểu', value: 'minimumInterestValue' },
        { label: 'Lãi đến hạn được rút', value: 'interestValue' },
        { label: 'Lãi đến hạn đã rút', value: 'interestWithdrawn' },
        // { label: 'Hoa hồng phí GD', value: 'commissionTransactionFee' },
        { label: 'Tổng lãi và phí', value: 'balance', disabled: true },
    ];

    const onChangeCheckbox = (checkedValues: CheckboxValueType[]) => {
        if (checkedValues === defaultColumnsKey) {
            setCheckDefault(true);
        } else {
            setCheckDefault(false);
        }
        setColumnsKey(checkedValues);
        setCheckAll(checkedValues.length === columnsOptions.length);
        localStorage.set('lenderAssetStatusTable', checkedValues);
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        const allColumnsKey = columnsOptions.map(column => column.value);
        const values = e.target.checked ? allColumnsKey : requiredColumnsKey;

        setCheckDefault(false);
        setColumnsKey(values);
        setCheckAll(e.target.checked);
        localStorage.set('lenderAssetStatusTable', values);
    };

    const onCheckDefault = (e: CheckboxChangeEvent) => {
        setCheckAll(false);
        setColumnsKey(defaultColumnsKey);
        setCheckDefault(e.target.checked);
        localStorage.set('lenderAssetStatusTable', defaultColumnsKey);
    };

    const CheckboxRender = () => {
        return <div className="flex flex-col">
            <Typography.Text className="text-base font-bold mb-4">Tuỳ chỉnh hiển thị</Typography.Text>
            <Row className="mb-3">
                <Col xs={24} md={8}><Checkbox onChange={onCheckAllChange} checked={checkAll}>Chọn tất cả</Checkbox></Col>
                <Col xs={24} md={8}><Checkbox onChange={onCheckDefault} checked={checkDefault}>Mặc định</Checkbox></Col>
            </Row>
            <Checkbox.Group value={columnsKey} onChange={onChangeCheckbox}>
                <Row>
                    {columnsOptions.map(option => {
                        return <Col xs={24} md={8} key={option.value} className="mb-1">
                            <Checkbox value={option.value} disabled={option.disabled}>{option.label}</Checkbox>
                        </Col>
                    })}
                </Row>
            </Checkbox.Group>
        </div>
    };

    const renderSummary = () => {
        if (!contracts.length) return;

        return (
            <Table.Summary fixed={'top'}>
                <Table.Summary.Row>
                    {visibleColumns.map((column, index) => {
                        let children = <></>;
                        if (column.key === 'source') children = <div className="font-bold">Tổng</div>;
                        if (column.key === 'contractValue') children = <div className="text-right font-bold">{moneyFormat(totalContractValue)}</div>;
                        if (column.key === 'minimumInterestValue') children = <div className="text-right font-bold">{moneyFormat(totalMinimumInterestValue)}</div>;
                        if (column.key === 'interestValue') children = <div className="text-right font-bold">{moneyFormat(totalInterestValue)}</div>;
                        if (column.key === 'interestWithdrawn') children = <div className="text-right font-bold">{moneyFormat(totalInterestWithdrawnValue)}</div>;
                        if (column.key === 'commissionFee') children = <div className="text-right font-bold">{moneyFormat(totalCommissionAndRefundFee)}</div>;
                        if (column.key === 'balance') children = <div className="text-right font-bold">{moneyFormat(totalMinimumInterestValue + totalCommissionAndRefundFee)}</div>;

                        return <Table.Summary.Cell key={index} index={index}>{children}</Table.Summary.Cell>
                    })}
                </Table.Summary.Row>
            </Table.Summary>
        )
    };

    return (
        <Layout>
            <Card className="card-table-header">
                <div className="flex justify-between items-center">
                    <Space>
                        <Typography.Title level={5} className="mb-0">Trạng thái tài sản</Typography.Title>
                        <Tooltip title="Nhấn để chọn các cột thông tin">
                            <Popover
                                content={<CheckboxRender />}
                                trigger="click"
                                placement="bottomLeft"
                                overlayStyle={{width: 600}}
                            >
                                <SwapOutlined />
                            </Popover>
                        </Tooltip>
                    </Space>
                    <Space>
                        <Button size="small" className="custom-btn" onClick={() => openTransactionModal('newContract')}>Mở hợp đồng</Button>
                        <Button size="small" className="custom-btn" onClick={() => openCloseContractModal()}>Đóng hợp đồng</Button>
                        <Button size="small" type="primary" className="custom-btn ant-btn-success" onClick={() => openTransactionModal('deposit')}>Nạp tiền</Button>
                        <Button size="small" type="primary" className="custom-btn ant-btn-danger" danger onClick={() => openTransactionModal('withdrawal')}>Rút Tiền</Button>
                    </Space>
                </div>
            </Card>
            <Table
                bordered
                rowKey={(r) => r.id}
                dataSource={contracts}
                columns={visibleColumns}
                scroll={{ x: 'max-content' }}
                sticky
                summary={renderSummary}
                pagination={{ size: 'small', defaultPageSize: PAGE_SIZE_SMALL, showSizeChanger: false }}
            />
            <ContractTransactionHistoryModal
                isVisible={isContractModalVisible}
                setIsVisible={setIsContractModalVisible}
                contract={contractTransactionHistory}
                profile={lender}
                onForceReload={onForceReload}
            />
            <EditContractModal
                isVisible={isEditContractModalVisible}
                setIsVisible={setIsEditContractModalVisible}
                contract={currentContract}
                setContract={setCurrentContract}
                onSaveContract={onSaveContract}
            />
        </Layout>
    )
};

export default LenderStatusTable;