import React, { useState } from 'react';
import { Layout, Card, Collapse, Typography } from 'antd';
import { useEffectOnce } from "react-use";
import _ from 'lodash';

import { moneyFormat } from "@Services/utils";
import { renderValueWithColor } from '@Services/renderSpecial';
import api from '@Services/api';
import Loading from '@Components/Loading';
import StocksOwnedTable from '@Screens/Main/Borrowers/Detail/Asset/StocksOwnedTable';

type SummaryBorrower = {
    index: number;
    account: NSApp.Account;
    source?: NSApp.Source;
    totalBuyValue: number;
    totalMarketValue: number;
    nav1: number;
    nav2: number;
    totalExpectedProfit: number;
    portfolios: NSApp.BorrowerPortfolio[];
    avgMarginRatio: number;
}

const StocksOwnedByBorrowerTable = ({ sourceDetail }) => {
    const [borrowers, setBorrowers] = useState<SummaryBorrower[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchSummaryBorrowers = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`source/summary-borrower/${sourceDetail.id}`);

        setIsFetching(false);

        if (err) {
            return;
        }

        const borrowers = resp.data;

        setBorrowers(borrowers);
    };

    useEffectOnce(() => {
        fetchSummaryBorrowers();
    });

    if (isFetching) {
        return <Loading/>
    }

    if (!borrowers.length) {
        return <Layout/>
    }

    return (
        <Layout className="mb-4">
            <Card className="card-table-header">
                <Typography.Title level={5} className="mb-0">Bảng cổ phiếu sở hữu theo người vay</Typography.Title>
            </Card>
            <Collapse defaultActiveKey={[borrowers[0].account.profileId]} className="collapse-no-padding-collapse header-align-center">
                {borrowers.map((borrower) => {
                    const { portfolios } = borrower;
                    const _sumTotalBuyValue = _.sumBy(portfolios, 'buyValue');
                    const _sumTotalMarketValue = _.sumBy(portfolios, 'currentValue');
                    const _sumTotalChangeValue = _.sumBy(portfolios, 'changeValue');
                    const _sumTotalChangeValueRatio = ( _sumTotalChangeValue / _sumTotalBuyValue ) * 100;

                    return <Collapse.Panel
                        key={borrower.account.profileId}
                        className="collapse-no-padding-panel"
                        header={<span className="font-bold">{borrower.account.profile.fullname} - ID: {borrower.account.profile.shortId}</span>}
                        extra={
                            <div className="flex">
                                <div className="flex flex-col w-64 text-right">
                                    <span className="text-gray-500 text-xs">Giá trị mua</span>
                                    <span className="font-bold">{_sumTotalBuyValue ? moneyFormat(_sumTotalBuyValue) : '0'}</span>
                                </div>
                                <div className="flex flex-col w-64 text-right">
                                    <span className="text-gray-500 text-xs">Giá trị thị trường</span>
                                    <span className="font-bold">{_sumTotalMarketValue ? moneyFormat(_sumTotalMarketValue) : '0'}</span>
                                </div>
                                <div className="flex flex-col w-64 text-right">
                                    <span className="text-gray-500 text-xs">Lãi/lỗ</span>
                                    <span className="font-bold">{renderValueWithColor(_sumTotalChangeValue)}</span>
                                </div>
                                <div className="flex flex-col w-48 text-right">
                                    <span className="text-gray-500 text-xs">% Lãi/lỗ</span>
                                    <span className="font-bold">{renderValueWithColor(_sumTotalChangeValueRatio, 'percent')}</span>
                                </div>
                            </div>
                        }
                    >
                        <StocksOwnedTable account={borrower.account} sourceId={sourceDetail.id} />
                    </Collapse.Panel>
                })}
            </Collapse>
        </Layout>
    );
};

export default StocksOwnedByBorrowerTable;