import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import _ from 'lodash';
import { Layout, Typography, Button, Collapse, Input, Table, Upload, Popover, Tag, Col, Row, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import type { UploadProps } from 'antd';
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";

import localStorage from "@Services/local-storage";
import { moneyFormat, toRoute } from "@Services/utils";
import { DATE_FORMAT, API_BASE_URL, PAGE_SIZE_LARGE } from "@Configs";
import routes from "@Routes";
import api from '@Services/api';

const { Search } = Input;

const Settings = () => {
    const accessToken = localStorage.get('accessToken');
	const navigate = useNavigate();

    const [stockList, setStockList] = useState<NSApp.Stock[]>([]);
    const [stockListFiltered, setStockListFiltered] = useState<NSApp.Stock[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);


    const fetchStockList = async () => {
        const [err, resp]: any = await api.get(`stock/all`, {});

        if (err) {
            return;
        }

        const stocks = resp.data.stocks;

        setStockList(stocks);
        setStockListFiltered(stocks)
        setIsLoading(false);
    }
    useEffectOnce(() => {
        fetchStockList();
    });

    const columns: ColumnsType<any> = [
        {
            title: 'Mã cổ phiếu',
            dataIndex: 'code',
            key: 'code',
            render: (value, record) => {
                return (
                    <Link to={toRoute(routes.SETTINGS_UPDATE_STOCK, { id: record.id })}>{value.toUpperCase()}</Link>
                )
            }
        },
        {
            title: 'Tỉ lệ margin',
            dataIndex: 'marginRatio',
            key: 'marginRatio',
            align: 'right',
            render: (value) => `${value}%`
        },
        {
            title: 'Room phát vay (CP)',
            dataIndex: 'loanRoom',
            key: 'loanRoom',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Giá chặn trên',
            dataIndex: 'upperLimitPrice',
            key: 'upperLimitPrice',
            align: 'right',
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (value) => {
                return <>
                    {value === 'active' ?
                        <Tag color="success">Đang hoạt động</Tag>
                        :
                        <Tag color="error">Không hoạt động</Tag>
                    }
                </>;
            },
        },
        {
            title: 'Ngày cập nhật',
            dataIndex: 'updatedAt',
            key: 'updateDate',
            align: 'right',
            render: (value) => moment(value).format(DATE_FORMAT),
        },
    ];

    const filterStock = (searchKey: string) => {
        const _filteredList = _.filter(stockList, (s) => {
            return s.code.startsWith(searchKey.toLowerCase());
        });

        setStockListFiltered(_filteredList);
    };

    const uploadUrl = new URL('stock/import', API_BASE_URL);
    const props: UploadProps = {
        name: 'file',
        action: uploadUrl.href,
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
        onChange(info) {
            if (info.file.status === 'uploading') {
                setIsLoading(true);
            } else {
                setIsLoading(false);
                if (info.file.status === 'done') {
                    const response = info.file.response;
                    const newStockList = response;
                    setStockList(newStockList);
                    message.success(`Import ${info.file.name} thành công`);
                } else if (info.file.status === 'error') {
                    const messageStr = info.file.response.message;

                    message.error(`Import ${info.file.name} thất bại.`);
                    message.error(messageStr);
                }
            }
        },
    };

    return (
        <Layout className="p-4">
            <Collapse defaultActiveKey={['1']}>
                    <Collapse.Panel
                        key="1"
                        collapsible="header"
                        header={<Typography.Title level={5} className="mb-0">Tỉ lệ margin mã cổ phiếu</Typography.Title>}
                        extra={
                            <>
                                <Popover
                                    placement="leftTop"
                                    title="Lưu ý khi import"
                                    content={
                                        <div>
                                            <div>1. Sử dụng template mẫu</div>
                                            <div>2. Chỉ dùng file <strong>.csv</strong></div>
                                            <div>3. Format dữ liệu: <strong>Number</strong></div>
                                        </div>
                                    }
                                >
                                    <InfoCircleOutlined className="mr-2" />
                                </Popover>
                                <Upload {...props}><Button size="small" type="primary">Import</Button></Upload>
                            </>
                        }
                    >
                        <Row>
                            <Col span={4}>
                                <Search
                                    placeholder="input search text"
                                    onSearch={(value) => {
                                        filterStock(value);
                                    }}
                                    style={{ width: 200 }}
                                />
                            </Col>
                            <Col span={3}>
                                <Button
                                    size="small"
                                    type="primary"
                                    onClick={() => {
                                        return navigate(routes.SETTINGS_ADD_STOCK);
                                    }}
                                >
                                    Thêm mã cổ phiếu
                                </Button>
                            </Col>
                        </Row>

                        <p>Tổng cộng mã cổ phiếu ({stockList.length})</p>

                        <Table
                            bordered
                            loading={isLoading}
                            rowKey={(r: any) => r.id}
                            columns={columns}
                            dataSource={stockListFiltered}
                            pagination={{ size: 'small', defaultPageSize: PAGE_SIZE_LARGE }}
                        />
                    </Collapse.Panel>
                </Collapse>
        </Layout>
    );
}

export default Settings;