import React, { useReducer } from "react";
import reducer from './reducer';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import routes from "@Routes";

import LoginPage from "@Screens/Auth/Login";
import ForgotPasswordPage from "@Screens/Auth/ForgotPassword";
import ResetPasswordPage from "@Screens/Auth/ResetPassword";
import RegisterPage from "@Screens/Auth/Register";
import RequireAuth from "@Components/RequireAuth";
import LockAccountPage from "@Screens/Auth/LockAccount";

import Homepage from "@Screens/Main/Home";
import { AuthContext } from "@Contexts/Auth";
import StocksProvider from "./contexts/StocksProvider";

const App = () => {
    const initialAuthState = {
        currentUser: null,
    };

    const [authState, setAuthState] = useReducer(reducer, initialAuthState);

    return (
        <AuthContext.Provider value={{authState, setAuthState}}>
            <StocksProvider>
                <BrowserRouter>
                    <Routes>
                        <Route element={<LoginPage />} path={routes.LOGIN} />
                        <Route element={<ForgotPasswordPage />} path={routes.FORGOT_PASSWORD} />
                        <Route element={<ResetPasswordPage />} path={routes.RESET_PASSWORD} />
                        <Route element={<RegisterPage />} path={routes.REGISTER} />
                        <Route element={<LockAccountPage />} path={routes.USER_LOCK_ACCOUNT} />
                        <Route path="*" element={<RequireAuth><Homepage /></RequireAuth>}/>
                    </Routes>
                </BrowserRouter>
            </StocksProvider>
        </AuthContext.Provider>
    );
}

export default App;
