import React, { useState, useEffect } from "react";
import { Modal, Table, Typography, notification } from "antd";
import moment from "moment";
import _ from "lodash";

import { DATE_FORMAT, PAGE_SIZE_NORMAL } from "@Configs";
import { moneyFormat } from "@Services/utils";
import api from '@Services/api';
import EditableTable from "@Components/EditableTable";

const ContractTransactionHistory = ({ isVisible, setIsVisible, contract, profile, onForceReload }) => {
    const [transactions, setTransactions] = useState([]);
    const [contractValue, setContractValue] = useState(0);

    useEffect(() => {
        if (contract) {
            const _transactions = contract.transactions.map(transaction => {
                return {
                    ...transaction,
                    date: moment(transaction.date)
                }
            });

            const sortedTransaction = _.orderBy(_transactions, function(transaction) {
                return moment(transaction.date);
            }, ['desc']);

            setTransactions(sortedTransaction);

            setContractValue(contract?.contractValue);
        }
    }, [contract]);

    const onSaveTransaction = async (id, values) => {
        const [err, resp]: any = await api.post(`contract/transactions/${id}`, {
            ...values
        });

        if (err) {
            notification.error({
                message: "Cập nhật thất bại!"
            });
            return;
        }

        const transaction = resp.data.contractTransaction;
        const _transactions: any = transactions.map((item: any) => {
            if (item.id === transaction.id) {
                transaction.date = moment(transaction.date)
                return transaction;
            }
            return item;
        });

        const sortedTransaction = _.orderBy(_transactions, function(transaction) {
            return moment(transaction.date);
        }, ['desc']);

        setTransactions(sortedTransaction);
        onForceReload();
        notification.success({
            message: "Cập nhật thành công!"
        });
    }

    const onDeleteTransaction = async (id) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.delete(`contract/transactions/${id}`);

        if (err) {
            notification.error({
                message: "Xóa thất bại!"
            });

            if (err?.response?.data?.message) {
                notification.error({
                    message: err.response.data.message
                });
            }

            if (err?.response?.data?.errors) {
                notification.error({
                    message: err?.response?.data.errors[0].msg
                });
            }

            return;
        }

        const _transactions: any = transactions.filter((item: any) => item.id !== id);
        setTransactions(_transactions);
        onForceReload();
        notification.success({
            message: "Xóa thành công!"
        });
    }

    const onCancelModal = () => {
        setIsVisible(false);
    }

    const columns = [
        {
            title: 'Ngày',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            editable: true,
            inputType: 'date',
            // inputWidth: 120,
            render: (value) => moment(value).format(DATE_FORMAT),
        },
        {
            title: 'Loại giao dịch',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            // editable: false,
            // inputType: 'select',
            // inputWidth: 150,
            // inputValue: [
            //     { value: 'deposit', label: 'Nạp tiền' },
            //     { value: 'withdrawal', label: 'Rút tiền' },
            // ],
            render: (value) => {
                if (value === 'deposit') return 'Nạp tiền';
                if (value === 'withdrawal') return 'Rút tiền';
            },
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            editable: true,
            inputType: 'number',
            inputWidth: 170,
            render: (value, record) => record.type === 'deposit' ? moneyFormat(value) : `-${moneyFormat(value)}`,
        },
        {
            title: 'Nội dung',
            dataIndex: 'note',
            key: 'note',
            editable: true,
            inputType: 'input',
        },
    ];

    return (
        <Modal
            centered
            title="Chi tiết nạp rút hợp đồng"
            visible={isVisible}
            onCancel={onCancelModal}
            destroyOnClose={true}
            footer={null}
            width={1000}
        >
            <div className="flex justify-between items-center">
                <Typography.Text>Số hợp đồng: <strong>{contract?.contractNo}</strong></Typography.Text>
                <Typography.Text>Kho: <strong>{contract?.source?.sourceCode}</strong></Typography.Text>
                <Typography.Text>Tên: <strong>{profile.fullname}</strong></Typography.Text>
                <Typography.Text>ID: <strong>{profile.shortId}</strong></Typography.Text>
            </div>
            <Typography.Title level={5} className="text-center mt-6 mb-4">Lịch sử Nạp/Rút Tiền</Typography.Title>
            <EditableTable
                bordered
                rowKey="id"
                columns={columns}
                dataSource={transactions}
                summary={() => !!transactions.length &&  (
                    <Table.Summary>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} className="font-bold">Tổng</Table.Summary.Cell>
                            <Table.Summary.Cell index={3} className="text-right font-bold">{moneyFormat(contractValue)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={4}></Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
                pagination={{ size: 'small', defaultPageSize: PAGE_SIZE_NORMAL }}
                onSave={onSaveTransaction}
                onDelete={onDeleteTransaction}
            />
        </Modal>
    )
};

export default ContractTransactionHistory;