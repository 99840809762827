import React, { useState, useEffect } from "react";
import { Layout, Space, Button, Typography, Tabs, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";

import { toRoute } from "@Services/utils";
import api from "@Services/api";
import routes from "@Routes";

import BackButton from "@Components/BackButton";
import Loading from "@Components/Loading";
import AssetStatusTable from "./StatusTable";
import StocksOwnedTable from "./StocksOwnedTable";
import StocksOwnedTab from "./Tab/StocksOwned";
import SoldStocksTab from "./Tab/SoldStocks";
import OrderBookTab from "./Tab/OrderBook";
import NavTab from "./Tab/Nav";
import TransactionModal from "./TransactionModal";
import TradeModal from "./TradeModal";

const BorrowerDetailAsset = () => {
    const navigate = useNavigate();
    const location = useLocation() as any;
    const { id } = useParams() as any;

    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isTransactionModalVisible, setIsTransactionModalVisible] = useState<boolean>(false);
    const [isTradeModalVisible, setIsTradeModalVisible] = useState<boolean>(false);
    const [transactionType, setTransactionType] = useState<string>('deposit');
    const [transactionData, setTransactionData] = useState<any>();

    const [tradeType, setTradeType] = useState<string>('buy');

    const [borrower, setBorrower] = useState<NSApp.Borrower>();
    const [account, setAccount] = useState<NSApp.Account>();

    const [assetStatus, setAssetStatus] = useState<NSApp.AssetStatus>();

    const [stocks, setStocks] = useState([]);
    const [sources, setSources] = useState([]);
    const [activeSources, setActiveSources] = useState([]);

    const [forceReload, setForceReload] = useState(0);

    const fetchDetail = async () => {
        const [err, resp]: any = await api.get(`borrower/detail/${id}`, {});

        if (err) {
            notification.error({
                message: "Không tìm thấy người vay!"
            });
            return navigate(routes.BORROWERS);
        }

        if (!resp?.data?.borrower) {
            notification.error({
                message: "Không tìm thấy người vay!"
            });
            return navigate(routes.BORROWERS);
        }

        setBorrower(resp.data.borrower);
        setAccount(resp.data.account);
    }

    const fetchStocks = async () => {
        const [err, resp]: any = await api.get(`stock/list`, {});

        if (err) {
            return;
        }

        const stocks = resp.data.stocks;

        setStocks(stocks);
    }

    const fetchSources = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get(`source/list`, {
            page: 1,
            pageSize: 100
        });

        if (resp) {
            setSources(resp.data.sources);
        };
    }

    const fetchActiveSources = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get(`source/list-active`, {
            page: 1,
            pageSize: 10000
        });

        if (resp) {
            setActiveSources(resp.data.sources);
        };
    }

    const updateAccount = (account: NSApp.Account) => setAccount(account);

    const fetchData = async () => {
        setIsFetching(true);
        await Promise.all([fetchDetail(), fetchStocks(), fetchSources(), fetchActiveSources()]);
        setIsFetching(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const increaseForceReload = () => setForceReload(forceReload + 1);

    if (isFetching) {
        return <Loading />;
    }

    if (!borrower || !account) {
        return <Layout />;
    }

    return (
        <Layout>
            <Layout className="flex-row justify-between items-center bg-gray p-4">
                <Space size="large" className="mr-6">
                    <BackButton />
                    <span>Tên: <strong>{borrower.fullname}</strong></span>
                    <span>ID: <strong>{borrower.shortId}</strong></span>
                </Space>
                <Space>
                    <Button className='custom-btn-tab active'>Tài sản</Button>
                    <Link to={toRoute(routes.BORROWER_DETAIL_INFO, { id })}><Button className='custom-btn-tab'>Thông tin cơ bản</Button></Link>
                </Space>
                <div/>
            </Layout>
            <Layout className="px-4 py-2">
                <Layout className="flex-row justify-between items-center mb-4">
                    <Typography.Title level={4} className="uppercase font-bold mb-0">Quản lý danh mục tài sản</Typography.Title>
                    <div className="flex flex-col mr-4 text-gray-500 hover:text-gray-800 cursor-pointer">
                        <UploadOutlined className="text-lg" />
                        <span className="text-xs">Export</span>
                    </div>
                </Layout>

                {/* Asset Status */}
                <AssetStatusTable account={account} setAssetStatus={setAssetStatus} forceReload={forceReload} setIsTransactionModalVisible={setIsTransactionModalVisible} setTransactionType={setTransactionType} />

                {/* Stocks Owned */}
                <StocksOwnedTable hasAction account={account} forceReload={forceReload} setIsTradeModalVisible={setIsTradeModalVisible} setTradeType={setTradeType} />

                <Tabs
                    defaultActiveKey={location?.state?.tabKey ? location.state.tabKey : "stocks-owned"}
                    tabBarStyle={{ backgroundColor: '#fff', paddingLeft: 10, marginBottom: 8 }}
                >
                    <Tabs.TabPane tab="Cổ phiếu đang sở hữu" key="stocks-owned">
                        <StocksOwnedTab
                            account={account}
                            setIsTradeModalVisible={setIsTradeModalVisible}
                            setTradeType={setTradeType}
                            setTransactionData={setTransactionData}
                            forceReload={forceReload}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Cổ phiếu đã bán" key="sold-stocks">
                        <SoldStocksTab forceReload={forceReload} account={account} increaseForceReload={increaseForceReload} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Sổ lệnh" key="order-book">
                        <OrderBookTab forceReload={forceReload} account={account} stocks={stocks} sources={activeSources} increaseForceReload={increaseForceReload} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="NAV1" key="nav1">
                        <NavTab account={account} updateAccount={updateAccount} />
                    </Tabs.TabPane>
                </Tabs>

                <TransactionModal
                    profile={borrower}
                    account={account}
                    updateAccount={updateAccount}
                    isVisible={isTransactionModalVisible}
                    setIsVisible={setIsTransactionModalVisible}
                    type={transactionType}
                    setType={setIsTransactionModalVisible}
                    increaseForceReload={increaseForceReload}
                />

                <TradeModal
                    initialValues={transactionData}
                    forceReload={forceReload}
                    assetStatus={assetStatus}
                    account={account}
                    sources={activeSources}
                    increaseForceReload={increaseForceReload}
                    stocks={stocks}
                    profile={borrower}
                    isVisible={isTradeModalVisible}
                    setIsVisible={setIsTradeModalVisible}
                    type={tradeType}
                    setType={setTradeType}
                    setTransactionData={setTransactionData}
                />
            </Layout>
        </Layout>
    );
};

export default BorrowerDetailAsset;