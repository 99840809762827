import React, { useState, useEffect } from "react";
import { Layout, Space, Button, Typography, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useEffectOnce } from "react-use";
import moment from "moment";

import api from "@Services/api";
import { toRoute } from "@Services/utils";
import routes from "@Routes";
import { useAuthState } from "@Contexts/Auth";

import AccessDenied from "@Components/AccessDenied";
import Loading from "@Components/Loading";
import BackButton from "@Components/BackButton";
import TransactionModal from "./TransactionModal";
import AssetStatusTable from "./StatusTable";
import AssetAndMarginTable from "./AssetAndMarginTable";
import FeeAndProfitTable from "./FeeAndProfitTable";
import RefundAndCommissionTable from "./RefundAndCommissionTable";
import RefundAndCommissionModal from "./RefundAndCommissionModal";
import CloseContractModal from "./CloseContractModal";

const LenderDetailAsset = () => {
    const navigate = useNavigate();
    const { id } = useParams() as any;
    let { authState } = useAuthState() as any;
    let { currentUser } = authState;

    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [lender, setLender] = useState<NSApp.Lender>();
    const [contracts, setContracts] = useState<NSApp.Contract[]>([]);
    const [totalContractValue, setTotalContractValue] = useState<number>(0);
    const [totalInterestValue, setTotalInterestValue] = useState<number>(0);
    const [totalCommissionAndRefundFee, setTotalCommissionAndRefundFee] = useState<number>(0);
    const [totalMinimumInterestValue, setTotalMinimumInterestValue] = useState<number>(0);
    const [totalInterestWithdrawnValue, setInterestWithdrawnValue] = useState<number>(0);
    const [isTransactionModalVisible, setIsTransactionModalVisible] = useState<boolean>(false);
    const [isCloseContractModalVisible, setIsCloseContractModalVisible] = useState<boolean>(false);
    const [isCommissionModalVisible, setIsCommissionModalVisible] = useState<boolean>(false);
    const [transactionType, setTransactionType] = useState<string>('');
    const [sources, setSources] = useState([]);

    const [forceReload, setForceReload] = useState<number>(0);

    const fetchDetail = async () => {
        const [err, resp]: any = await api.get(`lender/detail/${id}`, {});

        if (err) {
            notification.error({
                message: "Không tìm thấy người cho vay!"
            });
            return navigate(routes.LENDERS_ASSET);
        }

        if (!resp?.data?.lender) {
            notification.error({
                message: "Không tìm thấy người cho vay!"
            });
            return navigate(routes.LENDERS_ASSET);
        }

        setLender(resp.data.lender);
    }

    const fetchContracts = async (status) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get('contract/list', {
            profileId: id,
            status
        });

        const { contracts, totalContractValue, totalInterestValue, totalMinimumInterestValue, totalCommissionAndRefundFee } = resp.data;

        const _contracts = contracts.map(contract => {
            if (contract.startDate) {
                contract.startDate = moment(contract.startDate)
            }

            if (contract.expireDate) {
                contract.expireDate = moment(contract.expireDate)
            }

            if (contract.latestInterestPaymentDate) {
                contract.latestInterestPaymentDate = moment(contract.latestInterestPaymentDate);
            }
            return contract;
        })
        setContracts(contracts);
        setTotalContractValue(totalContractValue);
        setTotalInterestValue(totalInterestValue);
        setTotalCommissionAndRefundFee(totalCommissionAndRefundFee);
        setTotalMinimumInterestValue(totalMinimumInterestValue);
    }

    const fetchSources = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get('source/list', {
            profileId: id,
            page: 1,
            pageSize: 100
        });

        if (resp) {
            setSources(resp.data.sources);
        }
    }

    const fetchData = async () => {
        setIsFetching(true);
        await Promise.all([fetchDetail(), fetchContracts('active'), fetchSources()]);
        setIsFetching(false);
    };

    useEffectOnce(() => {
        fetchData();
    });

    useEffect(() => {
        if (forceReload > 0) {
            fetchContracts('active');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceReload])

    const openTransactionModal = (type) => {
        setTransactionType(type)
        setIsTransactionModalVisible(true);
    };

    const openCloseContractModal = () => {
        setIsCloseContractModalVisible(true);
    }

    const openCommissionModal = () => {
        setIsCommissionModalVisible(true);
    };

    const increaseForceReload = () => setForceReload(forceReload + 1);

    if (isFetching) {
        return <Loading />;
    }

    if (!lender) {
        return <Layout />;
    }

    if (!isFetching && currentUser && !currentUser?.isSuperAdmin) {
        return <AccessDenied />
    }

    return (
        <Layout>
            <Layout className="flex-row justify-between items-center bg-gray p-4">
                <Space size="large" className="mr-6">
                    <BackButton/>
                    <span>Tên: <strong>{lender.fullname}</strong></span>
                    <span>ID: <strong>{lender.shortId}</strong></span>
                </Space>
                <Space>
                    <Button className='custom-btn-tab active'>Tài sản</Button>
                    <Link to={toRoute(routes.LENDER_DETAIL_SOURCE_MANAGER, { id })}><Button className='custom-btn-tab'>Quản lý kho</Button></Link>
                    <Link to={toRoute(routes.LENDER_DETAIL_TRANSACTION_HISTORY, { id })}><Button className='custom-btn-tab'>Lịch sử giao dịch</Button></Link>
                    <Link to={toRoute(routes.LENDER_DETAIL_INFO, { id })}><Button className='custom-btn-tab'>Thông tin cơ bản</Button></Link>
                </Space>
                <div/>
            </Layout>
            <Layout className="px-4 py-2">
                <div className="flex justify-between items-center mb-1">
                    <Typography.Title level={4} className="uppercase font-bold mb-0">Quản lý danh mục tài sản</Typography.Title>
                    <div className="flex flex-col mr-4 text-gray-500 hover:text-gray-800 cursor-pointer">
                        <UploadOutlined className="text-lg" />
                        <span className="text-xs">Export</span>
                    </div>
                </div>

                <AssetStatusTable
                    lender={lender}
                    openTransactionModal={openTransactionModal}
                    openCloseContractModal={openCloseContractModal}
                    contracts={contracts}
                    setContracts={setContracts}
                    totalContractValue={totalContractValue}
                    setTotalContractValue={setTotalContractValue}
                    totalInterestValue={totalInterestValue}
                    setTotalInterestValue={setTotalInterestValue}
                    totalInterestWithdrawnValue={totalInterestWithdrawnValue}
                    totalCommissionAndRefundFee={totalCommissionAndRefundFee}
                    totalMinimumInterestValue={totalMinimumInterestValue}
                    onForceReload={() => {
                        increaseForceReload();
                    }}
                />

                <AssetAndMarginTable lender={lender}/>

                <FeeAndProfitTable lender={lender}/>

                <RefundAndCommissionTable
                    profile={lender}
                    openCommissionModal={openCommissionModal}
                    forceReload={forceReload}
                />
            </Layout>
            <TransactionModal
                sources={sources}
                profile={lender}
                isVisible={isTransactionModalVisible}
                setIsVisible={setIsTransactionModalVisible}
                type={transactionType}
                contracts={contracts}
                setContracts={setContracts}
                onSubmit={() => {
                    increaseForceReload();
                }}
            />
            <CloseContractModal
                profile={lender}
                isVisible={isCloseContractModalVisible}
                setIsVisible={setIsCloseContractModalVisible}
                contracts={contracts}
                setContracts={setContracts}
                onSubmit={() => {
                    increaseForceReload();
                }}
            />
            <RefundAndCommissionModal
                profile={lender}
                isVisible={isCommissionModalVisible}
                setIsVisible={setIsCommissionModalVisible}
                contracts={contracts}
                onSubmit={() => {
                    increaseForceReload();
                }}
            />
        </Layout>
    );
};

export default LenderDetailAsset;