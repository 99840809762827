import React, { useState, useEffect } from "react";
import { Layout, Space, Button, Typography, Table, Form, DatePicker, Select, Input, notification } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import moment from "moment";

import api from "@Services/api";
import { moneyFormat, useReducedState } from "@Services/utils";
import routes from "@Routes";
import { PAGE_SIZE_NORMAL, DATE_FORMAT, DATE_TIME_FORMAT } from "@Configs";

const { Option } = Select;

const HistoryStockTransaction = () => {
    const [transactions, setTransactions] = useState<any>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [form] = Form.useForm();
    const [status, setStatus] = useState<any>();
    const [typeOrder, setTypeOrder] = useState<any>();
    const [searchKey, setSearchKey] = useState<any>();
    const [typeSearch, setTypeSearch] = useState<any>();

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_NORMAL,
        total: 0
    });

    const fetchTransactions = async () => {
        setIsFetching(true);

        const _startDate = startDate ? moment(startDate).format(DATE_FORMAT) : '';
        const _endDate = endDate ? moment(endDate).format(DATE_FORMAT) : '';
        const _status = status === "all" ? null : status;
        const _type = typeOrder === "all" ? null : typeOrder;
        
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [err, resp]: any = await api.get(`order-book/list`, {
            listStatus: _status,
            startDate: _startDate,
            endDate: _endDate,
            page: pagination.current,
            pageSize: PAGE_SIZE_NORMAL,
            type: _type,
            [typeSearch]: searchKey,
        });
        
        if (err) {
            notification.error({
                message: err?.response?.data?.message
            });
            return;
        }

        if (resp) {
            const _transactions = resp.data.orderBooks.map((transaction: any) => {
                transaction.date = moment(transaction.date);
                return transaction;
            });

            setTransactions(_transactions);
            setPagination({
                ...pagination,
                total: resp.data.total
            });
        }

        setIsFetching(false);
    };

    useEffect(() => {
        fetchTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current, startDate, endDate, typeOrder, searchKey, typeSearch, status]);

    const onFinishFilter = (values) => {
        const { date, type, typeSearch, status } = values;
        if (date) {
            setStartDate(date[0]);
            setEndDate(date[1]);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
        if (type) {
            setTypeOrder(type)
        } 

        if (typeSearch) {
            setTypeSearch(typeSearch)
        }

        if (status) {
            setStatus(status)
        }

        setPagination({ current: 1 });
    };

    const onSearch = (value: any) => {
        setPagination({current: 1});
        setSearchKey(value);
    }

    const resetFilter = () => {
        setStartDate(null);
        setEndDate(null);
        setSearchKey('');
        setStatus("all");
        setTypeOrder("all");
        setPagination({ current: 1 });
        form.setFieldsValue({
            date: [],
            typeSearch: "fullname",
            type: "all",
            status: "all",
            transactionType: ''
        });
    }

    const columns: ColumnsType<any> = [
        {
            title: 'Họ và tên',
            dataIndex: 'account',
            key: 'fullname',
            align: 'center',
            render: (value) => value.profile?.fullname,
        },
        {
            title: 'Mã lô cổ phiếu',
            dataIndex: 'lot',
            key: 'lot',
            align: 'center',
        },
        {
            title: 'Lệnh',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (value) => {
                if (value === 'buy') {
                    return <span className="text-success font-bold">MUA</span>;
                }
                if (value === 'sell') {
                    return <span className="text-error font-bold">BÁN</span>;
                }
            },
        },
        {
            title: 'Kho',
            dataIndex: 'sourceId',
            key: 'sourceId',
            align: 'center',
            render: (value, record) => {
                return record.source ? `${record.source?.sourceCode} - ${record.source?.securitiesFullname}` : '';
            },
        },
        {
            title: 'Ngày thanh toán',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            render: (value) => value.format(DATE_TIME_FORMAT),
        },
        {
            title: 'Mã cổ phiếu',
            dataIndex: 'stockId',
            key: 'stockId',
            align: 'center',
            render: (value, record) => <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{record.stockCode.toUpperCase()}</span>,
        },
        {
            title: 'Giá',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (value, record) => value ? <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{moneyFormat(value)}</span> : '-',
        },
        {
            title: 'Khối lượng',
            dataIndex: 'volume',
            key: 'volume',
            align: 'right',
            render: (value, record) => value ? <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{moneyFormat(value)}</span> : '-',
        },
        {
            title: 'Giá khớp TB',
            dataIndex: 'matchedPrice',
            key: 'matchedPrice',
            align: 'right',
            render: (value, record) => value ? <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{moneyFormat(value)}</span> : '-',
        },
        {
            title: 'Khối lượng khớp',
            dataIndex: 'matchedVolume',
            key: 'matchedVolume',
            align: 'right',
            render: (value, record) => value ? <span className={`font-bold text-${record.type === 'buy' ? 'success' : 'error'}`}>{moneyFormat(value)}</span> : '-',
        },
        {
            title: 'Tình trạng',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (value, record) => {
                let _status = '';
                let _subStatus = '';

                if (record.userStatus === 'waiting-cancel') {
                    _subStatus = "(Chờ huỷ)";
                }
                if (value === 'waiting') {
                    _status = "Chờ đặt lệnh";
                }
                if (value === 'ordered') {
                    _status = "Chờ khớp";
                }
                if (value === 'partial-matched') {
                    _status = "Khớp một phần"
                }
                if (value === 'matched' && record.matchedVolume === record.volume) {
                    _status = "Khớp hết";
                }
                if (value === 'matched' && record.matchedVolume !== record.volume) {
                    _status = "Khớp một phần";
                }
                if (value === 'canceled') {
                    _status = "Đã huỷ lệnh";
                }
                return <div>{_status} {_subStatus && <span className="text-error font-bold">{_subStatus}</span>}</div>
            },
        },
    ];

    return (
        <Layout>
            <Layout className="bg-gray p-4">
                <Space>
                    <Link to={routes.HISTORY_MONEY_TRANSACTIONS}><Button className='custom-btn-tab'>Nạp rút tiền</Button></Link>
                    <Button className='custom-btn-tab active'>Giao dịch cổ phiếu</Button>
                    <Link to={routes.HISTORY_OPERATIONAL_EFFICIENCY}><Button className='custom-btn-tab'>Hiệu quả hoạt động</Button></Link>
                </Space>
            </Layout>
            <Layout className="px-4 pt-4">
                <Typography.Title level={4} className="uppercase font-bold mb-4">Lịch sử giao dịch cổ phiếu</Typography.Title>
                <Typography.Text><Typography.Link onClick={resetFilter}>Tất cả giao dịch</Typography.Link> ({pagination.total})</Typography.Text>
                <Layout className="flex-row mt-2 mb-5 justify-between items-between ">
                    <Form
                        form={form}
                        layout="inline"
                        onFinish={onFinishFilter}
                        initialValues={{ transactionType: '', typeSearch: 'fullname', type: 'all', status: 'all'}}
                    >   
                        <Form.Item name="typeSearch" className="mr-0 w-32" >
                                <Select >
                                    <Option value="fullname">Họ và tên</Option>
                                    <Option value="source">Kho</Option>
                                    <Option value="stockCode">Mã cổ phiếu</Option>
                                </Select>
                        </Form.Item>

                        <Input.Search
                            placeholder="Nhập từ khoá"
                            style={{width: 300}} 
                            allowClear 
                            enterButton="Tìm"
                            onSearch={onSearch}
                        />

                        <Form.Item name="date" className="ml-20">
                            <DatePicker.RangePicker
                                format={DATE_FORMAT}
                                placeholder={['Từ ngày', 'Đến ngày']}
                            />
                        </Form.Item>

                        <Form.Item name="type" className="w-36">
                            <Select >
                                <Option value="all">Tất cả các lệnh</Option>
                                <Option value="buy">Mua</Option>
                                <Option value="sell">Bán</Option>
                            </Select>
                        </Form.Item> 

                        <Form.Item name="status" className="w-36">
                            <Select >
                                <Option value="all">Tất cả tình trạng</Option>
                                <Option value="matched">Khớp hết</Option>
                                <Option value="partial-matched">Khớp một phần</Option>
                                <Option value="canceled">Đã huỷ lệnh</Option>
                            </Select>
                        </Form.Item> 
                        
                        <Button htmlType="submit">Lọc</Button>
                    </Form>
                </Layout>

                <Table
                    bordered
                    rowKey={(r: any) => r.id}
                    dataSource={transactions}
                    columns={columns}
                    loading={isFetching}
                    pagination={{
                        ...pagination,
                        size: 'small',
                        showSizeChanger: false,
                        onChange: page => {
                            setPagination({ current: page });
                        },
                    }}
                />
            </Layout>
        </Layout>
    )
};

export default HistoryStockTransaction;