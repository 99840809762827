import React, { useState, useEffect } from "react";
import { Layout, Space, Button, Typography, Table, Card, Tooltip, notification } from "antd";
import { ColumnWidthOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { useEffectOnce } from "react-use";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

import api from "@Services/api";
import localStorage from "@Services/local-storage";
import { moneyFormat, useReducedState, toRoute } from "@Services/utils";
import routes from "@Routes";
import { DATE_FORMAT } from "@Configs";

const OperationalEfficiency = () => {
    const [contracts, setContracts] = useState<any>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [fullTable, setFullTable] = useState<boolean>(false);
    const [totalContractValue, setTotalContractValue] = useState<number>(0);
    const [totalMinimumInterestValue, setTotalMinimumInterestValue] = useState<number>(0);
    const [totalInterestWithDrawnValue, setTotalInterestWithDrawnValue] = useState<number>(0);
    const [totalTransactionFee, setTotalTransactionFee] = useState<number>(0);
    const [totalLoanInterest, setTotalLoanInterest] = useState<number>(0);
    const [totalRefundAndCommission, setTotalRefundAndCommission] = useState<number>(0);
    const [totalTotal, setTotalTotal] = useState<number>(0);
    const [totalSourceCommission, setTotalSourceCommission] = useState<number>(0);
    const [totalF123Commission, setTotalF123Commission] = useState<number>(0);

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        // pageSize: PAGE_SIZE_NORMAL,
        pageSize: 1000,
        total: 0
    });

    const fetchOperationalEfficiency = async () => {
        setIsFetching(true);
        const filter = {
            // page: pagination.current,
            // pageSize: PAGE_SIZE_NORMAL,
            page: 1,
            pageSize: 1000,
        };

        const [err, resp]: any = await api.get(`contract/operational-efficiency`, filter);

        if (err) {
            notification.error(err?.response?.data?.message);
            return;
        }

        let { contracts, total } = resp?.data;

        const _totalContractValue = _.sumBy(contracts, 'contractValue');
        const _totalMinimumInterestValue = _.sumBy(contracts, 'minimumInterestValue');
        const _totalInterestWithdrawn = _.sumBy(contracts, 'interestWithdrawn');
        const _totalTransactionFee = _.sumBy(contracts, 'totalTransactionFee');
        const _totalLoanInterest = _.sumBy(contracts, 'totalLoanInterest');
        const _totalRefundAndCommission = _.sumBy(contracts, 'totalRefundAndCommission');
        const _totalTotal = _.sumBy(contracts, 'totalTotal');
        const _totalSourceCommission = _.sumBy(contracts, 'sourceCommission');
        const _totalF123Commission = _.sumBy(contracts, 'f123Commission');

        setTotalContractValue(_totalContractValue);
        setTotalMinimumInterestValue(_totalMinimumInterestValue);
        setTotalInterestWithDrawnValue(_totalInterestWithdrawn);
        setTotalTransactionFee(_totalTransactionFee);
        setTotalLoanInterest(_totalLoanInterest);
        setTotalRefundAndCommission(_totalRefundAndCommission);
        setTotalTotal(_totalTotal);
        setTotalSourceCommission(_totalSourceCommission);
        setTotalF123Commission(_totalF123Commission);

        setContracts(contracts);
        setPagination({ total });
        setIsFetching(false);
    }

    useEffect(() => {
        fetchOperationalEfficiency();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current]);

    const changeTableType = () => {
        setFullTable(!fullTable);
        localStorage.set('operationalEfficiencyTable', String(!fullTable));
    };

    const initFullTable = async () => {
        let fullTableLocalStorage = await localStorage.get('operationalEfficiencyTable');

        if (fullTableLocalStorage == null || fullTableLocalStorage === undefined) {
            setFullTable(true);
        } else {
            setFullTable(fullTableLocalStorage);
        }
    };

    useEffectOnce(() => {
        initFullTable();
    });

    let columns: ColumnsType<any> = [
        {
            title: 'Người cho vay',
            dataIndex: 'source',
            key: 'securitiesFullname',
            // width: fullTable ? 130 : undefined,
            width: 180,
            render: (value) => value?.securitiesFullname,
            fixed: 'left',
        },
        {
            title: 'Số HĐ',
            dataIndex: 'contractNo',
            key: 'contractNo',
            // width: fullTable ? 130 : undefined,
            width: 150,
            render: (value, record) => <Link className="font-bold" to={toRoute(routes.LENDER_DETAIL_ASSET, { id: record.source?.profileId })}>
                {value}
            </Link>,
            fixed: 'left',
        },
        {
            title: 'Kho',
            dataIndex: 'source',
            key: 'source',
            // width: fullTable ? 130 : undefined,
            width: 150,
            render: (value) => <Link className="font-bold" to={toRoute(routes.SOURCE_DETAIL, { id: value?.id })}>
                {value?.sourceCode}
            </Link>,
            fixed: 'left',
        },
        {
            title: 'Tổng tiền nạp/rút',
            dataIndex: 'contractValue',
            key: 'contractValue',
            align: 'right',
            // width: fullTable ? 180 : undefined,
            width: 180,
        },
        {
            title: 'NAV TB',
            dataIndex: 'averageNAV',
            key: 'averageNAV',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 180,
            render: (value) => value ? moneyFormat(value) : 0,
        },
        {
            title: 'Ngày tạo HĐ',
            dataIndex: 'startDate',
            key: 'startDate',
            align: 'center',
            // width: fullTable ? 150 : undefined,
            width: 150,
            render: (value) => moment(value).format(DATE_FORMAT),
        },
        {
            title: 'Ngày hết HĐ',
            dataIndex: 'expireDate',
            key: 'expireDate',
            align: 'center',
            // width: fullTable ? 150 : undefined,
            width: 150,
            render: (value) => moment(value).format(DATE_FORMAT),
        },
        {
            title: 'Lãi tối thiểu',
            dataIndex: 'minimumInterestValue',
            key: 'minimumInterestValue',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value),
        },
        {
            title: 'Tổng HH phí giao dịch',
            dataIndex: 'totalTransactionFee',
            key: 'totalTransactionFee',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Lãi cho vay',
            dataIndex: 'totalLoanInterest',
            key: 'totalLoanInterest',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Lãi margin CTCK và hoàn phí',
            dataIndex: 'totalRefundAndCommission',
            key: 'totalRefundAndCommission',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 160,
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Tổng thu',
            dataIndex: 'totalTotal',
            key: 'totalTotal',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Kho hưởng',
            dataIndex: 'sourceCommission',
            key: 'source',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value)
        },
        {
            title: 'F123 hưởng',
            dataIndex: 'f123Commission',
            key: 'f123',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value)
        },
    ];

    const fullColumns: ColumnsType<any> = [
        {
            title: 'Người cho vay',
            dataIndex: 'source',
            key: 'securitiesFullname',
            // width: fullTable ? 130 : undefined,
            width: 180,
            render: (value) => value?.securitiesFullname,
            fixed: 'left',
        },
        {
            title: 'Số HĐ',
            dataIndex: 'contractNo',
            key: 'contractNo',
            // width: fullTable ? 130 : undefined,
            width: 150,
            render: (value, record) => <Link className="font-bold" to={toRoute(routes.LENDER_DETAIL_ASSET, { id: record.source?.profileId })}>
                {value}
            </Link>,
            fixed: 'left',
        },
        {
            title: 'Kho',
            dataIndex: 'source',
            key: 'source',
            // width: fullTable ? 130 : undefined,
            width: 150,
            render: (value) => <Link className="font-bold" to={toRoute(routes.SOURCE_DETAIL, { id: value?.id })}>
                {value?.sourceCode}
            </Link>,
            fixed: 'left',
        },
        {
            title: 'Tổng tiền nạp/rút',
            dataIndex: 'contractValue',
            key: 'contractValue',
            align: 'right',
            // width: fullTable ? 180 : undefined,
            width: 180,
            render: (value) => value ? moneyFormat(value) : 0,
        },
        {
            title: 'NAV TB',
            dataIndex: 'averageNAV',
            key: 'averageNAV',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 180,
            render: (value) => value ? moneyFormat(value) : 0,
        },
        {
            title: 'Ngày tạo HĐ',
            dataIndex: 'startDate',
            key: 'startDate',
            align: 'center',
            // width: fullTable ? 150 : undefined,
            width: 150,
            render: (value) => moment(value).format(DATE_FORMAT),
        },
        {
            title: 'Ngày hết HĐ',
            dataIndex: 'expireDate',
            key: 'expireDate',
            align: 'center',
            // width: fullTable ? 150 : undefined,
            width: 150,
            render: (value) => moment(value).format(DATE_FORMAT),
        },
        {
            title: 'Kỳ rút lãi',
            dataIndex: 'interestPeriod',
            key: 'interestPeriod',
            align: 'center',
            // width: fullTable ? 100 : undefined,
            width: 100,
            render: (value) => `${value} tháng`
        },
        {
            title: 'Ngày rút lãi',
            dataIndex: 'latestInterestPaymentDate',
            key: 'latestInterestPaymentDate',
            align: 'center',
            // width: fullTable ? 120 : undefined,
            width: 120,
            render: (value) => value ? moment(value).format(DATE_FORMAT) : "Chưa rút",
        },
        {
            title: 'Lãi suất/ngày',
            dataIndex: 'interest',
            key: 'interest',
            align: 'right',
            // width: fullTable ? 120 : undefined,
            width: 120,
            render: (value) => `${value}%`
        },
        {
            title: 'Lãi tối thiểu',
            dataIndex: 'minimumInterestValue',
            key: 'minimumInterestValue',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value),
        },
        {
            title: 'Lãi đến hạn đã rút',
            dataIndex: 'interestWithdrawn',
            key: 'interestWithdrawn',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value),
        },
        {
            title: 'Tổng HH phí giao dịch',
            dataIndex: 'totalTransactionFee',
            key: 'totalTransactionFee',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Lãi cho vay',
            dataIndex: 'totalLoanInterest',
            key: 'totalLoanInterest',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Lãi margin CTCK và hoàn phí',
            dataIndex: 'totalRefundAndCommission',
            key: 'totalRefundAndCommission',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 160,
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Tổng thu',
            dataIndex: 'totalTotal',
            key: 'totalTotal',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value)
        },
        {
            title: 'Kho hưởng',
            dataIndex: 'sourceCommission',
            key: 'source',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value)
        },
        {
            title: 'F123 hưởng',
            dataIndex: 'f123Commission',
            key: 'f123',
            align: 'right',
            // width: fullTable ? 170 : undefined,
            width: 170,
            render: (value) => moneyFormat(value)
        },
    ];

    if (fullTable) {
        columns = fullColumns;
    }

    return (
        <Layout>
            <Layout className="bg-gray p-4">
                <Space>
                    <Link to={routes.HISTORY_MONEY_TRANSACTIONS}><Button className='custom-btn-tab'>Nạp rút tiền</Button></Link>
                    <Link to={routes.HISTORY_STOCK_TRANSACTIONS}><Button className='custom-btn-tab'>Giao dịch cổ phiếu</Button></Link>
                    <Button className='custom-btn-tab active'>Hiệu quả hoạt động</Button>
                </Space>
            </Layout>
            <Layout className="px-4 pt-4">
                {/* <Typography.Title level={4} className="uppercase font-bold mb-4">Lịch sử nạp rút tiền người vay</Typography.Title> */}
                {/* <Typography.Text><Typography.Link onClick={resetFilter}>Tất cả hợp đồng</Typography.Link> ({pagination.total})</Typography.Text>
                <Layout className="flex-row mt-2 mb-5 justify-between items-center">
                    <Form
                        form={form}
                        layout="inline"
                        onFinish={onFinishFilter}
                        initialValues={{ transactionType: '' }}
                    >
                        <Form.Item name="date">
                            <DatePicker.RangePicker
                                format={DATE_FORMAT}
                                placeholder={['Từ ngày', 'Đến ngày']}
                            />
                        </Form.Item>
                        <Form.Item name="transactionType">
                            <Select style={{ width: 200 }}>
                                <Select.Option value="">Tất cả giao dịch</Select.Option>
                                <Select.Option value="deposit">Nạp tiền</Select.Option>
                                <Select.Option value="withdrawal">Rút tiền</Select.Option>
                                <Select.Option value="profit">Chốt lãi/lỗ</Select.Option>
                                <Select.Option value="withdrawal-profit">Rút lãi</Select.Option>
                                <Select.Option value="cover-loss">Bù lỗ</Select.Option>
                            </Select>
                        </Form.Item>
                        <Button htmlType="submit">Lọc</Button>
                    </Form>
                </Layout> */}
                <Card className="card-table-header">
                    <div className="flex justify-between items-center">
                        <Typography.Title level={5} className="mb-0"></Typography.Title>
                        <Tooltip placement="left" title={fullTable ? "Xem bảng thu gọn" : "Xem bảng đầy đủ"}>
                            <ColumnWidthOutlined onClick={changeTableType} className="cursor-pointer" style={{ fontSize: 20 }} />
                        </Tooltip>
                    </div>
                </Card>

                <Table
                    bordered
                    rowKey={(r: any) => r.id}
                    dataSource={contracts}
                    columns={columns}
                    loading={isFetching}
                    scroll={{ x: 'max-content', y: window.innerHeight - 260 }}
                    pagination={false}
                    // scroll={{ x: 'max-content', y: window.innerHeight - 320 }}
                    // pagination={{
                    //     ...pagination,
                    //     size: "small",
                    //     showSizeChanger: false,
                    //     onChange: page => {
                    //         setPagination({ current: page });
                    //     }
                    // }}
                    sticky
                    summary={() => contracts.length > 0 && (
                        <Table.Summary fixed={'top'}>
                            <Table.Summary.Row>
                                {fullTable ? (
                                    <>
                                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} className="text-center font-bold">Tổng</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3} className="text-right font-bold">{moneyFormat(totalContractValue)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={8}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={10} className="text-right font-bold">{moneyFormat(totalMinimumInterestValue)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={11} className="text-right font-bold">{moneyFormat(totalInterestWithDrawnValue)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={12} className="text-right font-bold">{moneyFormat(totalTransactionFee)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={13} className="text-right font-bold">{moneyFormat(totalLoanInterest)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={14} className="text-right font-bold">{moneyFormat(totalRefundAndCommission)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={15} className="text-right font-bold">{moneyFormat(totalTotal)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={16} className="text-right font-bold">{moneyFormat(totalSourceCommission)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={17} className="text-right font-bold">{moneyFormat(totalF123Commission)}</Table.Summary.Cell>
                                    </>
                                ) : (
                                    <>
                                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={1} className="text-center font-bold">Tổng</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3} className="text-right font-bold">{moneyFormat(totalContractValue)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                        <Table.Summary.Cell index={7} className="text-right font-bold">{moneyFormat(totalSourceCommission)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={8} className="text-right font-bold">{moneyFormat(totalTransactionFee)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={9} className="text-right font-bold">{moneyFormat(totalLoanInterest)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={10} className="text-right font-bold">{moneyFormat(totalRefundAndCommission)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={11} className="text-right font-bold">{moneyFormat(totalTotal)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={12} className="text-right font-bold">{moneyFormat(totalSourceCommission)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={13} className="text-right font-bold">{moneyFormat(totalF123Commission)}</Table.Summary.Cell>
                                    </>
                                )}
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </Layout>
        </Layout>
    )
};

export default OperationalEfficiency;