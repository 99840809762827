import React, { useState, useEffect } from "react";
import { Layout, Card, Space, Button, Typography, Table, Tooltip } from "antd";
import { ColumnWidthOutlined } from "@ant-design/icons";
import { ColumnsType } from 'antd/lib/table';
import { useEffectOnce } from "react-use";

import { useAuthState } from "@Contexts/Auth";
import { moneyFormat, getColorClsName } from "@Services/utils";
import localStorage from "@Services/local-storage";
import api from "@Services/api";

type AssetStatusTableProps = {
    account: NSApp.Account;
    forceReload: number;
    setTransactionType: (type: string) => void;
    setIsTransactionModalVisible: (isTransactionModalVisible: boolean) => void;
    setAssetStatus: (assetStatus: NSApp.AssetStatus) => void;
}

const AssetStatusTable = (props: AssetStatusTableProps) => {
    const { account, setTransactionType, setAssetStatus, setIsTransactionModalVisible, forceReload } = props;
    const [asset, setAsset] = useState<NSApp.AssetStatus[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [fullTable, setFullTable] = useState<boolean>(false);

    let { authState } = useAuthState() as any;
    let { currentUser } = authState;

    const openTransactionModal = (type) => {
        setTransactionType(type)
        setIsTransactionModalVisible(true);
    };

    const fetchAsset = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`borrower/property/${account.id}`);
        setIsFetching(false);

        if (err) {
            return;
        }

        setAsset([{
            ...resp.data,
        }])

        setAssetStatus(resp.data)
    }

    const initFullTable = async () => {
        let fullTableLocalStorage = await localStorage.get('borrowerStatusTable');

        if (fullTableLocalStorage == null || fullTableLocalStorage === undefined) {
            setFullTable(true);
        } else {
            setFullTable(fullTableLocalStorage);
        }
    };

    useEffectOnce(() => {
        initFullTable();
    });

    useEffect(() => {
        fetchAsset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceReload]);

    const changeTableType = () => {
        setFullTable(!fullTable);
        localStorage.set('borrowerStatusTable', String(!fullTable));
    };

    const columnsArr1: ColumnsType<NSApp.AssetStatus> = [
        {
            title: 'Tổng giá trị mua',
            dataIndex: 'totalBuyValue',
            key: 'totalBuyValue',
            align: 'right',
            render: (value: number) => value !== null ? moneyFormat(value) : ""
        },
        {
            title: 'Tổng tiền nạp/rút',
            dataIndex: 'balance',
            key: 'balance',
            align: 'right',
            render: (value: number) => value !== null ? moneyFormat(value) : ""
        },
        {
            title: 'Lãi/lỗ đã chốt',
            dataIndex: 'profit',
            key: 'profit',
            align: 'right',
            render: (value: number) => {
                if (value === null) return '-';
                return <span className={`${getColorClsName(value)} font-bold`}>{moneyFormat(value)}</span>;
            }
        },
        {
            title: 'Bù lỗ/chốt lãi',
            dataIndex: 'cover',
            key: 'cover',
            align: 'right',
            render: (value: number) => value !== null ? moneyFormat(value) : ""
        },
        {
            title: 'NAV1',
            dataIndex: 'nav1',
            key: 'nav1',
            align: 'right',
            render: (value: number) => value !== null ? moneyFormat(value) : ""
        },
        {
            title: 'Lãi/lỗ dự kiến',
            dataIndex: 'totalExpectedProfit',
            key: 'totalExpectedProfit',
            align: 'right',
            render: (value: number) => {
                if (value === null) return '-';
                return <span className={`${getColorClsName(value)} font-bold`}>{moneyFormat(value)}</span>;
            }
        },
    ];

    const columnsArr2: ColumnsType<NSApp.AssetStatus> = [
        {
            title: 'Tỷ lệ biến động',
            dataIndex: 'changingRatio',
            key: 'changingRatio',
            align: 'right',
            render: (value) => {
                if (value === null) return '-';
                return <span className={`${getColorClsName(value)} font-bold`}>{value}%</span>;
            }
        },
        {
            title: 'Cổ tức dự kiến',
            dataIndex: 'expectedDividends',
            key: 'expectedDividends',
            align: 'right',
        },
    ];

    const columnsArr3: ColumnsType<NSApp.AssetStatus> = [
        {
            title: 'NAV2',
            dataIndex: 'nav2',
            key: 'nav2',
            align: 'right',
            render: (value: number) => value !== null ? moneyFormat(value) : ""
        },
        {
            title: 'Tỷ lệ hiện tại',
            dataIndex: 'currentMarginRatio',
            key: 'currentMarginRatio',
            align: 'right',
            render: (value) => value !== null ? `${value}%` : ""
        },
        {
            title: 'Tỷ lệ ký quỹ',
            dataIndex: 'marginRatio',
            key: 'marginRatio',
            align: 'right',
            render: (value) => value !== null ? `${value}%` : ""
        },
        {
            title: 'Yêu cầu nộp tiền',
            dataIndex: 'depositRequire',
            key: 'depositRequire',
            align: 'right',
            render: (value: number) => value !== null ? moneyFormat(value) : ""
        },
        {
            title: 'Số tiền có thể rút tối đa',
            dataIndex: 'withdrawable',
            key: 'withdrawable',
            align: 'right',
            render: (value: number) => value !== null ? moneyFormat(value) : ""
        },
        {
            title: 'Sức mua dự kiến',
            dataIndex: 'expectedPurchasingPower',
            key: 'expectedPurchasingPower',
            align: 'right',
            render: (value: number) => value !== null ? moneyFormat(value) : ""
        },
    ];

    let columns;
    if (fullTable) {
        columns = [...columnsArr1, ...columnsArr2, ...columnsArr3];
    } else {
        columns = [...columnsArr1, ...columnsArr3];
    }

    return (
        <Layout className="mb-4">
            <Card className="card-table-header">
                <div className="flex justify-between items-center">
                    <Typography.Title level={5} className="mb-0">Trạng thái tài sản</Typography.Title>
                    <Space>
                        <Tooltip placement="left" title={fullTable ? "Xem bảng thu gọn" : "Xem bảng đầy đủ"}>
                            <ColumnWidthOutlined onClick={changeTableType} className="cursor-pointer" style={{ fontSize: 20 }} />
                        </Tooltip>
                        {!!currentUser?.isSuperAdmin && (
                            <>
                                <Button size="small" className="custom-btn ant-btn-success" onClick={() => openTransactionModal('deposit')}>Nạp Tiền</Button>
                                <Button size="small" className="custom-btn" danger onClick={() => openTransactionModal('withdrawal')}>Rút Tiền</Button>
                            </>
                        )}
                    </Space>
                </div>
            </Card>
            <Table
                bordered
                rowKey={r => r.totalBuyValue}
                loading={isFetching}
                dataSource={asset}
                columns={columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </Layout>
    )
};

export default AssetStatusTable;