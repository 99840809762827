import React, { useState } from "react";
import { Alert, Row, Col, Button, Modal, Form, Input, DatePicker, InputNumber, Select, notification } from "antd";

import { DATE_TIME_FORMAT } from "@Configs";
import api from '@Services/api';
import { formatterMoney, parserMoney } from "@Services/utils";

const labelCol = 6;
const wrapperCol = 18;

const RefundAndCommissionModal = ({ profile, isVisible, setIsVisible, contracts, onSubmit }) => {
    const [errors, setErrors] = useState<any[]>([]);
    const [sourceCodeSelected, setSourceCodeSelected] = useState<any>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [form] = Form.useForm();

    const onCancelModal = () => {
        setErrors([]);
        setIsVisible(false);
    }

    const onChangeSelectedContract = (value) => {
        const selectedContract = contracts.find(contract => contract.id === value);
        if (selectedContract) {
            setSourceCodeSelected(selectedContract.source);
        }
    };

    const onFinishRefund = async (values) => {
        setIsSubmitting(true);
        const [err, resp]: any = await api.post('contract/transactions/refund-commission/add', {
            ...values,
            profileId: profile.id
        });

        setIsSubmitting(false);
        if (err) {
            setErrors(err.response.data.errors);
            return;
        }

        if (resp) {
            notification.success({
                message: 'Giao dịch thành công',
            });
            setErrors([]);
            setIsVisible(false);
            form.resetFields();
        } else {
            setErrors([{
                msg: "Có lỗi xảy ra. Xin vui lòng thử lại",
            }]);
        }

        onSubmit();
    }

    return (
        <Modal
            centered
            title="Hoàn phí và hoa hồng"
            visible={isVisible}
            onCancel={onCancelModal}
            destroyOnClose={true}
            footer={null}
            width={650}
        >
            {(errors.length > 0) && (
                <Row className="mb-4">
                    <Col span={24}>
                        {errors.map((error: any, index: number) => {
                            return <Alert
                                key={index}
                                className="mb-1"
                                type={"error"}
                                message={error.msg}
                                showIcon
                            />
                        })}
                    </Col>
                </Row>
            )}
            <Form
                form={form}
                layout="horizontal"
                labelAlign="left"
                colon={false}
                labelCol={{ span: labelCol }}
                wrapperCol={{ span: wrapperCol }}
                initialValues={{
                    type: 'refund',
                }}
                onFinish={onFinishRefund}
            >
                <Form.Item
                    label="Tài khoản"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <Input disabled value={`${profile.fullname} - ${profile.shortId}`}/>
                </Form.Item>
                <Form.Item
                    name="date"
                    label="Ngày"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <DatePicker className="w-full" placeholder="" format={DATE_TIME_FORMAT} showTime />
                </Form.Item>
                <Form.Item
                    name="type"
                    label="Loại giao dịch"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <Select>
                        <Select.Option value="refund">Hoàn phí</Select.Option>
                        <Select.Option value="commission">Hoa hồng</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="amount"
                    label="Số tiền"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <InputNumber
                        className="w-full"
                        controls={false}
                        formatter={value => formatterMoney(value)}
                        parser={value => parserMoney(value)}
                    />
                </Form.Item>
                <Form.Item
                    name="contractId"
                    label="Hợp đồng"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    {contracts.length > 0 ? (
                        <Select onChange={onChangeSelectedContract}>
                            {contracts.map(contract => {
                                return <Select.Option key={contract.id} value={contract.id}>{contract.contractNo}</Select.Option>
                            })}
                        </Select>
                        ) : (
                            <Input disabled placeholder="Chưa có hợp đồng nào" />
                        )}
                </Form.Item>
                <Form.Item
                    label="Kho"
                    rules={[
                        {
                            required: true,
                            message: 'Không được để trống',
                        },
                    ]}
                >
                    <Input disabled value={sourceCodeSelected ? sourceCodeSelected?.sourceCode : ""}/>
                </Form.Item>
                <Form.Item
                    name="note"
                    label="Nội dung"
                >
                    <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                    <div className="flex justify-end">
                        <Button type="primary" htmlType="submit" loading={isSubmitting}>Thêm</Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default RefundAndCommissionModal;