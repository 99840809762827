import React, { useState } from "react";
import {
	Layout,
	Typography,
	Row,
	Col,
	Form,
	Input,
	Button,
	DatePicker,
	Space,
	notification,
	Modal,
	Alert,
} from "antd";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useEffectOnce } from "react-use";
import moment from "moment";

import { useAuthState } from "@Contexts/Auth";
import api from "@Services/api";
import { toRoute } from "@Services/utils";
import routes from "@Routes";
import { DATE_FORMAT } from "@Configs";

import Loading from "@Components/Loading";
import BackButton from "@Components/BackButton";
import AccessDenied from "@Components/AccessDenied";

const labelCol = 6;
const wrapperCol = 14;

const LenderDetailInfo = () => {
	const navigate = useNavigate();
	const { id } = useParams() as any;
	const [lender, setLender] = useState<NSApp.Lender>();
	const [errors, setErrors] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	let { authState } = useAuthState() as any;
	let { currentUser } = authState;

	const fetchDetail = async () => {
		setIsFetching(true);
		const [err, resp]: any = await api.get(`lender/detail/${id}`, {});

		if (err) {
			notification.error({
				message: "Không tìm thấy người cho vay!",
			});
			return navigate(routes.LENDERS_ASSET);
		}

		if (!resp?.data?.lender) {
			notification.error({
				message: "Không tìm thấy người cho vay!",
			});
			return navigate(routes.LENDERS_ASSET);
		}

		setLender(resp.data.lender);
		setIsFetching(false);
	};

	useEffectOnce(() => {
		fetchDetail();
	});

	if (isFetching) {
		return <Loading />;
	}

	if (!lender) {
		return <Layout />;
	}

	if (!isFetching && currentUser && !currentUser?.isSuperAdmin) {
		return <AccessDenied />;
	}

	const onUpdateDetail = async (values) => {
		setLoading(true);
		setErrors([]);

		const {
			fullname,
			shortId,
			birthday,
			address,
			email,
			phone,
			bank_id,
			bank_name,
			bank_branch,
			identity_card_number,
			date_of_issued,
			place_of_issued,
		} = values;

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [err, _resp]: any = await api.post(`lender/update/${id}`, {
			fullname,
			shortId,
			birthday: birthday ? moment(birthday).format(DATE_FORMAT) : null,
			address,
			email,
			phone,
			bank_id,
			bank_name,
			bank_branch,
			identity_card_number,
			place_of_issued,
			date_of_issued: date_of_issued ? moment(date_of_issued).format(DATE_FORMAT) : null,
		});

		setLoading(false);
		if (!err) {
			notification.success({
				message: "Cập nhật thông tin thành công!",
			});
		}

		if (err?.response?.data?.errors) {
			setErrors(err.response.data.errors);
		}

		if (err?.response?.data?.message) {
			setErrors([{ msg: err.response.data.message }]);
		}
	};

	const openDeleteConfirm = () => {
		Modal.confirm({
			content: (
				<span>
					Bạn chắc chắn muốn xoá người cho vay{" "}
					<strong>{lender.fullname}</strong>?
				</span>
			),
			okText: "Có",
			cancelText: "Không",
			onOk: () => onDelete(),
		});
	};

	const onDelete = async () => {
		const [err, _resp]: any = await api.post(`lender/delete/${id}`, {});

		if (err) {
			notification.error({
				message: err?.response?.data?.message,
			});
			return;
		}

		notification.success({
			message: (
				<span>
					Xoá người cho vay <strong>{lender.fullname}</strong> thành
					công!
				</span>
			),
		});
		return navigate(routes.LENDERS_ASSET);
	};

	return (
		<Layout>
			<Layout className="flex-row justify-between items-center bg-gray p-4">
				<Space size="large" className="mr-6">
					<BackButton />
					<span>
						Tên: <strong>{lender.fullname}</strong>
					</span>
					<span>
						ID: <strong>{lender.shortId}</strong>
					</span>
				</Space>
				<Space>
					<Link to={toRoute(routes.LENDER_DETAIL_ASSET, { id })}>
						<Button className="custom-btn-tab">Tài sản</Button>
					</Link>
					<Link
						to={toRoute(routes.LENDER_DETAIL_SOURCE_MANAGER, {
							id,
						})}
					>
						<Button className="custom-btn-tab">Quản lý kho</Button>
					</Link>
					<Link
						to={toRoute(routes.LENDER_DETAIL_TRANSACTION_HISTORY, {
							id,
						})}
					>
						<Button className="custom-btn-tab">
							Lịch sử giao dịch
						</Button>
					</Link>
					<Button className="custom-btn-tab active">
						Thông tin cơ bản
					</Button>
				</Space>
				<div />
			</Layout>

			<Layout className="p-4">
				<Typography.Title
					level={4}
					className="uppercase font-bold mb-6"
				>
					Thông tin cơ bản
				</Typography.Title>
				{(!!errors || !!errors.length) && (
					<Row className="mb-4">
						<Col xs={24} lg={11}>
							{errors.map((error: any, index: number) => {
								return (
									<Alert
										key={index}
										className="mb-1"
										type={"error"}
										message={error.msg}
										showIcon
									/>
								);
							})}
						</Col>
					</Row>
				)}
				<Form
					layout="horizontal"
					labelAlign="left"
					colon={false}
					labelCol={{ span: labelCol }}
					wrapperCol={{ span: wrapperCol }}
					onFinish={onUpdateDetail}
					initialValues={{
						fullname: lender.fullname,
						birthday: lender.birthday ? moment(lender.birthday) : null,
						address: lender.address,
						email: lender.email,
						phone: lender.phone,
						shortId: lender.shortId.slice(0,lender.shortId.length - 2),
						bank_id: lender.bank_id,
						bank_name: lender.bank_name,
						bank_branch: lender.bank_branch,
						identity_card_number: lender.identity_card_number,
						date_of_issued: lender.date_of_issued ? moment(lender.date_of_issued) : null,
						place_of_issued: lender.place_of_issued,
					}}
				>
					<Row>
						<Col xs={24} lg={12}>
							<Form.Item
								name="fullname"
								label="Họ và tên"
								rules={[
									{
										required: true,
										message: "Không được để trống",
									},
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item name="birthday" label="Ngày sinh">
								<DatePicker
									className="w-full"
									placeholder=""
									format={DATE_FORMAT}
								/>
							</Form.Item>
							<Form.Item name="address" label="Địa chỉ">
								<Input />
							</Form.Item>
							<Form.Item name="email" label="Email">
								<Input disabled />
							</Form.Item>
							<Form.Item name="phone" label="Số điện thoại">
								<Input />
							</Form.Item>
							<Form.Item name="shortId" label="ID">
								<Input
									placeholder="ID chỉ bao gồm các chữ số"
									required={true}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item name="identity_card_number" label="Số CMND/CCCD">
                                <Input />
                            </Form.Item>
                            <Form.Item name="date_of_issued" label="Ngày cấp">
                                <DatePicker className="w-full" format={DATE_FORMAT} />
                            </Form.Item>
                            <Form.Item name="place_of_issued" label="Nơi cấp">
                                <Input />
                            </Form.Item>
							<Form.Item name="bank_id" label="Số tài khoản NH">
								<Input />
							</Form.Item>
							<Form.Item
								name="bank_name"
								label="Tên tài khoản NH"
							>
								<Input />
							</Form.Item>
							<Form.Item name="bank_branch" label="Chi nhánh NH">
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col xs={24} lg={12} />
						<Col xs={24} lg={12}>
							<Form.Item
								wrapperCol={{
									offset: labelCol,
									span: wrapperCol,
								}}
							>
								<div className="flex justify-end">
									<Button
										className="mr-2"
										type="primary"
										danger
										onClick={openDeleteConfirm}
									>
										Xoá
									</Button>
									<Button
										type="primary"
										htmlType="submit"
										loading={loading}
									>
										Lưu
									</Button>
								</div>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Layout>
		</Layout>
	);
};

export default LenderDetailInfo;
