import React, { useState } from "react";
import { Alert, Row, Col, Button, Typography, Modal, Form, Input, DatePicker, InputNumber, Select, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toRoute, moneyFormat, formatterMoney, parserMoney, formatterPercent, parserPercent } from "@Services/utils";
import { DATE_FORMAT } from "@Configs";
import api from "@Services/api";
import routes from "@Routes";

const labelCol = 6;
const wrapperCol = 18;

const TransactionModal = ({ sources, profile, isVisible, setIsVisible, type, contracts, setContracts, onSubmit }) => {
    const [balance, setBalance] = useState<number>(0);
    const [interestValue, setInterestValue] = useState<number>(0);
    const [transactionValue, setTransactionValue] = useState<number>(0);
    const [errors, setErrors]: any = useState([]);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const [newContractForm] = Form.useForm();
    const [depositOrWithdrawalForm] = Form.useForm();
    const startingDate = Form.useWatch('starting_date', newContractForm);

    let typeDescription = '';
    let modalTitle = '';
    if (type === 'newContract') {
        typeDescription = 'mở hợp đồng';
        modalTitle = 'Mở hợp đồng';
    }
    if (type === 'deposit') {
        typeDescription = 'nạp';
        modalTitle = 'Nạp tiền';
    }
    if (type === 'withdrawal') {
        typeDescription = 'rút';
        modalTitle = 'Rút Tiền';
    }

    const onCancelModal = () => {
        setErrors([]);
        setBalance(0);
        setTransactionValue(0);
        setIsVisible(false);
        depositOrWithdrawalForm.resetFields();
    }

    const onChangeTransactionValue = (e) => {
        if (!e) {
            setTransactionValue(0);
        }
        setTransactionValue(e);
    }

    const onChangeSelectedContract = (value) => {
        const selectedContract = contracts.find(contract => contract.id === value);
        const _balance = Number(selectedContract.contractValue);

        if (selectedContract) {
            depositOrWithdrawalForm.setFieldsValue({
                source: selectedContract.source.sourceCode,
                contractValue: selectedContract.contractValue,
                balance: _balance
            });
            setBalance(_balance);
            setInterestValue(selectedContract.interestValue);
        }
    };

    const onFinishOpenNewContract = async (values) => {
        setLoading(true);
        const { contract_id, value, interest_rate, starting_date, closing_date, interest_period, content, source } = values;
        let newContract = {
            profileId: profile.id,
            contractNo: contract_id,
            contractValue: value,
            interest: interest_rate,
            startDate: starting_date,
            expireDate: closing_date,
            interestPeriod: interest_period,
            note: content,
            source: source
        }

        const [err, resp]: any = await api.post('contract/open', newContract);

        setLoading(false);
        if (err) {
            if (err?.response?.data?.errors) {
                setErrors(err.response.data.errors);
            }

            if (err?.response?.data?.message) {
                setErrors([{ msg: err.response.data.message }])
            }

            notification.error({
                message: "Có lỗi trong quá trình mở hợp đồng"
            })
            return;
        }

        notification.success({
            message: 'Mở hợp đồng thành công',
        });

        const contract = resp.data.contract;
        setContracts([
            ...contracts,
            contract
        ]);
        onCancelModal();
    };

    const onFinishDeposit = async (values) => {
        setLoading(true);
        const { contract_id, amount, date, note } = values;
        const [err, resp]: any = await api.post(`contract/${contract_id}/deposit`, {
            amount, date, note
        });

        setLoading(false);
        if (err) {
            if (err?.response?.data?.errors) {
                setErrors(err.response.data.errors);
            }

            if (err?.response?.data?.message) {
                setErrors([{ msg: err.response.data.message }])
            }

            notification.error({
                message: "Có lỗi trong quá trình nạp tiền"
            })
            return;
        }

        notification.success({
            message: 'Nạp tiền thành công',
        });

        const _contracts = contracts.map((contract) => {
            if (contract.id === resp.data.contract.id) {
                return resp.data.contract;
            }

            return contract;
        })
        setContracts(_contracts);
        onSubmit();
        onCancelModal();
    }

    const onFinishWithdrawal = async (values) => {
        setLoading(true);
        const { contract_id, amount, date, note } = values;
        const [err, resp]: any = await api.post(`contract/${contract_id}/withdrawal`, {
            amount, date, note
        });

        setLoading(false);
        if (err) {
            if (err?.response?.data?.errors) {
                setErrors(err.response.data.errors);
            }

            if (err?.response?.data?.message) {
                setErrors([{ msg: err.response.data.message }])
            }

            notification.error({
                message: "Có lỗi trong quá trình rút tiền"
            })
            return;
        }

        notification.success({
            message: 'Rút tiền thành công',
        });

        const _contracts = contracts.map((contract) => {
            if (contract.id === resp.data.contract.id) {
                return resp.data.contract;
            }

            return contract;
        })
        setContracts(_contracts);
        onSubmit();
        onCancelModal();
    }

    const generateBalanceAfterWithdrawal = (balance: number, interestValue: number, transactionValue: number) => {
        const balanceResult = transactionValue >= interestValue ? balance - (transactionValue - interestValue) : balance;
        const interestValueResult = transactionValue >= interestValue ? 0 : interestValue - transactionValue;

        return (
            <>
                <div className="flex justify-between">
                    <span>Tổng tiền nạp/rút sau khi {typeDescription}:</span>
                    <Typography.Text className="font-bold" type='danger'>
                        {moneyFormat(balanceResult)}
                    </Typography.Text>
                </div>
                <div className="flex justify-between">
                    <span>Lãi đến hạn được rút:</span>
                    <Typography.Text className="font-bold" type='danger'>
                        {moneyFormat(interestValueResult)}
                    </Typography.Text>
                </div>
                <div className="flex justify-between">
                    <span>Tổng</span>
                    <Typography.Text className="font-bold" type='danger'>
                        {moneyFormat(balanceResult + interestValueResult)}
                    </Typography.Text>
                </div>
            </>
        )
    }

    const goToAddNewSource = () => {
        navigate(toRoute(routes.LENDER_ADD_NEW_SOURCE, { id: profile.id }))
    };

    if (sources.length < 1) {
        return (
            <Modal
                centered
                title={modalTitle}
                visible={isVisible}
                onCancel={onCancelModal}
                destroyOnClose={true}
                footer={null}
                width={650}
            >
                <div className="flex flex-col justify-center items-center my-20">
                    <Button className="mb-4" type="primary" onClick={goToAddNewSource}><PlusOutlined /> Tạo kho</Button>
                    <span className="text-gray-500">Người cho vay chưa có kho. Vui lòng tạo kho mới trước khi mở hợp đồng</span>
                </div>
            </Modal>
        )
    }

    return (
        <Modal
            centered
            title={modalTitle}
            visible={isVisible}
            onCancel={onCancelModal}
            destroyOnClose={true}
            footer={null}
            width={650}
        >
            {(errors.length > 0) && (
                <Row className="mb-4">
                    <Col span={24}>
                        {errors.map((error: any, index: number) => {
                            return <Alert
                                key={index}
                                className="mb-1"
                                type={"error"}
                                message={error.msg}
                                showIcon
                            />
                        })}
                    </Col>
                </Row>
            )}
            {type === 'newContract' && (
                <Form
                    form={newContractForm}
                    layout="horizontal"
                    labelAlign="left"
                    colon={false}
                    labelCol={{ span: labelCol }}
                    wrapperCol={{ span: wrapperCol }}
                    onFinish={onFinishOpenNewContract}
                    initialValues={{ id: `${profile.fullname} - ${profile.shortId}` }}
                >
                    <Form.Item
                        name="id"
                        label="Tài khoản"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="contract_id"
                        label="Số hợp đồng"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="value"
                        label="Số tiền"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <InputNumber
                            className="w-full"
                            controls={false}
                            formatter={value => formatterMoney(value)}
                            parser={value => parserMoney(value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="interest_rate"
                        label="Lãi suất"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <InputNumber
                            addonAfter="%/ngày"
                            className="w-full"
                            controls={false}
                            formatter={value => formatterPercent(value)}
                            parser={value => parserPercent(value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="interest_period"
                        label="Kỳ hạn rút lãi"
                        rules={[
                            {
                                required: true,
                                message: "Không được để trống"
                            }
                        ]}
                    >
                        <Select>
                            <Select.Option value="3">3 Tháng</Select.Option>
                            <Select.Option value="6">6 tháng</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="starting_date"
                        label="Ngày tạo hợp đồng"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <DatePicker
                            className="w-full"
                            placeholder=""
                            format={DATE_FORMAT}
                        />
                    </Form.Item>
                    <Form.Item
                        name="closing_date"
                        label="Ngày hết hợp đồng"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <DatePicker
                            className="w-full"
                            placeholder=""
                            format={DATE_FORMAT}
                            disabledDate={(current) => current && current < moment(startingDate).endOf('day')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="source"
                        label="Kho"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Select>
                            {sources.map((source: any) => {
                                return <Select.Option key={source.id} value={source.id}>
                                    {source.sourceCode}
                                </Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="content"
                        label="Nội dung"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                        <div className="flex justify-end">
                            <Button type="primary" htmlType="submit" loading={loading}>Thêm</Button>
                        </div>
                    </Form.Item>
                </Form>
            )}

            {(type === 'deposit' || type === 'withdrawal') && (
                <Form
                    form={depositOrWithdrawalForm}
                    layout="horizontal"
                    labelAlign="left"
                    colon={false}
                    labelCol={{ span: labelCol }}
                    wrapperCol={{ span: wrapperCol }}
                    initialValues={{ id: `${profile.fullname} - ${profile.shortId}` }}
                    onFinish={type === 'deposit' ? onFinishDeposit : onFinishWithdrawal}
                >
                    <Form.Item
                        name="id"
                        label="Tài khoản"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        name="contract_id"
                        label="Số hợp đồng"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        {contracts.length > 0 ? (
                        <Select onChange={onChangeSelectedContract}>
                            {contracts.map(contract => {
                                return <Select.Option key={contract.id} value={contract.id}>{contract.contractNo}</Select.Option>
                            })}
                        </Select>
                        ) : (
                            <Input disabled placeholder="Chưa có hợp đồng nào" />
                        )}
                    </Form.Item>
                    <Form.Item
                        name="source"
                        label="Kho"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        name="balance"
                        label="Tổng tiền nạp/rút"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <InputNumber
                            disabled
                            className="w-full"
                            controls={false}
                            formatter={value => formatterMoney(value)}
                            parser={value => parserMoney(value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="amount"
                        label={`Số tiền ${typeDescription}`}
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <InputNumber
                            className="w-full"
                            controls={false}
                            formatter={value => formatterMoney(value)}
                            parser={value => parserMoney(value)}
                            onChange={onChangeTransactionValue}
                        />
                    </Form.Item>
                    <Form.Item
                        name="date"
                        label={`Ngày ${typeDescription}`}
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <DatePicker
                            className="w-full"
                            placeholder=""
                            format={DATE_FORMAT}
                        />
                    </Form.Item>
                    <Row className="mb-6">
                        <Col offset={6} span={18}>
                            {type === 'deposit' &&
                                <div className="flex justify-between">
                                    <span>Tổng tiền nạp/rút sau khi {typeDescription}:</span>
                                    <Typography.Text className="font-bold" type='success'>
                                        {moneyFormat(balance + transactionValue)}
                                    </Typography.Text>
                                </div>
                            }
                            {type === 'withdrawal' && generateBalanceAfterWithdrawal(balance, interestValue, transactionValue)}
                        </Col>
                    </Row>
                    <Form.Item
                        name="note"
                        label="Nội dung"
                        rules={[
                            {
                                required: true,
                                message: 'Không được để trống',
                            },
                        ]}
                    >
                        <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: labelCol, span: wrapperCol }}>
                        <div className="flex justify-end">
                            <Button type="primary" htmlType="submit" loading={loading}>Thêm</Button>
                        </div>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    )
};

export default TransactionModal;