import React, { useEffect, useState } from "react";
import { Layout, Button, Table, Input, notification, Typography, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";

import { useAuthState } from "@Contexts/Auth";
import { useReducedState, toRoute } from "@Services/utils";
import { DATE_FORMAT, PAGE_SIZE_NORMAL } from "@Configs";
import api from "@Services/api";
import routes from "@Routes";
import AccessDenied from "@Components/AccessDenied";

const LendersInfo = () => {
    const navigate = useNavigate();
    let { authState } = useAuthState() as any;
    let { currentUser } = authState;

    const [listLenders, setListLenders] = useState<NSApp.Lender[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>();

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: PAGE_SIZE_NORMAL,
        total: 0
    });

    const fetchLenders = async () => {
        setIsFetching(true);
        const [err, resp]: any = await api.get(`lender/list`, {
            filter: searchKey, page: pagination.current, pageSize: PAGE_SIZE_NORMAL
        });
        setIsFetching(false)

        if (err) {
            notification.error({
                message: err?.response?.data?.message
            });
            return;
        }

        let { lenders, total } = resp?.data;

        setListLenders(lenders);
        setPagination({ total });
    }

    useEffect(() => {
        fetchLenders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current, searchKey]);

    const onSearch = (value: string) => {
        setSearchKey(value);
    };

    const columns: ColumnsType<any> = [
        {
            title: 'Tên',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (value, record) => <Link className="font-bold" to={toRoute(routes.LENDER_DETAIL_ASSET, { id: record.id })}>{value}</Link>
        },
        {
            title: 'ID',
            dataIndex: 'shortId',
            key: 'shortId',
            align: 'center',
            render: (value) => <div className="text-one-line">{value}</div>
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'birthday',
            key: 'birthday',
            align: 'center',
            render: (value) => moment(value).format(DATE_FORMAT)
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'user',
            render: (value) => value.email
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            align: 'center',
        },
    ];

    if (!isFetching && currentUser && !currentUser?.isSuperAdmin) {
        return <AccessDenied />
    }

    return (
        <Layout>
            <Layout className="bg-gray p-4">
                <Space>
                    <Link to={routes.LENDERS_ASSET}><Button className='custom-btn-tab'>Tài sản</Button></Link>
                    <Button className='custom-btn-tab active'>Thông tin</Button>
                </Space>
            </Layout>
            <Layout className="px-4 pt-4">
                <Layout className="flex-row justify-between items-center mb-6">
                    <div className="flex">
                        <Typography.Title level={4} className="uppercase font-bold mb-0 mr-4">Danh sách người cho vay</Typography.Title>
                        <Button onClick={() => navigate(routes.ADD_NEW_LENDER)}>Thêm mới</Button>
                    </div>
                    <div>
                        <Input.Search
                            placeholder="Nhập Tên, ID hoặc Email"
                            allowClear
                            enterButton="Tìm kiếm"
                            style={{ minWidth: 300 }}
                            onSearch={onSearch}
                        />
                    </div>
                </Layout>

                <div className="mb-3">
                    <span>Tất cả ({ pagination.total })</span>
                </div>

                <Table
                    bordered
                    rowKey={(r: any) => r.id}
                    dataSource={listLenders}
                    columns={columns}
                    loading={isFetching}
                    pagination={{
                        ...pagination,
                        size: 'small',
                        showSizeChanger: false,
                        onChange: page => {
                            setPagination({ current: page });
                        }
                    }}
                />
            </Layout>
        </Layout>
    );
}

export default LendersInfo;