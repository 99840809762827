import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Select, DatePicker, Form, Typography, Popconfirm, Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { DATE_TIME_FORMAT, DATE_FORMAT } from "@Configs";
import { formatterMoney, parserMoney } from "@Services/utils";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: string;
    inputValue: any;
    inputWidth: number;
    inputRules: any[];
    inputDisabled: boolean;
    record: any;
    index: number;
    align: string;
    children: React.ReactNode;
    required?: boolean;
    onChange?: any;
    form?: any;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    inputValue,
    inputWidth,
    inputRules,
    inputDisabled,
    record,
    index,
    children,
    align,
    required,
    form,
    onChange,
    ...restProps
}) => {
    let inputStyles = {};
    if (inputWidth) {
        inputStyles = {
            ...inputStyles,
            width: inputWidth,
        }
    };

    if (align) {
        inputStyles = {
            ...inputStyles,
            textAlign: align,
        }
    }

    const handleChange = (value) => {
        if (!form) {
            return;
        }
        onChange(value, form);
    };

    let inputNode = <Input style={inputStyles} disabled={inputDisabled} />;

    if (inputType === 'number') {
        inputNode = <InputNumber
            controls={false}
            style={inputStyles}
            disabled={inputDisabled}
            formatter={value => value ? formatterMoney(value) : '0'}
            parser={value => value ? parserMoney(value) : 0}
        />;
    }
    if (inputType === 'select') {
        inputNode = <Select style={inputStyles} disabled={inputDisabled} onChange={handleChange}>
            {inputValue.map((option) => <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>)}
        </Select>;
    }
    if (inputType === 'date' || inputType === 'date-time') {
        inputNode = <DatePicker
            showTime
            style={inputStyles}
            format={inputType === 'date-time' ? DATE_TIME_FORMAT : DATE_FORMAT}
            placeholder=""
            disabled={inputDisabled}
            suffixIcon={null}
            allowClear={false}
        />;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: required === false ? false : true, // if not defined it's true
                            message: <div className={`text-${align}`}>Nhập dữ liệu!</div>,
                        },
                        ...inputRules,
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const EditableTable = ({ rowKey, dataSource, columns, pagination, onSave, onDelete, anyoneEditable = false, ...restProps }) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(dataSource);
    const [editingKey, setEditingKey] = useState('');
    // let { authState } = useAuthState() as any;

    const isEditing = (record: any) => record[rowKey] === editingKey;

    const handleEdit = (record) => {
        let returnObj = {};
        columns.filter((column) => {
            if (column.editable) {
                returnObj = {
                    ...returnObj,
                    [column.key]: column.inputType === 'number' ? 0 : '',
                }
            }
            return returnObj;
        });

        form.setFieldsValue({
            ...returnObj,
            ...record,
        });
        setEditingKey(record[rowKey]);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const handleSave = async (key: React.Key) => {
        await form.validateFields();
        onSave(key, form.getFieldsValue());
        setEditingKey('');
    };

    const handleDelete = async (key: React.Key) => {
        const dataSource = [...data];
        const result = await onDelete(key);
        if (result) {
            setData(dataSource.filter(item => item[rowKey] !== key));
        }
    };

    useEffect(() => {
        setData(dataSource);
    }, [dataSource])

    const extraColumn = {
        title: '',
        key: 'action',
        align: 'center',
        width: 70,
        fixed: 'right',
        render: (_: any, record: any) => {
            const editable = isEditing(record);
            return editable ? (
                <span>
                    <Typography.Link onClick={cancel}>Huỷ</Typography.Link>
                    <Button type="primary" className="ml-1" onClick={() => handleSave(record[rowKey])}>Lưu</Button>
                </span>
            ) : (
                <span>
                    <Typography.Link disabled={editingKey !== ''} onClick={() => handleEdit(record)} className="mr-1">
                        <EditOutlined />
                    </Typography.Link>
                    {(data.length >= 1 && onDelete) ? (
                    <Popconfirm
                        title="Bạn chắc chắn muốn xoá dữ liệu này?"
                        okText="Có"
                        okButtonProps={{ danger: true }}
                        cancelText="Không"
                        onConfirm={() => handleDelete(record[rowKey])}
                    >
                        <Typography.Link type="danger"><DeleteOutlined /></Typography.Link>
                    </Popconfirm>
                    ) : null}
                </span>
            );
        },
    };

    // if (anyoneEditable || authState.currentUser.isSuperAdmin) {
        columns = [...columns, extraColumn];
    // }

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                required: col.required,
                inputType: col.inputType,
                inputValue: col.inputValue,
                inputWidth: col.inputWidth,
                inputRules: col.inputRules ? col.inputRules : [],
                inputDisabled: col.inputDisabled ? col.inputDisabled : false,
                dataIndex: col.dataIndex,
                title: col.title,
                align: col.align ? col.align : 'left',
                onChange: col.onChange ? col.onChange : false,
                form: col.onChange ? form : null,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                {...restProps}
                bordered
                rowClassName="editable-row"
                rowKey={(r: any) => r[rowKey]}
                dataSource={data}
                columns={mergedColumns}
                pagination={{
                    ...pagination,
                    size: 'small',
                }}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
            />
        </Form>
    );
}

export default EditableTable;
