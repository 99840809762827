import React from "react";
import { Layout, Spin } from "antd";

const Loading = () => {
    return (
        <Layout className="h-full justify-center items-center">
            <Spin />
        </Layout>
    )
};

export default Loading;